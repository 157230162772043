import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import {
  Container,
  Checkbox,
  Grid,
  FormGroup,
  FormControlLabel,
  Modal,
} from "@mui/material";
import useStyles, {
  ShowAddress,
  ModalAddress,
  ModalHeader,
  FlexIcon,
  ModalBody,
} from "./styles";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import FormInput from "../../components/FormInput/FormInput";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Adjust,
  Delete,
  Room,
  CalendarToday,
  AccessTime,
  Timelapse,
  Add,
  LocationOn,
  Flag,
  Close,
  Bookmark,
  ArrowForwardIos,
  Edit,
} from "@mui/icons-material";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import useAddress from "../../hooks/useAddress";
import Loader from "react-js-loader";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  get_delivery_type,
  get_booking_slot,
  check_eligible_location,
  calculate_price,
  get_passenger,
  calculate_distance,
  check_available_vehicle,
  get_return_time,
  get_session,
  get_bookaride_image,
  new_deliver_now,
  check_eligible_express,
  google_search,
  get_google_place_details,
  add_user_address,
  get_user_address,
  get_user_address_detail,
  edit_user_address,
  delete_user_address,
  booking,
  new_get_booking_slot,
  get_close_date,
} from "../../API/API";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";

const GOOGLEMAPKEY = "AIzaSyDLXH_lZuKaQ0rwrl9WRgKiCcdS1qku8BQ";

Geocode.setApiKey(GOOGLEMAPKEY);
Geocode.setLocationType("ROOFTOP");

export default function Bookaride() {
  const [beforeData, setBeforeData] = useState(0);

  const [price_per_km, set_price_per_km] = useState(0);
  const [distance_from_price, set_distance_from_price] = useState(0);
  const [base_fare, set_base_fare] = useState(0);
  const [total_from_price, set_total_from_price] = useState(0);
  const [service_tax, set_service_tax] = useState(0);
  const [price_from_km, set_price_from_km] = useState(0);
  const [hour, set_hour] = useState("");
  const [promocode, set_promocode] = useState("");
  const [return_time, set_return_time] = useState(0);
  const [passangerList, setPassangerList] = useState([
    { name: "", contact: "", is_oku: 0 },
  ]);
  const [user_list, set_user_list] = useState([]);
  const [user_list_id, set_user_list_id] = useState(0);
  const [distance, set_distance] = useState(0);
  const [is_checked_return, set_is_checked_return] = useState(false);
  const [booking_valid, set_booking_valid] = useState(true);
  const [booking_error, set_booking_error] = useState("");
  const [event_changer, set_event_changer] = useState(false);
  const [address, set_address] = useState("");
  const [end_address, set_end_address] = useState("");

  function convert_time(time) {
    if (delivery_type_id != 3) {
      var time_ = time.split(":")[0];
      var am_or_pm = "";
      if (time_ >= 12) {
        // am_or_pm = " PM"
      } else {
        // am_or_pm = " AM"
      }
      return time + am_or_pm;
    } else {
      return "";
    }
  }

  const blankItem = () => {
    return {
      name: "",
      contact: "",
      is_oku: 0,
    };
  };

  const handle_change_return = (e) => {
    const { name, value, checked } = e.target;

    if (checked == true) {
      var checked_status = 1;
    } else {
      var checked_status = 0;
    }
    set_is_checked_return(checked_status);
  };

  const handleChangeHour = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    set_hour(value);
  };

  const handleChangePassanger = (e, index) => {
    const { name, value, checked } = e.target;

    if (checked == true) {
      var checked_status = 1;
    } else {
      var checked_status = 0;
    }
    const items = [...passangerList];
    if (name == "is_oku") {
      items[index][name] = checked_status;
    } else {
      items[index][name] = value;
    }
    setPassangerList(items);
  };

  const handleCheckPassangerList = () => {
    var passenger_array = [...passangerList];

    var counter = 0;
    var counter_false = 0;
    for (let i = 0; i < passenger_array.length; i++) {
      if (passenger_array[i].is_oku == 1) {
        counter++;
      }
      if (passenger_array[i].is_oku == 0) {
        counter_false++;
      }
    }
    if (counter > 2) {
      alert("Maximum 2 Wheel chair passengers");
    } else if (counter_false > 2) {
      alert("Maximum 2 normal passengers");
    } else {
      handleChangeTab(2);
    }
  };

  // const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  // const [min_date, set_min_date] = useState(
  //   new Date().toISOString().split("T")[0]
  // );
  // useEffect(() => {
  //   getMinDate();
  // }, [date]);

  // function getMinDate() {
  //   if (date == new Date().toISOString().split("T")[0]) {
  //     // alert(date)
  //     // if()
  //     var today = new Date();
  //     var time = today.getHours();
  //     if (time >= "22") {
  //       var today = new Date(new Date().toISOString().split("T")[0]);
  //       var tomorrow = new Date(today);
  //       tomorrow.setDate(today.getDate() + 1);
  //       tomorrow = tomorrow.toISOString().split("T")[0];
  //       setDate(tomorrow);
  //     }
  //   }
  // }

  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [min_date, set_min_date] = useState(
    new Date()
  );
  useEffect(() => {
    getMinDate();
  }, [date]);

  function getMinDate() {
    if (date == new Date()) {
      // alert(date)
      // if()
      var today = new Date();
      var time = today.getHours();
      if (time >= "22") {
        var today = new Date();
        var tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        tomorrow = new Date(tomorrow);
        setDate(tomorrow);
        changeCalander(tomorrow)
      }
    }
  }

  // console.log(date)

  // TESTING CALENDAR
  const [value, onChange] = useState(new Date(new Date().toISOString().split("T")[0]));
  const changeCalander = (e) => {
    onChange(e)
    setDate(new Date(e - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0])
  }

  const [getDisableDate, setGetDisableDate] = React.useState([]);

  const getDisableDateList = () => {
    get_close_date().then((json) => {
      setGetDisableDate(json.data);
    });
  };

  var x = [...getDisableDate];
  for (var i = 0; i < x.length; i++) {


    var asd = x[i].split("-");

    var newDate = asd[0] + "," + (asd[1] - 1) + "," + asd[2]

    x[i] = new Date(newDate)

  }

  React.useEffect(() => {
    getDisableDateList();
  }, []);

  const disabledDates = [...x]

  // console.log(new Date().getTimezoneOffset() * 60000, value, new Date(value).getTimezoneOffset(), new Date(value - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]);



  const [keyword, setkeyword] = React.useState("0");

  const [discount_value, set_discount_value] = useState(0);
  const [delivery_type_id, set_delivery_type_] = React.useState(0);
  const [booking_slot, set_booking_slot] = React.useState([]);

  const [booking_slot_id, set_booking_slot_id] = useState(0);
  const [session_id, set_session_id] = useState(0);

  function get_delivery_type_text(delivery_type_id) {
    if (delivery_type_id == 1) {
      return "Session";
    }
    if (delivery_type_id == 2) {
      return "Distance";
    }
    if (delivery_type_id == 3) {
      return "Fullday";
    }

    if (delivery_type_id == 4) {
      return "Hour";
    }
  }

  function get_session_text(session_id) {
    if (session_id == 1) {
      return "8AM - 1PM (Morning session)";
    }
    if (session_id == 2) {
      return "1PM - 6PM (Afternoon session)";
    }
  }

  const [addressData, setAddressData] = React.useState({
    address: "",
    title: "",
    unit: "",
    landmark: "",
    route: "",
    streetnumber: "",
    street: "",
    city: "",
    postcode: "",
    state: "",
    address_book_id: "",
    end_address: "",
    end_title: "",
    end_unit: "",
    end_landmark: "",
    end_route: "",
    end_streetnumber: "",
    end_street: "",
    end_city: "",
    end_postcode: "",
    end_state: "",
    end_address_book_id: "",
    //THE OLD DATA
    delivery_type_id: delivery_type_id,
    booking_slot_id: booking_slot_id,
    passenger_array: JSON.stringify(passangerList),
    return_time: return_time,
    is_checked_return: is_checked_return,
    date: new Date(value - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0],
    start_state: "",
    start_lat: 0,
    start_lng: 0,
    start_address: address,
    end_lat: 0,
    end_lng: 0,
    end_state: "",
    end_address: end_address,
    session_id: session_id,
  });

  const [destinationAddressData, setDestinationAddressData] = React.useState({
    address: "",
    postcode: "",
    city: "",
    state: "",
    landmark: "",

    //THE OLD DATA

    delivery_type_id: delivery_type_id,
    booking_slot_id: booking_slot_id,
    passenger_array: JSON.stringify(passangerList),
    return_time: return_time,
    is_checked_return: is_checked_return,
    date: new Date(value - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0],
    start_state: "",
    start_lat: 0,
    start_lng: 0,
    start_address: address,
    end_lat: 0,
    end_lng: 0,
    end_state: "",
    end_address: end_address,
    session_id: session_id,
  });

  async function checkAddressStuff() {
    if (delivery_type_id == 2) {
      if (addressData.address == "") {
        set_booking_valid(false);
        set_booking_error("Please select an address");
      }
      if (addressData.end_address == "") {
        set_booking_valid(false);
        set_booking_error("Please select an address");
      }
    }
  }

  const classes = useStyles();

  const [tab, setTab] = React.useState(0);

  function validation() {
    // console.log("test", addressData);
    if (delivery_type_id == 0) {
      alert("Must Select a type");
      return false;
    }
    if (booking_slot_id == 0 && delivery_type_id != 3) {
      alert("Please select a time slot");
      return false;
    }

    if (
      addressData.end_state != addressData.start_state &&
      delivery_type_id == 2
    ) {
      alert("Pickup State and destination State must be the same");
      return false;
    }

    if (delivery_type_id == 3) {
      const state_allow = ["johor", "Johor", "柔佛"];
      if (
        state_allow.includes(addressData.start_state.trim().toLowerCase()) ==
        false
      ) {
        alert("Full day only allow johor");
        return false;
      }
    }

    if (session_id == 0 && delivery_type_id == 1) {
      alert("Please select a session AM or PM");
      return false;
    }

    if (delivery_type_id == 4 && (hour == "" || hour < 2)) {
      alert("Minimum 2 hours is required");
      return false;
    }

    if (is_checked_return == 1 && return_time == "") {
      alert("Please select a return time");
      return false;
    }

    return true;
  }

  function validation2() {
    if (
      passangerList.length == 1 &&
      passangerList[0].name == "" &&
      passangerList[0].contact == ""
    ) {
      alert("Please enter passenger detail");
      return false;
    }

    return true;
  }

  const handleChangeTab = (text) => {
    if (text == 1) {
      var status = validation();
      if (status) {
        setTab(text);
      }
    } else if (text == 2) {
      var status = validation2();
      if (status) {
        setTab(text);
      }
    } else {
      setTab(text);
    }
  };

  const handleChangeUserId = (event) => {
    const items = [...passangerList];

    var target_user = user_list.find(
      (arr) => arr.orders_passenger_id == event.target.value
    );
    // alert(JSON.stringify(target_user))
    items[0]["contact"] = target_user.contact;
    items[0]["name"] = target_user.name;
    setPassangerList(items);
    set_user_list_id(event.target.value);
  };

  const handleChange = (event) => {
    // alert(JSON.stringify(event.target.value))
    set_delivery_type_(event.target.value);
  };

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const [delivery_type, set_delivery_type] = useState([]);

  const [session, set_session] = useState([]);

  const [price, set_price] = useState(0);
  const [return_slot, set_return_slot] = useState([]);

  const [promo_valid, set_promo_valid] = useState(false);
  React.useEffect(() => {
    get_session().then((json) => {
      set_session(json.data);
    });

    get_passenger().then((json) => {
      set_user_list(json.data);
    });

    get_delivery_type().then((json) => {
      set_delivery_type(json.data);
    });

    new_get_booking_slot({ date: new Date(value - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0], delivery_type_id }).then(
      (booking_slot_data) => {
        if (booking_slot_data.status) {
          set_booking_slot(booking_slot_data.data);
        } else {
          alert(booking_slot_data.message);
          //OriginalCode
          // setDate(new Date().toISOString().split("T")[0]);
          setDate(new Date().toISOString().split("T")[0]);

          set_booking_slot([]);
        }
      }
    );

    get_return_time({ date: new Date(value - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0], pickup_time: booking_slot_id }).then(
      (return_slot) => {
        if (return_slot.status) {
          set_return_slot(return_slot.data);
        }
      }
    );

    calculate_distance({
      start_lat: addressData.start_lat,
      end_lat: addressData.end_lat,
      start_lng: addressData.start_lng,
      end_lng: addressData.end_lng,
    }).then((json) => {
      // set_booking_slot(booking_slot_data.data)
      set_distance(json.data);

      //calculate price after get the distance
      calculate_price({
        date: new Date(value - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0],
        promocode: promocode,
        distance: json.data,
        hour: hour,
        delivery_type_id: delivery_type_id,
        state: addressData.start_state,
        return_time: return_time,
        is_checked_return,
      }).then((json) => {
        // set_booking_slot(booking_slot_data.data)
        // alert(JSON.stringify(json.data))

        if (json.status == false) {
          if (
            json.message.includes("Voucher") ||
            json.message.includes("voucher")
          ) {
            set_promocode("");

            setkeyword("");
            set_promo_valid(false);
            alert(json.message);
            return;
          }
        } else {
          if (promocode != "") {
            set_promo_valid(true);
          } else {
            set_promo_valid(false);
          }
          set_price(json.data);
          setBeforeData(json.before_data);
          set_discount_value(json.discount_value);
          set_price_per_km(json.price_per_km);
          set_distance_from_price(json.distance);
          set_base_fare(json.base_fare);
          set_service_tax(json.service_tax);
          set_price_from_km(json.format);

          set_total_from_price(json.total);
        }
      });
    });
  }, [
    date,
    delivery_type_id,
    promocode,
    hour,
    return_time,
    is_checked_return,
    booking_slot_id,
    addressData.landmark,
    addressData.start_state,
    address,
    session_id,
    addressData.end_state,
    end_address,
  ]);

  React.useEffect(() => {
    validate_booking();
  }, [addressData.start_state, event_changer]);

  function clearPromo() {
    setkeyword("");
    // alert(keyword)

    set_promocode("");
  }
  const validate_booking = async () => {
    let res = await check_available_vehicle({
      delivery_type_id: delivery_type_id,
      time_slot_id: session_id,
      date: new Date(value - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0],
      session_id: session_id,
    });
    if (res.status) {
      set_booking_valid(true);
    } else {
      set_booking_error(res.message);
      set_booking_valid(false);
    }

    let res_location = await check_eligible_location({
      state: addressData.start_state,
      delivery_type_id: delivery_type_id,
    });
    if (res_location.status) {
      set_booking_valid(true);
    } else {
      set_booking_error(res_location.message);
      set_booking_valid(false);
    }
    let stuff = await checkAddressStuff();
  };

  const right = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        itemAlign: "center",
      }}
    >
      NEXT <ArrowForward />
    </div>
  );

  const left = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        itemAlign: "center",
      }}
    >
      <ArrowBack /> PREVIOUS
    </div>
  );

  const [bookingImage, setBookingImage] = React.useState([]);

  const getBookingData = () => {
    get_bookaride_image({}).then((json) => {
      setBookingImage(json.data);
    });
  };

  useEffect(() => {
    getBookingData();
  }, []);

  const isMobile = window.innerWidth <= 768;

  //GOOGLE ADDRESS

  //New Location Function

  const { addUserAddress, clearAddress } = useAddress();

  const [landmark, setLandmark] = React.useState();

  const [destinationLandmark, setDestinationLandmark] = React.useState();

  // console.log("addressData", addressData);

  /// ADD ADDRESS BOOK

  const [addressBook, setAddressBook] = React.useState({
    title: "",
    unit: "",
    landmark: "",
    address: "",
    postcode: "",
    city: "",
    state: "",
    latitude: "",
    longitude: "",
  });

  const [destinationAddressBook, setDestinationAddressBook] = React.useState({
    title: "",
    unit: "",
    landmark: "",
    address: "",
    postcode: "",
    city: "",
    state: "",
    latitude: "",
    longitude: "",
  });

  // GOOGLE MAP

  const mapContainerStyle = {
    height: "440px",
  };

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const [destinationLocation, setDestinationLocation] = React.useState({
    lat: parseFloat(1.5469),
    lng: parseFloat(-103.7781),
    latitude: parseFloat(1.5469),
    longitude: parseFloat(-103.7781),
    latitudeDelta: 0.0421,
    longitudeDelta: 0.0421,
  });

  const loadMarkerDestinationPosition = (e) => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then((e) => {
      if (e.results.length) {
        var city = "";
        var postcode = "";
        var state = "";
        for (var i = 0; i < e.results[0].address_components.length; i++) {
          if (
            inArray("postal_code", e.results[0].address_components[i].types)
          ) {
            postcode = e.results[0].address_components[i].long_name;
          }
          if (inArray("locality", e.results[0].address_components[i].types)) {
            city = e.results[0].address_components[i].long_name;
          }
          if (
            inArray(
              "administrative_area_level_1",
              e.results[0].address_components[i].types
            )
          ) {
            state = e.results[0].address_components[i].long_name;
          }
        }
        setAddressData({
          ...addressData,
          end_address: e.results[0].formatted_address,
          end_landmark: "",
          end_city: city,
          end_postcode: postcode,
          end_state: state,
        });

        setDestinationLocation({
          lat: e.results[0].geometry.location.lat,
          lng: e.results[0].geometry.location.lng,
          latitude: e.results[0].geometry.location.lat,
          longitude: e.results[0].geometry.location.lng,
        });
      }
    });
  };

  const [location, setLocation] = React.useState({
    lat: parseFloat(1.5469),
    lng: parseFloat(-103.7781),
    latitude: parseFloat(1.5469),
    longitude: parseFloat(-103.7781),
    latitudeDelta: 0.0421,
    longitudeDelta: 0.0421,
  });

  const loadMarkerPosition = (e) => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then((e) => {
      if (e.results.length) {
        var city = "";
        var postcode = "";
        var state = "";
        for (var i = 0; i < e.results[0].address_components.length; i++) {
          if (
            inArray("postal_code", e.results[0].address_components[i].types)
          ) {
            postcode = e.results[0].address_components[i].long_name;
          }
          if (inArray("locality", e.results[0].address_components[i].types)) {
            city = e.results[0].address_components[i].long_name;
          }
          if (
            inArray(
              "administrative_area_level_1",
              e.results[0].address_components[i].types
            )
          ) {
            state = e.results[0].address_components[i].long_name;
          }
        }
        setAddressData({
          ...addressData,
          address: e.results[0].formatted_address,
          landmark: "",
          city: city,
          postcode: postcode,
          state: state,
        });

        setLocation({
          lat: e.results[0].geometry.location.lat,
          lng: e.results[0].geometry.location.lng,
          latitude: e.results[0].geometry.location.lat,
          longitude: e.results[0].geometry.location.lng,
        });
      }
    });
  };

  const inArray = (needle, haystack) => {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i] == needle) return true;
    }
    return false;
  };

  // React.useEffect(() => {
  //     Geocode.setApiKey("AIzaSyDLXH_lZuKaQ0rwrl9WRgKiCcdS1qku8BQ");

  //     if ("geolocation" in navigator) {
  //         navigator.geolocation.getCurrentPosition(function (position) {
  //             setLocation({
  //                 lat: parseFloat(position.coords.latitude),
  //                 lng: parseFloat(position.coords.longitude),
  //                 latitude: parseFloat(position.coords.latitude),
  //                 longitude: parseFloat(position.coords.longitude),
  //             });
  //             Geocode.fromLatLng(
  //                 parseFloat(position.coords.latitude),
  //                 parseFloat(position.coords.longitude)
  //             ).then((e) => {
  //                 if (e.results.length) {
  //                     var city = "";
  //                     var postcode = "";
  //                     var state = "";
  //                     for (var i = 0; i < e.results[0].address_components.length; i++) {
  //                         if (
  //                             inArray("postal_code", e.results[0].address_components[i].types)
  //                         ) {
  //                             postcode = e.results[0].address_components[i].long_name;
  //                         }
  //                         if (
  //                             inArray("locality", e.results[0].address_components[i].types)
  //                         ) {
  //                             city = e.results[0].address_components[i].long_name;
  //                         }
  //                         if (
  //                             inArray(
  //                                 "administrative_area_level_1",
  //                                 e.results[0].address_components[i].types
  //                             )
  //                         ) {
  //                             state = e.results[0].address_components[i].long_name;
  //                         }
  //                     }
  //                     setAddressData({
  //                         ...addressData,
  //                         address: e.results[0].formatted_address,
  //                         city: city,
  //                         postcode: postcode,
  //                         state: state,
  //                     });
  //                 }

  //                 console.log(e.results);
  //             });
  //         });
  //     }

  //     Geocode.setApiKey("AIzaSyDLXH_lZuKaQ0rwrl9WRgKiCcdS1qku8BQ");

  //     if ("geolocation" in navigator) {
  //         navigator.geolocation.getCurrentPosition(function (position) {
  //             setDestinationLocation({
  //                 lat: parseFloat(position.coords.latitude),
  //                 lng: parseFloat(position.coords.longitude),
  //                 latitude: parseFloat(position.coords.latitude),
  //                 longitude: parseFloat(position.coords.longitude),
  //             });
  //             Geocode.fromLatLng(
  //                 parseFloat(position.coords.latitude),
  //                 parseFloat(position.coords.longitude)
  //             ).then((e) => {
  //                 if (e.results.length) {
  //                     var city = "";
  //                     var postcode = "";
  //                     var state = "";
  //                     for (var i = 0; i < e.results[0].address_components.length; i++) {
  //                         if (
  //                             inArray("postal_code", e.results[0].address_components[i].types)
  //                         ) {
  //                             postcode = e.results[0].address_components[i].long_name;
  //                         }
  //                         if (
  //                             inArray("locality", e.results[0].address_components[i].types)
  //                         ) {
  //                             city = e.results[0].address_components[i].long_name;
  //                         }
  //                         if (
  //                             inArray(
  //                                 "administrative_area_level_1",
  //                                 e.results[0].address_components[i].types
  //                             )
  //                         ) {
  //                             state = e.results[0].address_components[i].long_name;
  //                         }
  //                     }
  //                     setDestinationAddressData({
  //                         ...destinationAddressData,
  //                         address: e.results[0].formatted_address,
  //                         city: city,
  //                         postcode: postcode,
  //                         state: state,
  //                     });
  //                 }

  //                 console.log(e.results);
  //             });
  //         });
  //     }
  // }, []);

  //DESTINATION ADDRESS BOOK

  // const [isDestinationDefault, setIsDestinationDefault] = React.useState(false);

  const [userDestinationAddressBookData, setUserDestinationAddressBookData] =
    React.useState({});

  const handleChangeDestinationAddress = (e) => {
    setDestinationAddressData({
      ...destinationAddressData,
      [e.target.name]: e.target.value,
    });
    setDestinationAddressBook({
      ...destinationAddressBook,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDestinationAddressBook = (e) => {
    setUserDestinationAddressBookData({
      ...userAddressBookData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleChangeDestinationCheck = (event) => {
  //     setIsDestinationDefault(event.target.checked);
  // };

  //ADDRESS BOOK

  const [userAddressBookData, setUserAddressBookData] = React.useState({});

  const [isDefault, setIsDefault] = React.useState(false);

  const handleChangeAddress = (e) => {
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
    setAddressBook({
      ...addressBook,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeAddressBook = (e) => {
    setUserAddressBookData({
      ...userAddressBookData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeCheck = (event) => {
    setIsDefault(event.target.checked);
  };

  const [chooseTime, setChooseTime] = React.useState();

  const handleChooseTime = (times) => {
    setChooseTime(times);
  };

  //MODAL FOR SHOW DESTINATION

  const [destinationAddressModal, setDestinationAddressModal] =
    React.useState(false);

  const handleOpenDestinationAddressModal = () => {
    setDestinationAddressModal(true);
    getUserDestinationAddressBook();
  };
  const handleCloseDestinationAddressModal = () => {
    setDestinationAddressModal(false);
  };

  // MODAL FOR SHOW START POINT

  const [openAddressModal, setOpenAddressModal] = React.useState(false);

  const handleOpenAddressModal = () => {
    setOpenAddressModal(true);
    getUserAddressBook();
  };
  const handleCloseAddressModal = () => {
    setOpenAddressModal(false);
  };

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    // console.log("CURRENT TOKEN", token);
  }, []);

  const [geocodeAddressList, setGeocodeAddressList] = React.useState([]);

  const [geocodeDestinationAddressList, setGeocodeDestinationAddressList] =
    React.useState([]);

  const [geocodeTextInput, setGeocodeTextInput] = React.useState("");

  const [geocodeDestinationTextInput, setGeocodeDestinationTextInput] =
    React.useState("");

  const googlePlacesSearch = async (value) => {
    google_search(value)
      .then((res) => {
        if (res.status) {
          setGeocodeAddressList(res.data);
          setLandmark(res.data[0].name);
        }

        setIsSearching(false);
      })
      .catch((e) => {
        setIsSearching(false);
      });
  };

  const googleDestinationPlacesSearch = async (value) => {
    google_search(value)
      .then((res) => {
        if (res.status) {
          setGeocodeDestinationAddressList(res.data);
          setDestinationLandmark(res.data[0].name);
        }

        setIsDestinationSearching(false);
      })
      .catch((e) => {
        setIsDestinationSearching(false);
      });
  };

  const [keyDownTimer, setKeyDownTimer] = React.useState(null);

  const [keyDestinationDownTimer, setKeyDestinationDownTimer] =
    React.useState(null);

  const [isSearching, setIsSearching] = React.useState(false);

  const [isDestinationSearching, setIsDestinationSearching] =
    React.useState(false);

  const searchPlaces = (e) => {
    // console.log("Start search", e.target.value);
    googlePlacesSearch(e);
  };

  const searchDestinationPlaces = (e) => {
    googleDestinationPlacesSearch(e);
  };

  const handleChangeDestinationGeocode = (e) => {
    setGeocodeDestinationTextInput(e.target.value);

    let searchAddress = e.target.value;

    setIsDestinationSearching(true);
    var timer = keyDestinationDownTimer;
    clearTimeout(timer);
    // console.log("change", searchAddress);
    var newTimer = setTimeout(() => {
      if (searchAddress != "") {
        searchDestinationPlaces(searchAddress);
      } else {
        setGeocodeDestinationAddressList([]);
        setIsDestinationSearching(false);
      }
    }, 500);
    setKeyDestinationDownTimer(newTimer);

    setDestinationAddressBook({
      ...destinationAddressBook,
      address: searchAddress,
    });
  };

  const handleChangeGeocode = (e) => {
    setGeocodeTextInput(e.target.value);

    let searchAddress = e.target.value;

    setIsSearching(true);
    var timer = keyDownTimer;
    clearTimeout(timer);
    // console.log("change", searchAddress);
    var newTimer = setTimeout(() => {
      if (searchAddress != "") {
        searchPlaces(searchAddress);
      } else {
        setGeocodeAddressList([]);
        setIsSearching(false);
      }
    }, 500);
    setKeyDownTimer(newTimer);

    setAddressBook({
      ...addressBook,
      address: searchAddress,
    });
  };

  const handleChooseAddressFromPlace = async (item) => {
    get_google_place_details(item.place_id).then((res) => {
      if (res.status) {
        var item = res.data.result;
        var city = "";
        var postcode = "";
        var state = "";
        var streetnumber = "";
        var route = "";
        var street = "";

        for (var i = 0; i < item.address_components.length; i++) {
          if (inArray("postal_code", item.address_components[i].types)) {
            postcode = item.address_components[i].long_name;
          }
          if (inArray("locality", item.address_components[i].types)) {
            city = item.address_components[i].long_name;
          }
          if (
            inArray(
              "administrative_area_level_1",
              item.address_components[i].types
            )
          ) {
            state = item.address_components[i].long_name;
          }
          if (inArray("route", item.address_components[i].types)) {
            route = item.address_components[i].long_name;
          }
          if (inArray("street_number", item.address_components[i].types)) {
            streetnumber = item.address_components[i].long_name;
          }
          if (inArray("sublocality", item.address_components[i].types)) {
            street = item.address_components[i].long_name;
          }
        }

        let address = item.formatted_address;

        setLocation({
          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),
          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
          latitudeDelta: 0.0022,
          longitudeDelta: 0.0021,
        });

        setAddressData({
          ...addressData,
          address: address,
          landmark: item.name,
          route: route,
          streetnumber: streetnumber,
          street: street,
          city: city,
          postcode: postcode,
          state: state,
        });

        setAddressBook({
          ...addressBook,
          address: address,
          landmark: item.name,
          route: route,
          streetnumber: streetnumber,
          street: street,
          city: city,
          postcode: postcode,
          state: state,

          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),

          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
        });

        setUserAddressBookData({
          ...userAddressBookData,
          address: address,
          landmark: item.name,
          street: street,
          city: city,
          postcode: postcode,
          state: state,
          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),
          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
        });

        // handleCloseAddressModal();
      }
    });
  };

  const handleChooseAddressFromPlaceEnd = async (item) => {
    get_google_place_details(item.place_id).then((res) => {
      if (res.status) {
        var item = res.data.result;
        var city = "";
        var postcode = "";
        var state = "";
        var streetnumber = "";
        var route = "";
        var street = "";

        for (var i = 0; i < item.address_components.length; i++) {
          if (inArray("postal_code", item.address_components[i].types)) {
            postcode = item.address_components[i].long_name;
          }
          if (inArray("locality", item.address_components[i].types)) {
            city = item.address_components[i].long_name;
          }
          if (
            inArray(
              "administrative_area_level_1",
              item.address_components[i].types
            )
          ) {
            state = item.address_components[i].long_name;
          }
          if (inArray("route", item.address_components[i].types)) {
            route = item.address_components[i].long_name;
          }
          if (inArray("street_number", item.address_components[i].types)) {
            streetnumber = item.address_components[i].long_name;
          }
          if (inArray("sublocality", item.address_components[i].types)) {
            street = item.address_components[i].long_name;
          }
        }

        let address = item.formatted_address;

        setDestinationLocation({
          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),
          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
          latitudeDelta: 0.0022,
          longitudeDelta: 0.0021,
        });

        setAddressData({
          ...addressData,
          end_address: address,
          end_landmark: item.name,
          end_route: route,
          end_streetnumber: streetnumber,
          end_street: street,
          end_city: city,
          end_postcode: postcode,
          end_state: state,
        });

        setDestinationAddressBook({
          ...destinationAddressBook,
          address: address,
          landmark: item.name,
          route: route,
          streetnumber: streetnumber,
          street: street,
          city: city,
          postcode: postcode,
          state: state,

          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),

          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
        });

        setUserDestinationAddressBookData({
          ...userDestinationAddressBookData,
          address: address,
          landmark: destinationLandmark,
          street: street,
          city: city,
          postcode: postcode,
          state: state,
          latitude: parseFloat(item.geometry.location.lat),
          longitude: parseFloat(item.geometry.location.lng),
          lat: parseFloat(item.geometry.location.lat),
          lng: parseFloat(item.geometry.location.lng),
        });

        // handleCloseAddressModal();
      }
    });
  };

  const handleChooseDestinationAddress = (item) => {
    if (item.place_id != undefined) {
      handleChooseAddressFromPlaceEnd(item);
    } else {
      var city = "";
      var postcode = "";
      var state = "";
      var streetnumber = "";
      var route = "";
      var street = "";

      for (var i = 0; i < item.address_components.length; i++) {
        if (inArray("postal_code", item.address_components[i].types)) {
          postcode = item.address_components[i].long_name;
        }
        if (inArray("locality", item.address_components[i].types)) {
          city = item.address_components[i].long_name;
        }
        if (
          inArray(
            "administrative_area_level_1",
            item.address_components[i].types
          )
        ) {
          state = item.address_components[i].long_name;
        }
        if (inArray("route", item.address_components[i].types)) {
          route = item.address_components[i].long_name;
        }
        if (inArray("street_number", item.address_components[i].types)) {
          streetnumber = item.address_components[i].long_name;
        }
        if (inArray("sublocality", item.address_components[i].types)) {
          street = item.address_components[i].long_name;
        }
      }
    }
    let address = item.formatted_address;
    setDestinationLocation({
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
      latitudeDelta: 0.0022,
      longitudeDelta: 0.0021,
    });

    setAddressData({
      ...addressData,
      end_address: address,
      end_landmark: destinationLandmark,
      end_route: route,
      end_streetnumber: streetnumber,
      end_street: street,
      end_city: city,
      end_postcode: postcode,
      end_state: state,
    });

    setDestinationAddressBook({
      ...destinationAddressBook,
      address: address,
      landmark: destinationLandmark,
      route: route,
      streetnumber: streetnumber,
      street: street,
      city: city,
      postcode: postcode,

      state: state,
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
    });

    alert("send end adderss");
    set_end_address(address);
    setShowDestinationAddAddressBook(3);
  };

  const handleChooseAddress = (item) => {
    if (item.place_id != undefined) {
      handleChooseAddressFromPlace(item);
    } else {
      var city = "";
      var postcode = "";
      var state = "";
      var streetnumber = "";
      var route = "";
      var street = "";

      for (var i = 0; i < item.address_components.length; i++) {
        if (inArray("postal_code", item.address_components[i].types)) {
          postcode = item.address_components[i].long_name;
        }
        if (inArray("locality", item.address_components[i].types)) {
          city = item.address_components[i].long_name;
        }
        if (
          inArray(
            "administrative_area_level_1",
            item.address_components[i].types
          )
        ) {
          state = item.address_components[i].long_name;
        }
        if (inArray("route", item.address_components[i].types)) {
          route = item.address_components[i].long_name;
        }
        if (inArray("street_number", item.address_components[i].types)) {
          streetnumber = item.address_components[i].long_name;
        }
        if (inArray("sublocality", item.address_components[i].types)) {
          street = item.address_components[i].long_name;
        }
      }
    }
    let address = item.formatted_address;
    setLocation({
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
      latitudeDelta: 0.0022,
      longitudeDelta: 0.0021,
    });

    setAddressData({
      ...addressData,
      address: address,
      landmark: landmark,
      route: route,
      streetnumber: streetnumber,
      street: street,
      city: city,
      postcode: postcode,
      state: state,
    });

    setAddressBook({
      ...addressBook,
      address: address,
      landmark: landmark,
      route: route,
      streetnumber: streetnumber,
      street: street,
      city: city,
      postcode: postcode,
      state: state,
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
    });

    setShowAddAddressBook(3);
  };

  const handleChooseDestinationAddressEdit = (item) => {
    if (item.place_id != undefined) {
      handleChooseAddressFromPlaceEnd(item);
    } else {
      var city = "";
      var postcode = "";
      var state = "";
      var streetnumber = "";
      var route = "";
      var street = "";

      for (var i = 0; i < item.address_components.length; i++) {
        if (inArray("postal_code", item.address_components[i].types)) {
          postcode = item.address_components[i].long_name;
        }
        if (inArray("locality", item.address_components[i].types)) {
          city = item.address_components[i].long_name;
        }
        if (
          inArray(
            "administrative_area_level_1",
            item.address_components[i].types
          )
        ) {
          state = item.address_components[i].long_name;
        }
        if (inArray("route", item.address_components[i].types)) {
          route = item.address_components[i].long_name;
        }
        if (inArray("street_number", item.address_components[i].types)) {
          streetnumber = item.address_components[i].long_name;
        }
        if (inArray("sublocality", item.address_components[i].types)) {
          street = item.address_components[i].long_name;
        }
      }
    }
    let address = item.formatted_address;

    setDestinationLocation({
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
      latitudeDelta: 0.0022,
      longitudeDelta: 0.0021,
    });

    // setAddressData({
    //   ...addressData,
    //   address: address,
    //   route: route,
    //   streetnumber: streetnumber,
    //   street: street,
    //   city: city,
    //   postcode: postcode,
    //   state: state,
    // });

    setUserDestinationAddressBookData({
      ...userAddressBookData,
      address: address,
      landmark: destinationLandmark,
      street: street,
      city: city,
      postcode: postcode,
      state: state,
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
    });

    setShowDestinationAddAddressBook(4);
  };

  const handleChooseAddressEdit = (item) => {
    if (item.place_id != undefined) {
      handleChooseAddressFromPlace(item);
    } else {
      var city = "";
      var postcode = "";
      var state = "";
      var streetnumber = "";
      var route = "";
      var street = "";

      for (var i = 0; i < item.address_components.length; i++) {
        if (inArray("postal_code", item.address_components[i].types)) {
          postcode = item.address_components[i].long_name;
        }
        if (inArray("locality", item.address_components[i].types)) {
          city = item.address_components[i].long_name;
        }
        if (
          inArray(
            "administrative_area_level_1",
            item.address_components[i].types
          )
        ) {
          state = item.address_components[i].long_name;
        }
        if (inArray("route", item.address_components[i].types)) {
          route = item.address_components[i].long_name;
        }
        if (inArray("street_number", item.address_components[i].types)) {
          streetnumber = item.address_components[i].long_name;
        }
        if (inArray("sublocality", item.address_components[i].types)) {
          street = item.address_components[i].long_name;
        }
      }
    }
    let address = item.formatted_address;

    setLocation({
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
      latitudeDelta: 0.0022,
      longitudeDelta: 0.0021,
    });

    // setAddressData({
    //   ...addressData,
    //   address: address,
    //   route: route,
    //   streetnumber: streetnumber,
    //   street: street,
    //   city: city,
    //   postcode: postcode,
    //   state: state,
    // });

    setUserAddressBookData({
      ...userAddressBookData,
      address: address,
      landmark: landmark,
      street: street,
      city: city,
      postcode: postcode,
      state: state,
      latitude: parseFloat(item.geometry.location.lat),
      longitude: parseFloat(item.geometry.location.lng),
      lat: parseFloat(item.geometry.location.lat),
      lng: parseFloat(item.geometry.location.lng),
    });

    setShowAddAddressBook(4);
  };

  const handleChooseHistoryAddress = (address) => {
    Geocode.fromAddress(address).then((res) => {
      // console.log(res);
      var addresses = [];
      if (res.status == "OK") {
        for (var j = 0; j < res.results.length; j++) {
          var temp_address = "";

          let city = "";
          let postcode = "";
          let state = "";
          let streetnumber = "";
          let route = "";
          let street = "";

          var details = res.results[j];
          for (var i = 0; i < details.address_components.length; i++) {
            if (inArray("postal_code", details.address_components[i].types)) {
              postcode = details.address_components[i].long_name;
            }
            if (inArray("locality", details.address_components[i].types)) {
              city = details.address_components[i].long_name;
            }
            if (
              inArray(
                "administrative_area_level_1",
                details.address_components[i].types
              )
            ) {
              state = details.address_components[i].long_name;
            }
            if (inArray("route", details.address_components[i].types)) {
              route = details.address_components[i].long_name;
            }
            if (inArray("street_number", details.address_components[i].types)) {
              streetnumber = details.address_components[i].long_name;
            }
            if (inArray("sublocality", details.address_components[i].types)) {
              street = details.address_components[i].long_name;
            }
          }

          addresses.push({
            address: res.results[j].formatted_address,
            lat: parseFloat(res.results[j].geometry.location.lat),
            lng: parseFloat(res.results[j].geometry.location.lng),
            latitude: parseFloat(res.results[j].geometry.location.lat),
            longitude: parseFloat(res.results[j].geometry.location.lng),
            city: city,
            postcode: postcode,
            state: state,
            route: route,
            street: street,
            streetnumber: streetnumber,
          });
        }

        if (addresses.length) {
          // console.log("FOUND", addresses);
          var tempaddress = addresses[0];
          setLocation({
            lat: parseFloat(tempaddress.lat),
            lng: parseFloat(tempaddress.lng),
            latitude: parseFloat(tempaddress.lat),
            longitude: parseFloat(tempaddress.lng),
            latitudeDelta: 0.0022,
            longitudeDelta: 0.0021,
          });

          setAddressData({
            ...addressData,
            landmark: landmark,
            address: tempaddress.address,
            route: tempaddress.route,
            streetnumber: tempaddress.streetnumber,
            street: tempaddress.street,
            city: tempaddress.city,
            postcode: tempaddress.postcode,
            state: tempaddress.state,
          });
        }
      }
    });
    handleCloseAddressModal();
  };

  const [deliveryNowTime, setDeliveryNowTime] = React.useState("");

  const handleDeliveryNow = () => {
    // alert('sad')
    new_deliver_now({}).then((json) => {
      // alert(JSON.stringify(json))
      if (json.status) {
        setDeliveryNowTime(json.data.time);

        let postparam = {
          ...addressData,
          // ...deliveryDate,
          // ...time,
          // ...location,
          latitude: location.lat,
          longitude: location.lng,
          time: json.data.time,
        };

        check_eligible_express(postparam).then((res) => {
          if (res) {
            let dataListing = {
              // ...location,
              latitude: location.lat,
              longitude: location.lng,
              ...addressData,
              // ...time,
              // ...deliveryDate
              time: json.data.time,
            };

            addUserAddress(dataListing);

            // console.log(dataListing);

            // console.log('current  ' + deliveryNowTime);

            let array = [];

            array.push(dataListing);

            localStorage.setItem("addressListing", JSON.stringify(array));

            // history.push({
            //     pathname: "/expressproductlist",
            //     state: {
            //         latitude: location.latitude,
            //         longitude: location.longitude,
            //     },
            // });

            // console.log("DATA PASS TO NXT", JSON.stringify(array));
          } else {
            alert(json.message);
          }
        });
      } else {
        alert(json.message);
      }
    });
  };

  const [showAddAddressBook, setShowAddAddressBook] = React.useState(1);

  const handleAddAddressBook = (text) => {
    setShowAddAddressBook(text);
  };

  const [showDestinationAddAddressBook, setShowDestinationAddAddressBook] =
    React.useState(1);

  const handleAddDestinationAddressBook = (text) => {
    setShowDestinationAddAddressBook(text);
  };

  const handleSaveDestinationAddressBook = () => {
    // console.log(destinationAddressBook)
    // if (destinationAddressBook.landmark.length == 0) {
    //     alert('Lankmark Cannot be empty')
    //     return;
    // }
    add_user_address({
      token: token,
      ...destinationAddressBook,
    }).then((json) => {
      if (json.status) {
        alert(json.message);
        handleCloseDestinationAddressModal();
        setShowDestinationAddAddressBook(1);
        getUserDestinationAddressBook();
        setAddressData({
          ...addressData,
          end_address_book_id: json.id,
        });

        handleChooseDestinationAddressBook(json.id);
      } else {
        alert(json.message);
      }
    });
  };

  // ADD

  const handleSaveAddressBook = () => {
    add_user_address({
      token: token,
      ...addressBook,
    }).then((json) => {
      if (json.status) {
        alert(json.message);
        handleCloseAddressModal();
        setShowAddAddressBook(1);
        getUserAddressBook();
        setAddressData({
          ...addressData,
          address_book_id: json.id,
        });
        handleChooseAddressBook(json.id);
      } else {
        alert(json.message);
      }
    });
  };

  // START DESTINATION ADDRESS BOOK
  const [userDestinationAddressBook, setUserDestinationAddressBook] =
    React.useState([]);

  const handleChooseDestinationAddressBook = (user_address_id) => {
    let postparam = {
      user_address_id: user_address_id,
    };
    get_user_address_detail(postparam).then((json) => {
      setUserDestinationAddressBookData(json.data);

      setAddressData({
        ...addressData,
        end_address: json.data.address,
        end_landmark: json.data.landmark,
        end_postcode: json.data.postcode,
        end_city: json.data.city,
        end_state: json.data.state,
        end_unit: json.data.unit,
        end_state: json.data.state,
        end_lat: parseFloat(json.data.latitude),
        end_lng: parseFloat(json.data.longitude),
        end_address_book_id: user_address_id,
      });

      let lat = json.data.latitude;
      let lng = json.data.longitude;

      setDestinationLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lng),
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        latitudeDelta: 0.0022,
        longitudeDelta: 0.0021,
      });

      check_eligible_location({
        state: json.data.state,
        delivery_type_id: delivery_type_id,
      }).then((json) => {
        if (json.status == false) {
          set_booking_error(json.message);
          set_booking_valid(false);
        } else {
          set_booking_valid(true);
        }
      });

      set_address(json.data.address);
    });

    handleCloseDestinationAddressModal();

    // alert(JSON.stringify(userAddressBookData))
  };

  // START ADDRESS BOOK
  const [userAddressBook, setUserAddressBook] = React.useState([]);

  const handleChooseAddressBook = (user_address_id) => {
    let postparam = {
      user_address_id: user_address_id,
    };
    get_user_address_detail(postparam).then((json) => {
      setUserAddressBookData(json.data);

      setAddressData({
        ...addressData,
        address: json.data.address,
        landmark: json.data.landmark,
        postcode: json.data.postcode,
        city: json.data.city,
        state: json.data.state,
        unit: json.data.unit,
        start_state: json.data.state,
        start_lat: parseFloat(json.data.latitude),
        start_lng: parseFloat(json.data.longitude),
        address_book_id: user_address_id,
      });

      let lat = json.data.latitude;
      let lng = json.data.longitude;

      setLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lng),
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        latitudeDelta: 0.0022,
        longitudeDelta: 0.0021,
      });

      check_eligible_location({
        state: json.data.state,

        delivery_type_id: delivery_type_id,
      }).then((json) => {
        if (json.status == false) {
          set_booking_error(json.message);
          set_booking_valid(false);
        } else {
          set_booking_valid(true);
        }
      });

      set_address(json.data.landmark);
    });

    handleCloseAddressModal();

    // alert(JSON.stringify(userAddressBookData))
  };

  const getUserDestinationAddressBook = () => {
    get_user_address({ token: token }).then((json) => {
      setUserDestinationAddressBook(json.data);
    });
  };

  const getUserDestinationAddressBookDetail = (user_address_id) => {
    let postparam = {
      user_address_id: user_address_id,
    };
    get_user_address_detail(postparam).then((json) => {
      setUserDestinationAddressBookData(json.data);

      if (json.data.is_default == "1") {
        setIsDefault(true);
      } else {
        setIsDefault(false);
      }
    });
  };

  React.useEffect(() => {
    getUserDestinationAddressBook();
    // getUserDestinationAddressBookDetail();
  }, []);

  // GET ADDRESS BOOK

  const getUserAddressBook = () => {
    get_user_address({ token: token }).then((json) => {
      setUserAddressBook(json.data);
    });
  };

  const getUserAddressBookDetail = (user_address_id) => {
    let postparam = {
      user_address_id: user_address_id,
    };
    get_user_address_detail(postparam).then((json) => {
      setUserAddressBookData(json.data);

      if (json.data.is_default == "1") {
        setIsDefault(true);
      } else {
        setIsDefault(false);
      }
    });
  };

  React.useEffect(() => {
    getUserAddressBook();
    // getUserAddressBookDetail();
  }, []);

  const getUserAddressID = (user_address_id) => {
    handleAddAddressBook(4);

    getUserAddressBookDetail(user_address_id);
  };

  const getUserDestinationAddressID = (user_address_id) => {
    handleAddDestinationAddressBook(4);

    getUserDestinationAddressBookDetail(user_address_id);

    // console.log(user_address_id);
  };

  const handleEditDestinationAddressBook = () => {
    let res = "0";
    if (isDefault == true) {
      res = "1";
    }
    let postparam = {
      ...userAddressBookData,
      is_default: res,
    };
    edit_user_address(postparam).then((json) => {
      if (json.status) {
        alert(json.message);
        // handleCloseAddress()
        setShowDestinationAddAddressBook(1);
        getUserAddressBook();
      } else {
        alert(json.message);
      }
    });
  };

  const handleEditAddressBook = () => {
    let res = "0";
    if (isDefault == true) {
      res = "1";
    }
    let postparam = {
      ...userAddressBookData,
      is_default: res,
    };
    edit_user_address(postparam).then((json) => {
      if (json.status) {
        alert(json.message);
        // handleCloseAddress()
        setShowAddAddressBook(1);
        getUserAddressBook();
        handleChooseAddressBook(userAddressBookData.user_address_id);
      } else {
        alert(json.message);
      }
    });
  };

  const handleDeleteAddress = () => {
    let postparam = {
      ...userAddressBookData,
      deleted: 1,
    };
    delete_user_address(postparam).then((json) => {
      if (json.status) {
        alert(json.message);
        setShowAddAddressBook(1);
        getUserAddressBook();
      } else {
        alert(json.message);
      }
    });
  };

  const handleDeleteDestionationAddress = () => {
    let postparam = {
      ...userAddressBookData,
      deleted: 1,
    };
    delete_user_address(postparam).then((json) => {
      if (json.status) {
        alert(json.message);
        setShowDestinationAddAddressBook(1);
        getUserAddressBook();
      } else {
        alert(json.message);
      }
    });
  };

  //

  const handleSubmit = () => {
    addressData.fetch_time = booking_slot_id;
    addressData.pickup_location = addressData.address;
    addressData.delivery_type_id = delivery_type_id;
    addressData.destination = addressData.end_address;
    addressData.session_id = session_id;
    addressData.return_time = return_time;
    addressData.date = date;
    addressData.state = addressData.start_state;
    addressData.promocode = promocode;
    addressData.is_checked_return = is_checked_return;
    addressData.distance = distance;
    addressData.hour = hour;
    addressData.pickup_landmark = addressData.landmark;
    addressData.destination_landmark = addressData.end_landmark;

    addressData.passengers = JSON.stringify(passangerList);
    booking(addressData).then((json) => {
      if (json.status) {
        window.location.href = json.url;
      } else {
        alert(json.message);
      }
    });
  };

  // const handleLogin = () => {
  //     history.push('/login')
  // }



  return (
    <React.Fragment>
      <Navbar />
      <LoadScript libraries={["places"]} googleMapsApiKey={GOOGLEMAPKEY}>
        <div className={classes.theBanner}>
          {/* <img src={bg001} /> */}
          <div className={classes.theBooking} id="bookride">
            <Paragraph
              color="#185d6a"
              center
              margin="0 0 .3rem 0"
              className={classes.theResizeFont_}
            >
              Looking for Wheelchair Mobility Service?
            </Paragraph>
            <Paragraph
              className={classes.theResizeFont_}
              color="#185d6a"
              bold="700"
              center
            >
              BOOK YOUR RIDE NOW !
            </Paragraph>
            <br />
            {tab == 0 && (
              <>
                <div className={classes.theForm}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className={classes.theBox}>
                        {addressData.address_book_id != "" ? (
                          <>
                            <GoogleMap
                              id="map"
                              mapContainerStyle={mapContainerStyle}
                              zoom={14}
                              center={location}
                              onLoad={onMapLoad}
                            >
                              <Marker
                                draggable={true}
                                position={location}
                                onDragEnd={loadMarkerPosition}
                              ></Marker>
                            </GoogleMap>
                            <br />
                          </>
                        ) : (
                          <>
                            <img src={bookingImage.bookaride_image} />
                          </>
                        )}
                      </div>

                      <div className={classes.theBox}>
                        {delivery_type_id == 2 ? (
                          <>
                            <GoogleMap
                              id="map"
                              mapContainerStyle={mapContainerStyle}
                              zoom={14}
                              center={destinationLocation}
                              onLoad={onMapLoad}
                            >
                              <Marker
                                draggable={true}
                                position={destinationLocation}
                                onDragEnd={loadMarkerDestinationPosition}
                              ></Marker>
                            </GoogleMap>
                          </>
                        ) : null}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Add />
                          <div style={{ width: "100%" }}>
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={delivery_type_id}
                                onChange={handleChange}
                                placeholder="Type"
                              >
                                <MenuItem value={0}>Type</MenuItem>

                                {delivery_type.map((item) => (
                                  <MenuItem value={item.delivery_type_id}>
                                    {item.title}
                                  </MenuItem>
                                ))}
                                {/* <MenuItem value={20}>Session</MenuItem> */}
                                {/* <MenuItem value={30}>Day</MenuItem> */}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <br />
                        {delivery_type_id == 0 ? null : (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CalendarToday />
                              <div style={{ width: "100%" }}>
                                {/* <FormInput
                                  border="1px solid #cecece"
                                  type="date"
                                  onChange={(e) => setDate(e.target.value)}
                                  min={min_date}
                                  value={date}
                                /> */}
                                <Calendar
                                  onChange={changeCalander}
                                  value={value}
                                  // onChange={(e) => setDate(e.target.value)}
                                  // value={date}
                                  tileDisabled={({ date, view }) =>
                                    view === "month" && // Block day tiles only
                                    disabledDates.some(
                                      (disabledDate) =>
                                        date.getFullYear() ===
                                        disabledDate.getFullYear() &&
                                        date.getMonth() ===
                                        disabledDate.getMonth() + 1 &&
                                        date.getDate() ===
                                        disabledDate.getDate()
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <br />

                        {delivery_type_id != 3 && delivery_type_id != 0 ? (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <AccessTime />
                              <div style={{ width: "100%" }}>
                                <FormControl fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={booking_slot_id}
                                    onChange={(e) =>
                                      set_booking_slot_id(e.target.value)
                                    }
                                  >
                                    <MenuItem value={0}>Time Slot</MenuItem>

                                    {booking_slot.map((item) => (
                                      <MenuItem value={item.time}>
                                        {item.time}
                                      </MenuItem>
                                    ))}
                                    {/* <MenuItem value={20}>Session</MenuItem> */}
                                    {/* <MenuItem value={30}>Day</MenuItem> */}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                            <br />
                          </>
                        ) : null}
                        {delivery_type_id == 0 ? null : (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LocationOn />
                              <div style={{ width: "100%" }}>
                                <Card>
                                  <div onClick={() => handleOpenAddressModal()}>
                                    <div>
                                      <Paragraph
                                        bold="700"
                                        color="#e60c0b"
                                        // margin="0 0 1rem 0"
                                        style={{ fontSize: "1rem" }}
                                      >
                                        {addressData.address == ""
                                          ? "PLEASE CHOOSE YOUR PICKUP LOCATION"
                                          : "CLICK HERE TO CHANGE PICKUP LOCATION"}
                                      </Paragraph>
                                    </div>

                                    {addressData.address != "" && (
                                      <React.Fragment
                                        onClick={() => handleOpenAddressModal()}
                                      >
                                        {/* <Paragraph color="#000" bold="600">
                                                                            {addressData.title}
                                                                        </Paragraph> */}

                                        <Paragraph color="#000" bold="600">
                                          {addressData.landmark}
                                        </Paragraph>
                                        <Paragraph
                                          color="#878787"
                                          bold="400"
                                          style={{ fontSize: 12, width: "95%" }}
                                        >
                                          {addressData.address}
                                        </Paragraph>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </Card>
                              </div>
                            </div>
                          </>
                        )}
                        <br />
                        {delivery_type_id == 3 ? (
                          <Paragraph color="#185d6a" bold="400">
                            Please make your Full Day 1-day in advance. For
                            assistance, please call or WhatsApp 011 6328 9098.
                          </Paragraph>
                        ) : null}

                        {delivery_type_id == 2 && (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Flag />
                              <div style={{ width: "100%" }}>
                                <Card>
                                  <div
                                    onClick={() =>
                                      handleOpenDestinationAddressModal()
                                    }
                                  >
                                    <div>
                                      <Paragraph
                                        bold="700"
                                        color="#e60c0b"
                                        // margin="0 0 1rem 0"
                                        style={{ fontSize: "1rem" }}
                                      >
                                        {addressData.end_address == ""
                                          ? "PLEASE CHOOSE YOUR DESTINATION LOCATION"
                                          : "CLICK HERE TO CHANGE DESTINATION LOCATION"}
                                      </Paragraph>
                                    </div>

                                    {addressData.end_address != "" && (
                                      <React.Fragment
                                        onClick={() =>
                                          handleOpenDestinationAddressModal()
                                        }
                                      >
                                        <Paragraph color="#000" bold="600">
                                          {addressData.end_landmark}
                                        </Paragraph>
                                        <Paragraph
                                          color="#878787"
                                          bold="400"
                                          style={{ fontSize: 12, width: "95%" }}
                                        >
                                          {addressData.end_address}
                                        </Paragraph>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </Card>
                              </div>
                            </div>
                            <br />
                          </>
                        )}
                        {delivery_type_id == 1 && (
                          <>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Timelapse />
                              <div style={{ width: "100%" }}>
                                {/* <FormInput
                                                            margin="0 0 0 0"
                                                            mb="0"
                                                            placeholder="Enter Your Session"
                                                            style={{
                                                                borderBottom: "1px solid #878787",
                                                                borderRadius: "0",
                                                            }}
                                                        /> */}
                                <FormControl fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={session_id}
                                    onChange={(e) =>
                                      set_session_id(e.target.value)
                                    }
                                  >
                                    <MenuItem value={0}>
                                      Select Session
                                    </MenuItem>

                                    {session.map((item) => (
                                      <MenuItem value={item.time_slot_id}>
                                        {item.title}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                            <br />
                          </>
                        )}
                      </div>
                      <br />

                      {delivery_type_id == 2 && (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ width: "100%" }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) => handle_change_return(e)}
                                      name="Return Trip"
                                      checked={
                                        is_checked_return == 1 ? true : false
                                      }
                                    />
                                  }
                                  label="Return Trip"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <br />
                        </>
                      )}
                      {delivery_type_id == 4 && (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ width: "100%" }}>
                              <FormInput
                                margin="0 0 0 0"
                                mb="0"
                                onChange={(e) => handleChangeHour(e)}
                                pattern="^[0-9]*$"
                                value={hour}
                                inputProps={{
                                  maxLength: 13,
                                  step: "1",
                                }}
                                type="number"
                                placeholder="Enter Your Hour"
                                style={{
                                  borderBottom: "1px solid #878787",
                                  borderRadius: "0",
                                }}
                              />
                            </div>
                          </div>
                          <br />
                        </>
                      )}

                      {is_checked_return && delivery_type_id == 2 ? (
                        // <>
                        //     <div style={{ display: "flex", alignItems: "center" }}>
                        //         <Timelapse />
                        //         <div style={{ width: "100%" }}>

                        //             <FormInput
                        //                 margin="0 0 0 0"
                        //                 mb="0"
                        //                 type="time"

                        //                 value={return_time}
                        //                 onChange={(e) => set_return_time(e.target.value)}
                        //                 placeholder="Enter In Minutes"
                        //                 style={{
                        //                     borderBottom: "1px solid #878787",
                        //                     borderRadius: "0",
                        //                 }}

                        //             />
                        //         </div>
                        //     </div>
                        //     <br />
                        // </>
                        // :
                        // null
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <AccessTime />
                            <div style={{ width: "100%" }}>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={return_time}
                                  onChange={(e) =>
                                    set_return_time(e.target.value)
                                  }
                                >
                                  {return_slot.map((item) => (
                                    <MenuItem value={item.time}>
                                      {item.time}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <br />
                          <Paragraph color="#185d6a" bold="400">
                            If you're not sure about your return trip pick-up
                            timing, please select an indicative time slot; to
                            change your pick-up timing, please call or WhatsApp
                            011 6328 9098, we will make the necessary
                            arrangement to accommodate your needs.
                          </Paragraph>
                          <br />
                        </>
                      ) : null}
                      {booking_valid ? (
                        <Button
                          label={right}
                          color="#fff"
                          bg="#115D6A"
                          onClick={() => handleChangeTab(1)}
                        />
                      ) : (
                        <Button
                          label={booking_error}
                          color="#fff"
                          disabled
                          bg="#115D6A"
                        // onClick={() => handleChangeTab(1)}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
            {/* --------------------------------------------------------------------------------------- */}
            {tab == 1 && (
              <>
                <div className={classes.theForm}>
                  <Grid container spacing={3}>
                    {passangerList.map((row, index) => (
                      <>
                        {index < 1 ? (
                          <>
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12}>
                                  {passangerList.length > 1 && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                      }}
                                      onClick={() => {
                                        if (passangerList.length > 1) {
                                          var items = [...passangerList];
                                          items.splice(index, 1);
                                          setPassangerList(items);
                                        } else {
                                          alert("Min 1");
                                        }
                                      }}
                                    >
                                      <Delete style={{ color: "#000" }} />
                                    </div>
                                  )}
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <FormControl fullWidth>
                                    <Select
                                      labelId="demo-simple-se2lect-label"
                                      name="users_id"
                                      id="demo-simple-select2"
                                      value={user_list_id}
                                      onChange={handleChangeUserId}
                                    >
                                      <MenuItem value={0}>
                                        Select A User From List
                                      </MenuItem>

                                      {user_list.map((item) => (
                                        <MenuItem
                                          value={item.orders_passenger_id}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                      {/* <MenuItem value={20}>Session</MenuItem> */}
                                      {/* <MenuItem value={30}>Day</MenuItem> */}
                                    </Select>
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                  <FormInput
                                    margin="0 0 0 0"
                                    mb="0.5rem"
                                    placeholder="Enter Your Name"
                                    type="text"
                                    label="Name"
                                    style={{
                                      borderBottom: "1px solid #878787",
                                      borderRadius: "0",
                                    }}
                                    name="name"
                                    value={row.name}
                                    onChange={(e) =>
                                      handleChangePassanger(e, index)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <FormInput
                                    margin="0 0 0 0"
                                    mb="0.5rem"
                                    placeholder="Enter Your Contact"
                                    type="number"
                                    label="Contact Number"
                                    style={{
                                      borderBottom: "1px solid #878787",
                                      borderRadius: "0",
                                    }}
                                    name="contact"
                                    value={row.contact}
                                    onChange={(e) =>
                                      handleChangePassanger(e, index)
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={(e) =>
                                            handleChangePassanger(e, index)
                                          }
                                          name="is_oku"
                                          checked={
                                            row.is_oku == 1 ? true : false
                                          }
                                        />
                                      }
                                      label="Wheelchair passenger"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm={12} md={12}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12}>
                                  {passangerList.length > 1 && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                      }}
                                      onClick={() => {
                                        if (passangerList.length > 1) {
                                          var items = [...passangerList];
                                          items.splice(index, 1);
                                          setPassangerList(items);
                                        } else {
                                          alert("Min 1");
                                        }
                                      }}
                                    >
                                      <Delete style={{ color: "#000" }} />
                                    </div>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <FormInput
                                    margin="0 0 0 0"
                                    mb="0.5rem"
                                    placeholder="Enter Your Name"
                                    type="text"
                                    label="Name"
                                    style={{
                                      borderBottom: "1px solid #878787",
                                      borderRadius: "0",
                                    }}
                                    // readOnly

                                    name="name"
                                    value={row.name}
                                    onChange={(e) =>
                                      handleChangePassanger(e, index)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <FormInput
                                    margin="0 0 0 0"
                                    mb="0.5rem"
                                    placeholder="Enter Your Contact"
                                    type="text"
                                    label="Contact"
                                    style={{
                                      borderBottom: "1px solid #878787",
                                      borderRadius: "0",
                                    }}
                                    // readOnly
                                    name="contact"
                                    value={row.contact}
                                    onChange={(e) =>
                                      handleChangePassanger(e, index)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={(e) =>
                                            handleChangePassanger(e, index)
                                          }
                                          name="is_oku"
                                          checked={
                                            row.is_oku == 1 ? true : false
                                          }
                                        />
                                      }
                                      label="Wheel chair passenger"
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    ))}

                    <div className={classes.theBtnAdd}>
                      <Button
                        label="Add Passenger"
                        color="#fff"
                        bg="#115D6A"
                        onClick={() => {
                          if (passangerList.length < 4) {
                            var items = [...passangerList];
                            items.push(blankItem());
                            setPassangerList(items);
                          } else {
                            alert("Max 4 passenger");
                          }
                          // console.log(
                          //   passangerList.length,
                          //   "passangerList.length"
                          // );
                        }}
                      />
                    </div>
                  </Grid>
                  <br />
                  <div>
                    <br />
                    <br />

                    <div className={classes.theBtn}>
                      <Button
                        label={left}
                        color="#fff"
                        bg="#115D6A"
                        onClick={() => handleChangeTab(0)}
                      />
                      <br />
                      <Button
                        label={right}
                        color="#fff"
                        bg="#115D6A"
                        onClick={
                          () => handleCheckPassangerList()
                          // console.log('passmgerlist', passangerList)
                          // handleChangeTab(2)
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {tab == 2 && (
              <>
                <div className={classes.theForm}>
                  <Paragraph
                    color="#115D6A"
                    bold="700"
                    size="24px"
                    style={{
                      textDecoration: "underline",
                      marginBottom: "2rem",
                    }}
                  >
                    TRIP DETAIL
                  </Paragraph>
                </div>
                <div className={classes.theForm}>
                  <div className={classes.theInputForm}>
                    <div className={classes.theFormWidth}>
                      <div className={classes.theContentStyle}>
                        <Paragraph
                          color="#115D6A"
                          bold="700"
                          style={{ width: "30%" }}
                        >
                          Booking Type:
                        </Paragraph>
                        <Paragraph
                          style={{
                            width: "70%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {get_delivery_type_text(delivery_type_id)}
                        </Paragraph>
                      </div>

                      <div className={classes.theContentStyle}>
                        <Paragraph
                          color="#115D6A"
                          bold="700"
                          style={{ width: "30%" }}
                        >
                          Date:
                        </Paragraph>
                        <Paragraph
                          style={{
                            width: "70%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {new Date(value - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                        </Paragraph>
                      </div>

                      {delivery_type_id != 3 ? (
                        <>
                          <div className={classes.theContentStyle}>
                            <Paragraph
                              color="#115D6A"
                              bold="700"
                              style={{ width: "30%" }}
                            >
                              Pick Up Time:
                            </Paragraph>
                            <Paragraph
                              style={{
                                width: "70%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {convert_time(booking_slot_id)}
                            </Paragraph>
                          </div>
                        </>
                      ) : null}

                      {delivery_type_id == 4 && (
                        <>
                          <div className={classes.theContentStyle}>
                            <Paragraph
                              color="#115D6A"
                              bold="700"
                              style={{ width: "30%" }}
                            >
                              Booking From {convert_time(booking_slot_id)} to{" "}
                              {parseFloat(booking_slot_id.split(":")[0]) +
                                parseFloat(hour) +
                                ":" +
                                booking_slot_id.split(":")[1].split(" ")[0] +
                                (parseFloat(booking_slot_id.split(":")[0]) +
                                  parseFloat(hour) >
                                  12
                                  ? " pm"
                                  : " am")}
                            </Paragraph>
                            {/* <Paragraph style={{ width: "70%", display: "flex", justifyContent: "flex-end" }} >
                                                            {end_address}
                                                        </Paragraph> */}
                          </div>
                        </>
                      )}
                      <div className={classes.theContentStyle}>
                        <Paragraph
                          color="#115D6A"
                          bold="700"
                          style={{ width: "30%" }}
                        >
                          Pickup Location:
                        </Paragraph>

                        <div className={classes.theLandmark}>
                          <Paragraph color="#000" bold="600">
                            {addressData.landmark}
                          </Paragraph>
                          <Paragraph
                            color="#878787"
                            bold="400"
                            style={{ fontSize: 12 }}
                          >
                            {addressData.address}
                          </Paragraph>
                        </div>
                      </div>

                      {delivery_type_id == 2 && (
                        <>
                          <div className={classes.theContentStyle}>
                            <Paragraph
                              color="#115D6A"
                              bold="700"
                              style={{ width: "30%" }}
                            >
                              Destination:
                            </Paragraph>

                            <div className={classes.theLandmark}>
                              <Paragraph color="#000" bold="600">
                                {addressData.end_landmark}
                              </Paragraph>
                              <Paragraph
                                color="#878787"
                                bold="400"
                                style={{ fontSize: 12 }}
                              >
                                {addressData.end_address}
                              </Paragraph>
                            </div>
                          </div>
                        </>
                      )}

                      {delivery_type_id == 2 && is_checked_return == 1 ? (
                        <>
                          <div className={classes.theContentStyle}>
                            <Paragraph
                              color="#115D6A"
                              bold="700"
                              style={{ width: "30%" }}
                            >
                              Return Trip Time:
                            </Paragraph>
                            <Paragraph
                              style={{
                                width: "70%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {convert_time(return_time)}
                            </Paragraph>
                          </div>
                        </>
                      ) : null}

                      {delivery_type_id == 1 && (
                        <>
                          <div className={classes.theContentStyle}>
                            <Paragraph
                              color="#115D6A"
                              bold="700"
                              style={{ width: "30%" }}
                            >
                              Session:
                            </Paragraph>
                            <Paragraph
                              style={{
                                width: "70%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {get_session_text(session_id)}
                            </Paragraph>
                          </div>
                        </>
                      )}
                    </div>

                    <hr className={classes.theLine} />

                    <div className={classes.theFormWidth}>
                      <Paragraph
                        color="#115D6A"
                        bold="700"
                        margin="0 0 2rem 0"
                        style={{ textDecoration: "underline" }}
                      >
                        Fare Breakdown
                      </Paragraph>

                      {delivery_type_id == 2 && (
                        <>
                          <Paragraph
                            color="#115D6A"
                            bold="700"
                            margin="0 0 2rem 0"
                          >
                            Pick Up Trip
                          </Paragraph>

                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Base Fare (inclusive for first 3 km)
                            </Paragraph>
                            <Paragraph>RM {base_fare}</Paragraph>
                          </div>

                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Distance ({distance} km - 3 km) x RM{" "}
                              {price_per_km}
                            </Paragraph>
                            <Paragraph>RM {price_from_km}</Paragraph>
                          </div>

                          {is_checked_return == 1 && (
                            <>
                              <Paragraph
                                color="#115D6A"
                                bold="700"
                                margin="0 0 2rem 0"
                              >
                                Return Trip
                              </Paragraph>

                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Base Fare (inclusive for first 3 km)
                                </Paragraph>
                                <Paragraph>RM {base_fare}</Paragraph>
                              </div>

                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Distance ({distance} km - 3 km) x RM{" "}
                                  {price_per_km}
                                </Paragraph>
                                <Paragraph>RM {price_from_km}</Paragraph>
                              </div>
                            </>
                          )}
                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Service Tax (6%)
                            </Paragraph>
                            <Paragraph>RM {service_tax}</Paragraph>
                          </div>
                          {promo_valid != false && (
                            <>
                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Promocode
                                </Paragraph>
                                <Paragraph>{promocode}</Paragraph>
                              </div>

                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Discount On Promocode
                                </Paragraph>
                                <Paragraph>RM {discount_value}</Paragraph>
                              </div>
                            </>
                          )}

                          {isMobile ? (
                            <div>
                              <FormInput
                                bold="700"
                                textLabelColor="#115D6A"
                                label="Enter Promo Code"
                                type="text"
                                value={keyword != "0" ? keyword : ""}
                                onChange={(e) => setkeyword(e.target.value)}
                                border="1px solid #878787"
                              />
                              {/* <input type="text" onChange={(e) => setkeyword(e.target.value)}></input> */}
                              <div className={classes.theBtn_}>
                                <div className={classes.theBtnApply}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Apply"
                                    onClick={() => set_promocode(keyword)}
                                  />
                                </div>
                                <div className={classes.theBtnApply_}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Clear Promo"
                                    onClick={() => clearPromo()}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <FormInput
                                bold="700"
                                textLabelColor="#115D6A"
                                label="Enter Promo Code"
                                type="text"
                                value={keyword != "0" ? keyword : ""}
                                onChange={(e) => setkeyword(e.target.value)}
                                border="1px solid #878787"
                                style={{ width: "40%" }}
                              />
                              {/* <input type="text" onChange={(e) => setkeyword(e.target.value)}></input> */}
                              <div className={classes.theBtn_}>
                                <div className={classes.theBtnApply}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Apply"
                                    onClick={() => set_promocode(keyword)}
                                  />
                                </div>
                                <div className={classes.theBtnApply_}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Clear Promo"
                                    onClick={() => clearPromo()}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <hr />
                          <div className={classes.theContentOneStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Total
                            </Paragraph>
                            <Paragraph>RM {price}</Paragraph>
                          </div>
                          <hr />
                        </>
                      )}

                      {delivery_type_id == 4 && (
                        <>
                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Rate (Total {hour} hour)
                            </Paragraph>
                            <Paragraph>RM {beforeData}</Paragraph>
                          </div>

                          {is_checked_return == 1 && (
                            <>
                              <Paragraph
                                color="#115D6A"
                                bold="700"
                                margin="0 0 2rem 0"
                              >
                                Return Trip
                              </Paragraph>

                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Base Fare (inclusive for first 3 km)
                                </Paragraph>
                                <Paragraph>RM {base_fare}</Paragraph>
                              </div>

                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Distance ({distance} km - 3 km) x RM{" "}
                                  {price_per_km}
                                </Paragraph>
                                <Paragraph>RM {price_from_km}</Paragraph>
                              </div>
                            </>
                          )}
                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Service Tax (6%)
                            </Paragraph>
                            <Paragraph>RM {service_tax}</Paragraph>
                          </div>

                          {promo_valid != false && (
                            <>
                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Promocode
                                </Paragraph>
                                <Paragraph>{promocode}</Paragraph>
                              </div>

                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Discount On Promocode
                                </Paragraph>
                                <Paragraph>RM {discount_value}</Paragraph>
                              </div>
                            </>
                          )}

                          {isMobile ? (
                            <div>
                              <FormInput
                                bold="700"
                                textLabelColor="#115D6A"
                                label="Enter Promo Code"
                                type="text"
                                value={keyword != "0" ? keyword : ""}
                                onChange={(e) => setkeyword(e.target.value)}
                                border="1px solid #878787"
                              />
                              <div className={classes.theBtn_}>
                                <div className={classes.theBtnApply}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Apply"
                                    onClick={() => set_promocode(keyword)}
                                  />
                                </div>
                                <div className={classes.theBtnApply_}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Clear Promo"
                                    onClick={() => clearPromo()}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <FormInput
                                bold="700"
                                textLabelColor="#115D6A"
                                label="Enter Promo Code"
                                type="text"
                                value={keyword != "0" ? keyword : ""}
                                onChange={(e) => setkeyword(e.target.value)}
                                border="1px solid #878787"
                                style={{ width: "40%" }}
                              />
                              {/* <input type="text" onChange={(e) => setkeyword(e.target.value)}></input> */}
                              <div className={classes.theBtn_}>
                                <div className={classes.theBtnApply}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Apply"
                                    onClick={() => set_promocode(keyword)}
                                  />
                                </div>
                                <div className={classes.theBtnApply_}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Clear Promo"
                                    onClick={() => clearPromo()}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <hr />
                          <div className={classes.theContentOneStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Total
                            </Paragraph>
                            <Paragraph>RM {price}</Paragraph>
                          </div>
                          <hr />
                        </>
                      )}

                      {delivery_type_id == 1 && (
                        <>
                          <Paragraph
                            color="#115D6A"
                            bold="700"
                            margin="0 0 2rem 0"
                          >
                            By Session
                          </Paragraph>

                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Base Fare (5 hour block)
                            </Paragraph>
                            <Paragraph>RM {beforeData}</Paragraph>
                          </div>

                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Distance (within 150KM radius from pickup point)
                            </Paragraph>
                            <Paragraph>0.00 KM</Paragraph>
                          </div>

                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Service Tax (6%)
                            </Paragraph>
                            <Paragraph>RM {service_tax}</Paragraph>
                          </div>

                          {promo_valid != false && (
                            <>
                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Promocode
                                </Paragraph>
                                <Paragraph>{promocode}</Paragraph>
                              </div>

                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Discount On Promocode
                                </Paragraph>
                                <Paragraph>RM {discount_value}</Paragraph>
                              </div>
                            </>
                          )}
                          {isMobile ? (
                            <div>
                              <FormInput
                                bold="700"
                                textLabelColor="#115D6A"
                                label="Enter Promo Code"
                                type="text"
                                value={keyword != "0" ? keyword : ""}
                                onChange={(e) => setkeyword(e.target.value)}
                                border="1px solid #878787"
                              />
                              {/* <input type="text" onChange={(e) => setkeyword(e.target.value)}></input> */}
                              <div className={classes.theBtn_}>
                                <div className={classes.theBtnApply}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Apply"
                                    onClick={() => set_promocode(keyword)}
                                  />
                                </div>
                                <div className={classes.theBtnApply_}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Clear Promo"
                                    onClick={() => clearPromo()}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <FormInput
                                bold="700"
                                textLabelColor="#115D6A"
                                label="Enter Promo Code"
                                type="text"
                                value={keyword != "0" ? keyword : ""}
                                onChange={(e) => setkeyword(e.target.value)}
                                border="1px solid #878787"
                                style={{ width: "40%" }}
                              />
                              {/* <input type="text" onChange={(e) => setkeyword(e.target.value)}></input> */}
                              <div className={classes.theBtn_}>
                                <div className={classes.theBtnApply}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Apply"
                                    onClick={() => set_promocode(keyword)}
                                  />
                                </div>
                                <div className={classes.theBtnApply_}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Clear Promo"
                                    onClick={() => clearPromo()}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <hr />

                          <div className={classes.theContentOneStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Total
                            </Paragraph>
                            <Paragraph>RM {price}</Paragraph>
                          </div>
                          <hr />
                        </>
                      )}

                      {delivery_type_id == 3 && (
                        <>
                          <Paragraph
                            color="#115D6A"
                            bold="700"
                            margin="0 0 2rem "
                          >
                            By Fullday
                          </Paragraph>
                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Base Fare (8AM TO 6PM)
                            </Paragraph>
                            <Paragraph>RM {beforeData}</Paragraph>
                          </div>

                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Distance (within Johor state)
                            </Paragraph>
                            <Paragraph>0.00 KM</Paragraph>
                          </div>

                          <div className={classes.theContentStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Service Tax (6%)
                            </Paragraph>

                            <Paragraph>RM {service_tax}</Paragraph>
                          </div>
                          {promo_valid != false && (
                            <>
                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Promocode
                                </Paragraph>
                                <Paragraph>{promocode}</Paragraph>
                              </div>

                              <div className={classes.theContentStyle}>
                                <Paragraph color="#115D6A" bold="700">
                                  Discount On Promocode
                                </Paragraph>
                                <Paragraph>RM {discount_value}</Paragraph>
                              </div>
                            </>
                          )}

                          {isMobile ? (
                            <div>
                              <FormInput
                                bold="700"
                                textLabelColor="#115D6A"
                                label="Enter Promo Code"
                                type="text"
                                value={keyword != "0" ? keyword : ""}
                                onChange={(e) => setkeyword(e.target.value)}
                                border="1px solid #878787"
                              />
                              {/* <input type="text" onChange={(e) => setkeyword(e.target.value)}></input> */}
                              <div className={classes.theBtn_}>
                                <div className={classes.theBtnApply}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Apply"
                                    onClick={() => set_promocode(keyword)}
                                  />
                                </div>
                                <div className={classes.theBtnApply_}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Clear Promo"
                                    onClick={() => clearPromo()}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <FormInput
                                bold="700"
                                textLabelColor="#115D6A"
                                label="Enter Promo Code"
                                type="text"
                                value={keyword != "0" ? keyword : ""}
                                onChange={(e) => setkeyword(e.target.value)}
                                border="1px solid #878787"
                                style={{ width: "40%" }}
                              />
                              {/* <input type="text" onChange={(e) => setkeyword(e.target.value)}></input> */}
                              <div className={classes.theBtn_}>
                                <div className={classes.theBtnApply}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Apply"
                                    onClick={() => set_promocode(keyword)}
                                  />
                                </div>
                                <div className={classes.theBtnApply_}>
                                  <Button
                                    color="#fff"
                                    bg="#115D6A"
                                    br="0.25rem"
                                    label="Clear Promo"
                                    onClick={() => clearPromo()}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <hr />
                          <div className={classes.theContentOneStyle}>
                            <Paragraph color="#115D6A" bold="700">
                              Total
                            </Paragraph>
                            <Paragraph>RM {price}</Paragraph>
                          </div>

                          <hr />
                        </>
                      )}

                      <div className={classes.theBtnConfirm}>
                        <Button
                          label={left}
                          color="#fff"
                          br="0.25rem"
                          bg="#115D6A"
                          mb="0.5rem"
                          onClick={() => handleChangeTab(1)}
                        />

                        <Button
                          label="CONFIRM"
                          color="#fff"
                          br="0.25rem"
                          bg="#115D6A"
                          onClick={() => handleSubmit()}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />

        {/* Modal Start Point */}
        <Modal
          open={openAddressModal}
          onClose={handleCloseAddressModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalAddress>
            {showAddAddressBook == 1 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleCloseAddressModal()}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <div className={classes.thePadding}>
                    <Paragraph
                      bold="700"
                      margin="0 0 1rem 0"
                      style={{ fontSize: "1.1rem" }}
                    >
                      SAVED ADDRESS BOOK
                    </Paragraph>
                    <a
                      className={classes.theFlexCreateBtn_}
                      onClick={() => handleAddAddressBook(2)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Bookmark style={{ color: "#000", marginRight: 4 }} />
                        <div>
                          <Paragraph color="#000" bold="600">
                            Create Address Book
                          </Paragraph>
                          <Paragraph
                            color="#878787"
                            bold="400"
                            style={{ fontSize: 12 }}
                          >
                            Save your favourite address
                          </Paragraph>
                        </div>
                      </div>
                      <ArrowForwardIos
                        style={{ fontSize: 16, color: "#000" }}
                      />
                    </a>

                    {userAddressBook.map((item) => (
                      <a
                        className={
                          addressData.address_book_id == item.user_address_id
                            ? classes.theFlexCreateBtn
                            : classes.theFlexCreateBtn_
                        }
                      >
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() =>
                            handleChooseAddressBook(item.user_address_id)
                          }
                        >
                          <Room style={{ color: "#000", marginRight: 4 }} />
                          <div>
                            <Paragraph color="#000" bold="600">
                              {item.landmark}
                            </Paragraph>
                            <Paragraph color="#000" bold="400">
                              {item.title} { }
                            </Paragraph>
                            <Paragraph
                              color="#878787"
                              bold="400"
                              style={{ fontSize: 12, width: "95%" }}
                            >
                              {item.address}
                            </Paragraph>
                          </div>
                        </div>
                        <a
                          onClick={() => getUserAddressID(item.user_address_id)}
                        >
                          <Edit style={{ fontSize: 16, color: "#000" }} />
                        </a>
                      </a>
                    ))}
                  </div>
                </ModalBody>
              </>
            )}

            {showAddAddressBook == 2 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddAddressBook(1)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph
                    bold="700"
                    margin="0 0 1rem 0"
                    style={{ fontSize: "1.2rem" }}
                  >
                    ENTER YOUR ADDRESS
                  </Paragraph>
                  <FormInput
                    bg="#fff"
                    placeholder="Search"
                    name="search"
                    value={geocodeTextInput}
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeGeocode}
                  />
                  {/* <input placeholder="asdasd"/> */}
                  {isSearching == true && (
                    <Loader
                      type="TailSpin"
                      color="#ff8001"
                      height={80}
                      // timeout={3000}
                      width={80}
                    />
                  )}
                  {isSearching == false && geocodeAddressList.length != 0 && (
                    <>
                      {geocodeAddressList.map((item) => (
                        <a
                          className={
                            item.address != addressData.address
                              ? classes.theDefaultAddress
                              : classes.theSelectedAddress
                          }
                          onClick={() => handleChooseAddress(item)}
                        >
                          {item.name != "" && (
                            <Paragraph color="#000" bold="700" mb="4" size="15">
                              {item.name}
                            </Paragraph>
                          )}
                          <Paragraph color="#000">
                            {item.formatted_address}
                          </Paragraph>
                        </a>
                      ))}
                    </>
                  )}
                </ModalBody>
                <br />
              </>
            )}

            {showAddAddressBook == 3 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddAddressBook(2)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph
                    bold="700"
                    margin="0 0 1rem 0"
                    style={{ fontSize: "1.2rem" }}
                  >
                    ADD ADDRESS BOOK
                  </Paragraph>

                  <FormInput
                    bg="#fff"
                    placeholder="Title"
                    name="title"
                    label="Title"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeAddress}
                  />
                  <FormInput
                    bg="#fff"
                    placeholder="Unit / Block"
                    name="unit"
                    label="Unit / Block"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeAddress}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Landmark"
                    name="landmark"
                    label="Landmark"
                    border="1px solid #efefef"
                    bold="600"
                    value={addressBook.landmark}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Address"
                    name="address"
                    label="Address"
                    border="1px solid #efefef"
                    bold="600"
                    value={addressBook.address}
                    multiline
                    rows={4}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Postcode"
                    name="postcode"
                    label="Postcode"
                    border="1px solid #efefef"
                    bold="600"
                    value={addressBook.postcode}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="City"
                    name="city"
                    label="City"
                    border="1px solid #efefef"
                    bold="600"
                    value={addressBook.city}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="State"
                    name="state"
                    label="State"
                    border="1px solid #efefef"
                    bold="600"
                    value={addressBook.state}
                  />

                  <Button
                    label="Submit"
                    bg="#ffa921"
                    border="1px solid #efefef"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleSaveAddressBook()}
                  />
                </ModalBody>
                <br />
              </>
            )}

            {showAddAddressBook == 4 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddAddressBook(1)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph
                    bold="700"
                    margin="0 0 1rem 0"
                    style={{ fontSize: "1.2rem" }}
                  >
                    EDIT ADDRESS BOOK
                  </Paragraph>

                  <FormInput
                    bg="#fff"
                    placeholder="Title"
                    name="title"
                    label="Title"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeAddressBook}
                    value={userAddressBookData.title}
                  />
                  <FormInput
                    bg="#fff"
                    placeholder="Unit / Block"
                    name="unit"
                    label="Unit / Block"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeAddressBook}
                    value={userAddressBookData.unit}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Landmark"
                    name="landmark"
                    label="Landmark"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeAddressBook}
                    value={userAddressBookData.landmark}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Address"
                    name="address"
                    label="Address"
                    border="1px solid #efefef"
                    multiline
                    row="4"
                    bold="600"
                    onChange={handleChangeAddressBook}
                    value={userAddressBookData.address}
                  />

                  {/* <Paragraph
                                        color="#495057"
                                        bold="600"
                                        margin="1rem 0"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        Address
                                    </Paragraph>
                                    <a
                                        className={classes.theCurrentAddress}
                                        onClick={() => handleAddAddressBook(5)}
                                    >
                                        {userAddressBookData.address}
                                    </a> */}

                  <FormInput
                    bg="#fff"
                    placeholder="Postcode"
                    name="postcode"
                    label="Postcode"
                    border="1px solid #efefef"
                    bold="600"
                    value={userAddressBookData.postcode}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="City"
                    name="city"
                    label="City"
                    border="1px solid #efefef"
                    bold="600"
                    value={userAddressBookData.city}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="State"
                    name="state"
                    label="State"
                    border="1px solid #efefef"
                    bold="600"
                    value={userAddressBookData.state}
                  />

                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox
                                            onChange={handleChangeCheck}
                                            color="primary"
                                            inputProps={{
                                                "aria-label": "secondary checkbox",
                                            }}
                                            name="is_default"
                                            checked={isDefault}
                                            value={userAddressBookData.is_default}
                                        />
                                        <Paragraph bold="600" color="#1e1e1e">
                                            Default Address
                                        </Paragraph>
                                    </div>
                                    <br /> */}
                  <Button
                    label="Delete Address"
                    bg="#dc3545"
                    color="#fff"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleDeleteAddress()}
                  />
                  <br />
                  <Button
                    label="Submit"
                    bg="#ffa921"
                    color="#fff"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleEditAddressBook()}
                  />
                </ModalBody>
                <br />
              </>
            )}

            {showAddAddressBook == 5 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddAddressBook(4)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph
                    bold="700"
                    margin="0 0 1rem 0"
                    style={{ fontSize: "1.2rem" }}
                  >
                    ENTER YOUR ADDRESS
                  </Paragraph>
                  <FormInput
                    bg="#fff"
                    placeholder="Search"
                    name="search"
                    value={geocodeTextInput}
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeGeocode}
                    margin="0"
                  />
                  {isSearching == true && (
                    <Loader
                      type="TailSpin"
                      color="#ff8001"
                      height={80}
                      // timeout={3000}
                      width={80}
                    />
                  )}
                  {isSearching == false && geocodeAddressList.length != 0 && (
                    <>
                      {geocodeAddressList.map((item) => (
                        <a
                          className={
                            item.address != addressData.address
                              ? classes.theDefaultAddress
                              : classes.theSelectedAddress
                          }
                          onClick={() => handleChooseAddressEdit(item)}
                        >
                          {item.name != "" && (
                            <Paragraph color="#000" bold="700" mb="4" size="15">
                              {item.name}
                            </Paragraph>
                          )}
                          <Paragraph color="#000">
                            {item.formatted_address}
                          </Paragraph>
                        </a>
                      ))}
                    </>
                  )}
                </ModalBody>
                <br />
              </>
            )}
          </ModalAddress>
        </Modal>

        {/* Modal Destination */}
        <Modal
          open={destinationAddressModal}
          onClose={handleCloseDestinationAddressModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalAddress>
            {showDestinationAddAddressBook == 1 && (
              <>
                <ModalHeader>
                  <FlexIcon
                    onClick={() => handleCloseDestinationAddressModal()}
                  >
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <div className={classes.thePadding}>
                    <Paragraph
                      bold="700"
                      margin="0 0 1rem 0"
                      style={{ fontSize: "1.1rem" }}
                    >
                      SAVED ADDRESS BOOK
                    </Paragraph>
                    <a
                      className={classes.theFlexCreateBtn_}
                      onClick={() => handleAddDestinationAddressBook(2)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Bookmark style={{ color: "#000", marginRight: 4 }} />
                        <div>
                          <Paragraph color="#000" bold="600">
                            Create Address Book
                          </Paragraph>
                          <Paragraph
                            color="#878787"
                            bold="400"
                            style={{ fontSize: 12 }}
                          >
                            Save your favourite address
                          </Paragraph>
                        </div>
                      </div>
                      <ArrowForwardIos
                        style={{ fontSize: 16, color: "#000" }}
                      />
                    </a>

                    {userDestinationAddressBook.map((item) => (
                      <a
                        className={
                          addressData.end_address_book_id ==
                            item.user_address_id
                            ? classes.theFlexCreateBtn
                            : classes.theFlexCreateBtn_
                        }
                      >
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() =>
                            handleChooseDestinationAddressBook(
                              item.user_address_id
                            )
                          }
                        >
                          <Room style={{ color: "#000", marginRight: 4 }} />
                          <div>
                            <Paragraph color="#000" bold="600">
                              {item.landmark}
                            </Paragraph>
                            <Paragraph color="#000" bold="600">
                              {item.title}
                            </Paragraph>
                            <Paragraph
                              color="#878787"
                              bold="400"
                              style={{ fontSize: 12, width: "95%" }}
                            >
                              {item.address}
                            </Paragraph>
                          </div>
                        </div>
                        <a
                          onClick={() =>
                            getUserDestinationAddressID(item.user_address_id)
                          }
                        >
                          <Edit style={{ fontSize: 16, color: "#000" }} />
                        </a>
                      </a>
                    ))}
                  </div>
                </ModalBody>
              </>
            )}

            {showDestinationAddAddressBook == 2 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddDestinationAddressBook(1)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph
                    bold="700"
                    margin="0 0 1rem 0"
                    style={{ fontSize: "1.2rem" }}
                  >
                    ENTER YOUR ADDRESS
                  </Paragraph>
                  <FormInput
                    bg="#fff"
                    placeholder="Search"
                    name="search"
                    value={geocodeDestinationTextInput}
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeDestinationGeocode}
                  />
                  {/* <input placeholder="asdasd"/> */}
                  {isDestinationSearching == true && (
                    <Loader
                      type="TailSpin"
                      color="#ff8001"
                      height={80}
                      // timeout={3000}
                      width={80}
                    />
                  )}
                  {isDestinationSearching == false &&
                    geocodeDestinationAddressList.length != 0 && (
                      <>
                        {geocodeDestinationAddressList.map((item) => (
                          <a
                            className={
                              item.address != destinationAddressData.address
                                ? classes.theDefaultAddress
                                : classes.theSelectedAddress
                            }
                            onClick={() => handleChooseDestinationAddress(item)}
                          >
                            {item.name != "" && (
                              <Paragraph
                                color="#000"
                                bold="700"
                                mb="4"
                                size="15"
                              >
                                {item.name}
                              </Paragraph>
                            )}
                            <Paragraph color="#000">
                              {item.formatted_address}
                            </Paragraph>
                          </a>
                        ))}
                      </>
                    )}
                </ModalBody>
                <br />
              </>
            )}

            {showDestinationAddAddressBook == 3 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddDestinationAddressBook(2)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph
                    bold="700"
                    margin="0 0 1rem 0"
                    style={{ fontSize: "1.2rem" }}
                  >
                    ADD ADDRESS BOOK
                  </Paragraph>

                  <FormInput
                    bg="#fff"
                    placeholder="Title"
                    name="title"
                    label="Title"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeDestinationAddress}
                  />
                  <FormInput
                    bg="#fff"
                    placeholder="Unit / Block"
                    name="unit"
                    label="Unit / Block"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeDestinationAddress}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Landmark"
                    name="landmark"
                    label="Landmark"
                    border="1px solid #efefef"
                    bold="600"
                    value={destinationAddressBook.landmark}
                    onChange={handleChangeDestinationAddress}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Address"
                    name="address"
                    label="Address"
                    border="1px solid #efefef"
                    bold="600"
                    value={destinationAddressBook.address}
                    multiline
                    rows={4}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Postcode"
                    name="postcode"
                    label="Postcode"
                    border="1px solid #efefef"
                    bold="600"
                    value={destinationAddressBook.postcode}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="City"
                    name="city"
                    label="City"
                    border="1px solid #efefef"
                    bold="600"
                    value={destinationAddressBook.city}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="State"
                    name="state"
                    label="State"
                    border="1px solid #efefef"
                    bold="600"
                    value={destinationAddressBook.state}
                  />

                  <Button
                    label="Submit"
                    bg="#ffa921"
                    border="1px solid #efefef"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleSaveDestinationAddressBook()}
                  />
                </ModalBody>
                <br />
              </>
            )}

            {showDestinationAddAddressBook == 4 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddDestinationAddressBook(1)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph
                    bold="700"
                    margin="0 0 1rem 0"
                    style={{ fontSize: "1.2rem" }}
                  >
                    EDIT ADDRESS BOOK
                  </Paragraph>

                  <FormInput
                    bg="#fff"
                    placeholder="Title"
                    name="title"
                    label="Title"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeDestinationAddressBook}
                    value={userDestinationAddressBookData.title}
                  />
                  <FormInput
                    bg="#fff"
                    placeholder="Unit / Block"
                    name="unit"
                    label="Unit / Block"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeDestinationAddressBook}
                    value={userDestinationAddressBookData.unit}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Landmark"
                    name="landmark"
                    label="Landmark"
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeDestinationAddressBook}
                    value={userDestinationAddressBookData.landmark}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="Address"
                    name="address"
                    label="Address"
                    border="1px solid #efefef"
                    multiline
                    row="4"
                    bold="600"
                    onChange={handleChangeDestinationAddressBook}
                    value={userDestinationAddressBookData.address}
                  />

                  {/* <Paragraph
                                        color="#495057"
                                        bold="600"
                                        margin="1rem 0"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        Address
                                    </Paragraph>
                                    <a
                                        className={classes.theCurrentAddress}
                                        onClick={() => handleAddDestinationAddressBook(5)}
                                    >
                                        {userDestinationAddressBookData.address}
                                    </a> */}

                  <FormInput
                    bg="#fff"
                    placeholder="Postcode"
                    name="postcode"
                    label="Postcode"
                    border="1px solid #efefef"
                    bold="600"
                    value={userDestinationAddressBookData.postcode}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="City"
                    name="city"
                    label="City"
                    border="1px solid #efefef"
                    bold="600"
                    value={userDestinationAddressBookData.city}
                  />

                  <FormInput
                    bg="#fff"
                    placeholder="State"
                    name="state"
                    label="State"
                    border="1px solid #efefef"
                    bold="600"
                    value={userDestinationAddressBookData.state}
                  />

                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox
                                            onChange={handleChangeDestinationCheck}
                                            color="primary"
                                            inputProps={{
                                                "aria-label": "secondary checkbox",
                                            }}
                                            name="is_default"
                                            checked={isDestinationDefault}
                                            value={userDestinationAddressBookData.is_default}
                                        />
                                        <Paragraph bold="600" color="#1e1e1e">
                                            Default Address
                                        </Paragraph>
                                    </div>
                                    <br /> */}
                  <Button
                    label="Delete Address"
                    bg="#dc3545"
                    color="#fff"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleDeleteDestionationAddress()}
                  />
                  <br />
                  <Button
                    label="Submit"
                    bg="#ffa921"
                    color="#fff"
                    bold="600"
                    pd="0.6rem 2rem"
                    onClick={() => handleEditDestinationAddressBook()}
                  />
                </ModalBody>
                <br />
              </>
            )}

            {showDestinationAddAddressBook == 5 && (
              <>
                <ModalHeader>
                  <FlexIcon onClick={() => handleAddDestinationAddressBook(4)}>
                    <Close />
                  </FlexIcon>
                </ModalHeader>
                <ModalBody>
                  <Paragraph
                    bold="700"
                    margin="0 0 1rem 0"
                    style={{ fontSize: "1.2rem" }}
                  >
                    ENTER YOUR ADDRESS
                  </Paragraph>
                  <FormInput
                    bg="#fff"
                    placeholder="Search"
                    name="search"
                    value={geocodeDestinationTextInput}
                    border="1px solid #efefef"
                    bold="600"
                    onChange={handleChangeDestinationGeocode}
                    margin="0"
                  />
                  {isDestinationSearching == true && (
                    <Loader
                      type="TailSpin"
                      color="#ff8001"
                      height={80}
                      // timeout={3000}
                      width={80}
                    />
                  )}
                  {isDestinationSearching == false &&
                    geocodeDestinationAddressList.length != 0 && (
                      <>
                        {geocodeDestinationAddressList.map((item) => (
                          <a
                            className={
                              item.address != destinationAddressData.address
                                ? classes.theDefaultAddress
                                : classes.theSelectedAddress
                            }
                            onClick={() =>
                              handleChooseDestinationAddressEdit(item)
                            }
                          >
                            {item.name != "" && (
                              <Paragraph
                                color="#000"
                                bold="700"
                                mb="4"
                                size="15"
                              >
                                {item.name}
                              </Paragraph>
                            )}
                            <Paragraph color="#000">
                              {item.formatted_address}
                            </Paragraph>
                          </a>
                        ))}
                      </>
                    )}
                </ModalBody>
                <br />
              </>
            )}
          </ModalAddress>
        </Modal>
      </LoadScript>
    </React.Fragment>
  );
}
