import axios from "axios";
// const host = "http://178.128.120.86/onle/admin/";
const host = "https://onle.my/admin/";

// const access_endpoint_link = host + "API/";
// const host = "http://localhost/beautyandhealth/";
const access_endpoint_link = host + "API/";


const format_request = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    let username = window.localStorage.getItem("username");
    if (token) {
      postparam["token"] = token;
    }
    if (username) {
      postparam["username"] = username;
    }
  }
  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }

  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  };
  return postOptions;
};

const api_post = async (postparam = {}, end_point) => {
  var response = await fetch(
    access_endpoint_link + end_point,
    format_request(postparam)
  );

  var json = await response.json();
  if (json.status) {
    return json;
  } else {
    if (json.message == "Please login again") {
      window.localStorage.clear();
      //alert(json.message);
      window.location.href = "/login";
      return json;
    } else if (json.message == "Please login again") {
      //   alert(json.message);
      window.localStorage.clear();
      //alert(json.message);
      window.location.href = "/login";
      return json;
    } else {

    }
    return json;
  }
};

export const booking = async (postparam) => {
  return api_post(postparam, "booking");
};


export const get_services = async (postparam) => {
  return api_post(postparam, "get_services");
};

export const get_booking_slot = async (postparam) => {
  return api_post(postparam, "get_booking_slot");
};

export const get_return_time = async (postparam) => {
  return api_post(postparam, "get_return_time");
};


export const check_eligible_location = async (postparam) => {
  return api_post(postparam, "check_eligible_location");
};

export const get_session = async (postparam) => {
  return api_post(postparam, "get_session");
};


export const calculate_price = async (postparam) => {
  return api_post(postparam, "calculate_price");
};

export const check_available_vehicle = async (postparam) => {
  return api_post(postparam, "check_available_vehicle");
};

export const calculate_distance = async (postparam) => {
  return api_post(postparam, "calculate_distance");
};
export const get_delivery_type = async (postparam) => {
  return api_post(postparam, "get_delivery_type");
};

export const get_company = async (postparam) => {
  return api_post(postparam, "get_company");
};

export const get_service_price = async (postparam) => {
  return api_post(postparam, "get_service_price");
};

export const contact_us = async (postparam) => {
  return api_post(postparam, "contact_us");
};

export const get_about_us = async (postparam) => {
  return api_post(postparam, "get_about_us");
};

export const get_team = async (postparam) => {
  return api_post(postparam, "get_team");
};

export const register = async (postparam) => {
  return api_post(postparam, "register");
};

// export const login = async (postparam) => {
//   return api_post(postparam, "login");
// };

export const get_otp = async (postparam) => {
  return api_post(postparam, "get_otp");
};


export const get_passenger = async (postparam) => {
  return api_post(postparam, "get_passenger");
};


export const get_order_history = async (postparam) => {
  return api_post(postparam, "get_order_history");
};

export const get_order_history_detail = async (postparam) => {
  return api_post(postparam, "get_order_history_detail");
};

export const get_user_profile = async (postparam) => {
  return api_post(postparam, "get_user_profile");
};

export const edit_user_profile = async (postparam) => {
  return api_post(postparam, "edit_user_profile");
};

export const get_homepage = async (postparam) => {
  return api_post(postparam, "get_homepage");
};

export const get_banner = async (postparam) => {
  return api_post(postparam, "get_banner");
};

export const get_booking_slide = async (postparam) => {
  return api_post(postparam, "get_booking_slide");
};

export const get_state = async (postparam) => {
  return api_post(postparam, "get_state");
};

export const get_homepage_word = async (postparam) => {
  return api_post(postparam, "get_homepage_word");
};

export const get_bookaride_image = async (postparam) => {
  return api_post(postparam, "get_bookaride_image");
};

export const delete_order = async (postparam) => {
  return api_post(postparam, "delete_order");
};

export const edit_user_address = async (postparam) => {
  return api_post(postparam, "edit_user_address");
};

export const get_user_address_detail = async (postparam) => {
  return api_post(postparam, "get_user_address_detail");
};

export const get_user_address = async (postparam) => {
  return api_post(postparam, "get_user_address");
};

export const add_user_address = async (postparam) => {
  return api_post(postparam, "add_user_address");
};

export const check_eligible_express = async (postparam) => {
  return api_post(postparam, "check_eligible_express");
};

export const new_deliver_now = async (postparam) => {
  return api_post(postparam, "new_deliver_now");
};

export const get_address_list = async (postparam) => {
  return api_post(postparam, "get_address_list");
};

export const get_delivery_time = async (postparam) => {
  return api_post(postparam, "get_delivery_time");
};

export const choose_date_time = async (postparam) => {
  return api_post(postparam, "choose_date_time");
};

export const delete_user_address = async (postparam) => {
  return api_post(postparam, "delete_user_address");
};

export const new_get_booking_slot = async (postparam) => {
  return api_post(postparam, "new_get_booking_slot");
};

export const login_social_email = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login_social_email",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    window.localStorage.setItem("username", json.data.username);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const login = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    window.localStorage.setItem("username", json.data.username);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};


export const login_social_facebook = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "login_social_facebook",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    window.localStorage.setItem("token", json.data.token);
    window.localStorage.setItem("user_id", json.data.user_id);
    window.localStorage.setItem("username", json.data.username);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      alert(json.message);
    }
    return false;
  }
};

export const get_google_place_details = async value => {
  var data = await format_request({
    place_id: encodeURIComponent(value),
  });
  if (data) {
    var response = await fetch(
      access_endpoint_link + 'get_google_place_details',
      data,
    );

    var json = await response.json();
    if (json.status == false) {
      alert(json.message);
    } else {
      return json;
    }
  }
};

export const google_search = async value => {
  var data = await format_request({
    value: encodeURIComponent(value),
  });
  if (data) {
    var response = await fetch(access_endpoint_link + 'google_search', data);

    var json = await response.json();
    if (json.status == false) {
      alert(json.message);
    } else {
      return json;
    }
  }
};

export const get_close_date = async (postparam) => {
  return api_post(postparam, "get_close_date");
};


// export const get_close_date = async (postparam) => {
//   var response = await fetch(
//     access_endpoint_link + "get_close_date ",
//     format_request(postparam)
//   );
//   var json = await response.json();
//   return json;
// };
