import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Close, Search } from "@mui/icons-material";

import logo from '../../assets/images/logo.png'

import {
  NavbarSidebarList,
  NavbarSidebarListLogo,
  CloseButtonBox,
  CloseIcon,
  SearchContainer,
  Box,
  SearchField,
  SearchButtonMoblie,
  SearchIcon,
} from "./styles";
import Button from "../Button/Button";

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
}));

const SidebarMenu = ({ open, onClose, handleDrawerToggle }) => {
  const classes = useStyle();
  const sideDrawerList = (anchor) => (
    <div
      className={classes.sidenavbar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  // SEARCH PRODUCT

  const history = useNavigate();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    if (location.pathname != "/") {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user_id");
      window.localStorage.removeItem("length");
      navigate("/");
      window.location.reload();
    } else {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user_id");
      window.localStorage.removeItem("length");
      navigate("/");
      window.location.reload();
    }
  };

  const [token, setToken] = useState(null);

  useEffect(() => {
    let tokenRes = "";
    tokenRes = window.localStorage.getItem("token");
    if (tokenRes) {
      setToken(tokenRes);
    }
  }, []);

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      {sideDrawerList("left")}
      <List component="nav">
        <NavbarSidebarList>
          <NavbarSidebarListLogo>
            <Link to="/">
              <img src={logo} />
            </Link>
          </NavbarSidebarListLogo>
          <CloseButtonBox>
            <a onClick={handleDrawerToggle}>
              <CloseIcon />
            </a>
          </CloseButtonBox>

          <br />
          <br />
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/"
            >
              <ListItemText
                primary="Home"
                style={{
                  color: '#173d6c',
                  fontWeight: '700 !important'
                }}
              />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/about"
            >
              <ListItemText
                primary="About Us"
                style={{
                  color: '#173d6c',
                  fontWeight: '700 !important'
                }}
              />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/service"
            >
              <ListItemText
                primary="Service"
                style={{
                  color: '#173d6c',
                  fontWeight: '700 !important'
                }}
              />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/pricing"
            >
              <ListItemText
                primary="Pricing"
                style={{
                  color: '#173d6c',
                  fontWeight: '700 !important'
                }}
              />
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{
                textDecoration: "none",
                color: "#000",
                width: "100%",
              }}
              to="/contact"
            >
              <ListItemText
                primary="Support"
                style={{
                  color: '#173d6c',
                  fontWeight: '700 !important'
                }}
              />
            </Link>
          </ListItem>

          {token != null ?
            (
              <>
                <ListItem>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      width: "100%",
                    }}
                    to="/booking"
                  >
                    <ListItemText
                      primary="Booking History"
                      style={{
                        color: '#173d6c',
                        fontWeight: '700 !important'
                      }}
                    />
                  </Link>
                </ListItem>

              </>
            ) : (
              null
            )}
          <br />
          <br />
          {token != null ?
            (
              <>
                <div>
                  <Link to="/bookaride">
                    <Button
                      label="Book A Ride"
                      color="#fff"
                      bg="#0689A7"
                      bold="600"
                      box="2px solid #0689A7"
                    />
                  </Link>
                </div>
              </>
            ) : (
              null
            )}


          <br />
          {token != null ?
            (
              <>
                <div>
                  <Link to="/userprofile">
                    <Button
                      label="User Profile"
                      color="#0689A7"
                      box="2px solid #0689A7"
                      bold="600"
                      mb="1rem"
                    />
                  </Link>
                </div>
                <div>
                  <a onClick={() => handleLogout()}>
                    <Button
                      label="Logout"
                      color="#0689A7"
                      box="2px solid #0689A7"
                      bold="600"
                      mb="1rem"
                    />
                  </a>
                </div>
              </>
            ) : (
              <>
                <div>
                  <Link to="/login">
                    <Button
                      label="Login"
                      color="#0689A7"
                      box="2px solid #0689A7"
                      bold="600"
                      mb="1rem"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="/register">
                    <Button
                      label="Register"
                      color="#0689A7"
                      box="2px solid #0689A7"
                      bold="600"
                      mb="1rem"
                    />
                  </Link>
                </div>
              </>
            )}

        </NavbarSidebarList>
      </List>
    </Drawer>
  );
};

export default SidebarMenu;
