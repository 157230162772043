import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import AddressContextProvider from "./contexts/AddressContext";
import "./App.css";

function App() {
  return (
    <AddressContextProvider>
      <BrowserRouter>
        <Routes></Routes>
      </BrowserRouter>
    </AddressContextProvider>
  );
}

export default App;
