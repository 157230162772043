import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { TextField } from "@mui/material";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const CustomFormControl = styled(TextField)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;

  @media screen and (min-width: 992px) {
    width: 100%;
  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  /* .MuiFormControl-root-MuiTextField-root{
    width: 100%;
  } */
`;

const config = {
  theTextTitle: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },

  theFont: {
    fontSize: "18px",
    ...md("sm", {
      fontSize: "28px",
    }),

    ...md("md", {
      fontSize: "28px",
    }),

    ...md("xmd", {
      fontSize: "34px",
    }),
  },

  theText: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    // width: '100%',
  },

  theResizeFont: {
    fontSize: "24px !important",

    ...md("sm", {
      fontSize: "24px !important",
    }),

    ...md("md", {
      fontSize: "24px !important",
    }),

    ...md("xmd", {
      fontSize: "32px !important",
    }),
  },

  theResizeFont_: {
    fontSize: "18px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "26px !important",
    }),

    ...md("xmd", {
      fontSize: "32px !important",
    }),
  },

  theResizing: {
    width: "54%",
    fontSize: "12px !important",

    ...md("sm", {
      width: "49%",
      fontSize: "16px !important",
    }),

    ...md("md", {
      width: "44%",
      fontSize: "16px !important",
    }),

    ...md("xmd", {
      width: "41%",
      fontSize: "16px !important",
    }),
  },

  theResizeText: {
    fontSize: "14px !important",

    ...md("sm", {
      fontSize: "16px !important",
    }),
  },

  //

  theBox: {
    position: "relative",
    width: "80%",
    margin: "0 auto",
    padding: "2rem",
    boxShadow: "0px 0px 4px 1px rgb(0 0 0 / 8%)",

    ...md("md", {
      width: "50%",
    }),
  },

  theHeader: {
    position: "relative",
    background: "#0689A7",
    padding: "1rem",
  },

  theFlex: {
    display: "flex",
    alignItems: "center",
  },

  theIMG: {
    position: "relative",
    width: "8%",
    marginLeft: '3rem',
    "& img": {
      width: "100%",
    },
  },

  theBtn: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "space-between",
      width: "60%",
      margin: "0 auto",
    }),
  },

  theBorder: {
    border: "1px solid #eaeaea",
    padding: "1rem",
    position: "relative",
    borderRadius: 50,
    marginBottom: "1rem",
  },

  theSendCode: {
    borderRight: "1px solid #d7d7d7",
    borderTop: "1px solid #d7d7d7",
    borderBottom: "1px solid #d7d7d7",
    backgroundColor: "#f5f7fa",
    padding: "10px 20px 10px 20px",
    color: "#909399",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
    width: "165px",
    textAlign: "center",
    fontWeight: "400",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  theContact: {
    border: "1px solid rgb(215,215,215)",
    backgroundColor: "rgb(245, 247, 250)",
    padding: "15px 5px",
    color: "rgb(144, 147, 153)",
    width: "50px",
    textAlign: "center",
    fontWeight: "400"
  }
};

export default makeStyles(config);
