import React, { useState, useEffect } from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SidebarMenu from "../SidebarMenu/SidebarMenu";

import Button from "../Button/Button";

import logo from "../../assets/images/logo.png";
import rectangle from "../../assets/images/rectangle.png";
import headericon from "../../assets/images/headericon.png";
import logoword from "../../assets/images/logoword.png";

import {
  Badge,
  Icon,
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarUpper,
  NavbarLogo,
  NavbarIconList,
  NavbarIconLink,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavLinking,
  SearchContainerDesktop,
  ShapeCircle,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarMiddle,
} from "./styles";
import {
  Box,
  NavbarSidebarList,
  SearchField,
  SearchButton,
  SearchIcon,
} from "./styles";
import Paragraph from "../Typography/Paragraph";
import { Person, LinkedIn, Mail, Phone, Room, DirectionsCar, FiberManualRecord, LocationOn } from "@mui/icons-material";
import { md } from "../../styles/responsive";
import { get_homepage, get_homepage_word } from "../../API/API";
import "./styles.css"

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
  theContact: {
    position: "absolute",
    top: "50%",
    left: "10%",
    transform: "translateY(-50%)",
    display: "flex",
  },
  theRow: {
    display: "flex",
    ...md("sm", {
      marginTop: "4rem",
    }),
    ...md("md", {
      marginTop: "2.9rem",
    }),
  },
  theTopTitle: {
    display: "flex",


    ...md("sm", {
      marginTop: "3rem",
      fontSize: "1rem",
    }),

    ...md("md", {
      marginTop: "4rem",
      marginLeft: "2rem",
      fontSize: "1rem",
    }),

    ...md("xxmd", {
      marginTop: "4rem",
      marginLeft: "2rem",
      fontSize: "1.5rem",
    }),

    ...md("xxl", {
      marginTop: "3rem",
      marginLeft: "2rem",
      fontSize: "2rem",
    }),
  },
}));

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

  const [openSideCart, setOpenSideCart] = React.useState({ right: false });

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = React.useState(null);

  // const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // HANDLE LOGOUT

  const handleLogout = () => {
    if (location.pathname != "/") {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user_id");
      window.localStorage.removeItem("length");
      navigate("/");
      window.location.reload();
    } else {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user_id");
      window.localStorage.removeItem("length");
      navigate("/");
      window.location.reload();
    }
  };

  //HeaderWord

  const [headerData, setHeaderData] = React.useState([]);

  const getHeaderData = () => {
    get_homepage_word({}).then((json) => {
      setHeaderData(json.data);
    });
  };

  //INFO

  const [infoData, setInfoData] = React.useState([]);

  const getInfoData = () => {
    get_homepage({}).then((json) => {
      setInfoData(json.data);
    });
  };

  const [token, setToken] = useState(null);
  const [username, setUsername] = useState(null);

  useEffect(() => {
    let tokenRes = "";
    tokenRes = window.localStorage.getItem("token");
    if (tokenRes) {
      setToken(tokenRes);
    }
    getHeaderData();
    getInfoData();
  }, []);
  // let usernameRes = "";
  const usernameRes = window.localStorage.getItem("username");
  // if (usernameRes) {
  //   setUsername(usernameRes);
  // }
  //

  // const [token, setToken] = React.useState([]);

  // React.useEffect(() => {
  //   let token = window.localStorage.getItem("token");
  //   setToken(token || "");
  //   console.log("CURRENT TOKEN", token);
  // }, []);

  // CART COUNT

  // let user_id = window.localStorage.getItem("user_id");

  // const { getCart } = useCart();

  // const [cart, setCart] = React.useState(getCart);

  // React.useEffect(() => {
  //   setCart(getCart());
  // }, [getCart]);

  // API

  // const [profile, setProfile] = React.useState([]);

  // React.useEffect(() => {
  //   if (token) {
  //     let postparam = {
  //       token: token,
  //     };
  //     get_user_profile(postparam).then((json) => {
  //       setProfile(json.data);
  //     });
  //   }
  // }, []);

  // SEARCH PRODUCT

  // const [search, setSearch] = React.useState("");

  // const handleSearchSubmit = (e) => {
  //   e.preventDefault();
  //   e.persist();
  //   history.push("/searchproduct/" + encodeURIComponent(search));
  //   window.location.reload();
  // };

  // const handleChangeSearch = (e) => {
  //   e.persist();
  //   setSearch(e.target.value);
  // };




  return (
    <NavbarContainer>
      {/* <Container maxWidth="lg"> */}
      <AppBar position="static">
        <Toolbar>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle("left", true)}
              >
                <MenuIcon color="#000" />
              </IconButton>
              <NavbarMobileLogo>
                <Link to="/">
                  <img src={logoword} />
                </Link>
              </NavbarMobileLogo>
              <NavbarUpper>
                <img style={{ width: "700px" }} src={rectangle} />
                <div className={classes.theContact}>
                  <div style={{ display: "flex", padding: "0 1rem" }}>
                    <Phone style={{ color: "#fff", marginRight: 14 }} />
                    <Paragraph>{infoData.contact}</Paragraph>
                  </div>
                  <div style={{ display: "flex", padding: "0 1rem" }}>
                    <Mail style={{ color: "#fff", marginRight: 14 }} />
                    <Paragraph>{infoData.email}</Paragraph>
                  </div>
                  <div style={{ display: "flex", padding: "0 1rem" }}>
                    <Room style={{ color: "#fff" }} />
                    <Paragraph>
                      {infoData.address}
                    </Paragraph>
                  </div>
                </div>
              </NavbarUpper>
              <NavbarIconMobileList>
                <div className="theIcon" onClick={() => handleMenu()}>
                  <span className="theCircle">
                    {/* <img src={account} /> */}
                  </span>
                </div>
              </NavbarIconMobileList>

              <SidebarMenu
                anchor="left"
                open={openSideMenu.left}
                onOpen={handleDrawerToggle("left", true)}
                onClose={handleDrawerToggle("left", false)}
                handleDrawerToggle={handleDrawerToggle("left", false)}
              />
            </>
          ) : (
            <>
              <NavbarList>
                <NavbarUpper>
                  <img style={{ width: "970px" }} src={headericon} />
                  <div className={classes.theContact}>
                    <div style={{ display: "flex", padding: "0 0.5rem 0 2rem" }}>
                      <Paragraph bold="400" className="theTileFont" style={{ marginTop: "0.5rem" }}>{headerData.dot_1}</Paragraph>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Paragraph className="theTileFont" style={{ marginTop: "0.5rem" }}><FiberManualRecord fontSize="small" /></Paragraph>
                    </div>
                    <div style={{ display: "flex", padding: "0 0.5rem" }}>
                      <Paragraph bold="400" className="theTileFont" style={{ marginTop: "0.5rem" }}>{headerData.dot_2}</Paragraph>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Paragraph className="theTileFont" style={{ marginTop: "0.5rem" }}><FiberManualRecord fontSize="small" /></Paragraph>
                    </div>

                    <div style={{ display: "flex", padding: "0 0.5rem" }}>
                      <Paragraph bold="400" className="theTileFont" style={{ marginTop: "0.5rem" }}>{headerData.dot_3}</Paragraph>
                    </div>
                    <div style={{ display: "flex", padding: "0 2rem" }}>
                      <DirectionsCar fontSize="medium" style={{ color: "#fff", marginRight: 10, marginTop: 5 }} />
                      <Paragraph bold="400" className="theTileFont" style={{ marginTop: "0.5rem" }}>
                        {headerData.service_availability}
                      </Paragraph>
                    </div>
                    {/* <div style={{ display: "flex", padding: "0 1rem" }}>
                      <Mail style={{ color: "#fff", marginRight: 14 }} />
                      <Paragraph>{infoData.email}</Paragraph>
                    </div>
                    <div style={{ display: "flex", padding: "0 1rem" }}>
                      <Room style={{ color: "#fff" }} />
                      <Paragraph>
                        {infoData.address}
                      </Paragraph>
                    </div> */}

                  </div>
                </NavbarUpper>

                <NavbarLower>
                  <NavbarLowerList>
                    <NavbarLogo>
                      <a href="/">
                        <img src={logoword} />
                      </a>
                      {/* <div className={classes.theTopTitle}>
                        <Paragraph color="#185D6A" bold="600" center className="theTileFont">Onle Wheelchair Mobility</Paragraph>
                      </div> */}
                    </NavbarLogo>

                    <div className={classes.theRow}>
                      <NavbarLink color="#185D6A" bold="700" to="/">
                        Home
                      </NavbarLink>
                      <NavbarLink color="#185D6A" bold="700" to="/about">
                        About Us
                      </NavbarLink>
                      <NavbarLink color="#185D6A" bold="700" to="/service">
                        Service
                      </NavbarLink>
                      <NavbarLink color="#185D6A" bold="700" to="/pricing">
                        Pricing
                      </NavbarLink>
                      <NavbarLink color="#185D6A" bold="700" to="/contact">
                        Support
                      </NavbarLink>

                      {token != null ?
                        (
                          <>
                            <NavbarLink color="#185D6A" bold="700" to="/booking">
                              Booking History
                            </NavbarLink>
                          </>
                        ) : (
                          null
                        )}


                      {token != null ?
                        (
                          <>
                            <a href="/bookaride">
                              <Button
                                label="Book A Ride"
                                color="#fff"
                                bg="#185D6A"
                                bold="600"
                                br="20px"

                                // box="2px solid #c0522b"
                                style={{ marginRight: 18, marginLeft: "0rem" }}
                              />
                            </a>
                          </>
                        ) : (
                          null
                        )}

                      {token != null ?
                        (
                          <div style={{ display: 'flex', alignItems: 'center', color: "#185D6A", fontWeight: "700", cursor: "pointer" }} onClick={handleMenu}>
                            Hi,{usernameRes}
                          </div>
                        ) : (
                          <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }} onClick={handleMenu}>
                            <Person style={{ color: '#185D6A' }} />
                          </div>
                        )}

                    </div>
                  </NavbarLowerList>
                </NavbarLower>
              </NavbarList>
            </>
          )}

          <>
            <Menu
              id="menu-appbar"
              elevation={0}
              // getContentAnchorEl={null}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              // anchorPosition={{
              //   top: 300,
              // }}
              keepMounted
              // open={open}
              // onClose={handleClose}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {token != null ?
                (
                  <>
                    <MenuItem>
                      <Link to="/userprofile">User Profile</Link>
                    </MenuItem>
                    <MenuItem>
                      <a onClick={() => handleLogout()}>Logout</a>
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem>
                      <Link to="/login">Login</Link>
                    </MenuItem>

                    <MenuItem>
                      <Link to="/register">Register</Link>
                    </MenuItem>
                  </>
                )}
            </Menu>
          </>
        </Toolbar>
      </AppBar>
      {/* </Container> */}
    </NavbarContainer>
  );
};

export default Navbar;
