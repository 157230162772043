import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import bg001 from "../../assets/images/bg001.jpg";
import bghome from "../../assets/images/bghome.JPG";
import { padding } from "@mui/system";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  theBanner: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      // height: '450px',
      // objectFit: 'cover'
    },
  },

  theOverlay: {
    position: "absolute",
    width: "100%",
    height: "99%",
    backgroundColor: "rgba(0,0,0,0.4)",
    top: 0,
    left: 0,
  },

  theBooking: {
    position: "relative",
    // backgroundImage: "url(" + bghome + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: "unset",

    ...md("md", {
      padding: "2rem 1.4rem",
    }),

    "&:before": {
      content: "",
      width: "100%",
      height: "99%",
      backgroundColor: "rgba(0,0,0,0.4)",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  theForm: {
    background: "#fff",
    width: "unset",
    borderRadius: 20,
    padding: "2rem",


    ...md("md", {
      width: "70%",
      position: "absolute",
      transform: "translateY(-50%)",
      top: "50%",
      left: 0,
      right: 0,
      marginLeft: "auto",
      marginRight: "auto",
    }),

  },

  theMap: {
    position: "relative",
    width: "100%",

    ...md("xxmd", {
      position: "relative",
      width: "100%",
      top: "20%",
    }),

    ...md("slg", {
      position: "relative",
      width: "100%",
      top: "15%",
    }),

    ...md("lg", {
      position: "relative",
      width: "100%",
      top: "unset",
    }),

    "& img": {
      width: "100%",
      borderRadius: 20,
    },
  },

  theTextTitle: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },

  theFont: {
    fontSize: "18px",
    ...md("sm", {
      fontSize: "28px",
    }),

    ...md("md", {
      fontSize: "28px",
    }),

    ...md("xmd", {
      fontSize: "34px",
    }),
  },

  theList: {
    position: "relative",
  },

  theBox: {
    position: "relative",
    display: "block",

    "& img": {
      width: "100%",
    },
  },

  theText: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    // width: '100%',
  },

  theResizeFont: {
    fontSize: "20px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "22px !important",
    }),

    ...md("xmd", {
      fontSize: "24px !important",
    }),
  },

  theResizeFont_: {
    fontSize: "18px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "26px !important",
    }),

    ...md("xmd", {
      fontSize: "32px !important",
    }),
  },

  theCardTesti: {
    position: "relative",
    width: "70%",
    // margin: '0 auto',
    margin: "10px auto",
  },

  theQuo: {
    position: "relative",
    width: "10%",
    margin: "0 auto",
    paddingBottom: "1rem",
    "& img": {
      width: "100%",
    },
  },

  theAvatar: {
    position: "relative",
    width: "100%",
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    marginBottom: ".2rem",

    "& img": {
      width: 50,
      height: 50,
      borderRadius: 50,
      objectFit: "cover",
    },
  },

  theResizing: {
    width: "54%",
    fontSize: "12px !important",

    ...md("sm", {
      width: "49%",
      fontSize: "16px !important",
    }),

    ...md("md", {
      width: "44%",
      fontSize: "16px !important",
    }),

    ...md("xmd", {
      width: "41%",
      fontSize: "16px !important",
    }),
  },

  theResizeText: {
    fontSize: "14px !important",

    ...md("sm", {
      fontSize: "16px !important",
    }),
  },

  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theServiceIMG: {
    position: "relative",
    width: "40%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  theDetail: {},

  thePricing: {
    position: "relative",
    // width: "100%",
    background: "#C1DEE5",
    padding: "1rem",
  },

  theBox: {
    position: "relative",
    width: "80%",
    margin: "0 auto",
  },

  theLine: {
    borderBottom: "1px solid #fff",
  },

  theIcon: {
    position: "relative",
    width: "10%",

    ...md("sm", {
      width: "15%",
    }),

    "& img": {
      width: "100%",
    },
  },

  theBtn: {
    display: 'block',

    ...md("sm", {
      display: 'flex',
      justifyContent: 'space-between',
      width: '60%',
      margin: '0 auto'
    }),
  },
  theBookBtn: {
    marginTop: "unset",
    display: "flex",
    alignItems: "center",

    ...md("xmd", {
      marginTop: "3rem"
    }),
    ...md("slg", {
      marginTop: "4rem"
    }),
  },

  theBookFlow: {
    display: "flex",
  },

  theArrow: {
    marginTop: "5rem",
    padding: "0.5rem",
  },

  theIcon: {
    display: "flex",
  },

  theBackground: {
    height: "1050px",

    ...md("xxmd", {
      height: "700px",
    }),

    ...md("xmd", {
      height: "650px",
    }),

    ...md("xl", {
      height: "750px",
    }),
  },

  theServiceDetail: {
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    display: "-webkit-box",
    height: "100px",
    textAlign: "center",
  },

  theServiceTitle: {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    display: "-webkit-box",
    height: "45px",
  },

  theTiltle: {
    color: "#fff",
    fontWeight: "unset",
    fontSize: "14px",
    margin: "unset",
    textAlign: "left",
  },
  theDetail: {
    color: "#fff",
    margin: "unset",
    fontWeight: "100",
    fontSize: "14px",
    textAlign: "left",
  },

  theTiltleImg: {
    display: "flex",
    justifyContent: "left",
    marginLeft: "3rem",

    "& img": {
      width: "25%",
    },
  },

  theBookRideBtn: {
    width: "50%",
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
  },

  thePriceBorder: {
    boxShadow: "0px 0px 9px 3px rgba(0,0,0,0.2)",
    borderRadius: "10px",
  },

  thePriceFlex: {
    display: "flex",
    flexDirection: "column",
  },

  theHeadColor: {
    background: "linear-gradient(180deg, rgba(17,93,106,1) 0%, rgba(33,183,212,1) 100%)",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },

  theTimeFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2rem 1rem",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "100px",
    textAlign: "center",
  },

  theTimeFlexOne: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "2rem 1rem",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "100px",
    textAlign: "center",
  }



};

export default makeStyles(config);
