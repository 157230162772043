import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles, { CustomFormControl, FormInputSelect } from "./styles";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import FormInput from "../../components/FormInput/FormInput";
import Footer from "../../components/Footer/Footer";
import fb from "../../assets/images/fb.png";
import google from "../../assets/images/google.png";
import apple from "../../assets/images/apple.png";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useUser from "../../hooks/useUser";
import toast from "react-simple-toasts";
import { register, get_state } from "../../API/API";
import { Adjust, Apple, Facebook, Google, Room } from "@mui/icons-material";
import {
    Container,
    Grid,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";


export default function Register() {
    const classes = useStyles();
    // const navigate = useNavigate()

    const navigate = useNavigate();
    let { Register } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [Register]);

    const params = useParams();
    // const { set_user } = useUser();

    const [registerData, setRegisterData] = useState({
        username: "",
        contact: "",
        email: "",
        state_id: "",
        dob: "",
        name: "",
        // password: "",
        // password2: "",
    });

    const handleChange = (event) => {
        // console.log(event.target.value, event.target.name);
        var new_data = {
            ...registerData,
            [event.target.name]: event.target.value,
        };
        setRegisterData(new_data);
    };

    const handleRegister = () => {
        register(registerData).then((json) => {
            if (json.status) {
                toast("Register Successful")
                navigate("/");
                // window.localStorage.setItem("token", json.data.token);

                // set_user(json.data);
            } else {
                toast(json.message);
            }
        });
    };

    const [stateData, setStateData] = useState([]);

    const getState = () => {
        get_state().then((json) => {
            setStateData(json.data);
        });
    };

    const handleStateChange = (e) => {
        setRegisterData({
            ...registerData,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        getState();
    }, []);



    return (
        <React.Fragment>
            <Navbar />
            <Section>
                <Container>
                    <div className={classes.theBox}>
                        <Paragraph
                            bold="700"
                            color="#0689A7"
                            className={classes.theResizeFont}
                            margin="0 0 .4rem 0"
                        >
                            WELCOME
                        </Paragraph>
                        <Paragraph bold="600" color="#0689A7">
                            Create An Account
                        </Paragraph>
                        <br />
                        <div className={classes.theForm}>
                            <Paragraph bold="600" color="#000000" style={{ marginBottom: "1rem" }}>
                                Contact Number
                            </Paragraph>
                            <div style={{ display: "flex" }}>
                                <div className={classes.theContact}>+60</div>
                                <CustomFormControl
                                    placeholder="Contact Number"
                                    border="1px solid #d7d7d7"
                                    bold="600"
                                    type="text"
                                    name="contact"
                                    onChange={handleChange}
                                />
                            </div>
                            <FormInput
                                label="User ID"
                                placeholder="User ID"
                                border="1px solid #d7d7d7"
                                bold="600"
                                type="text"
                                name="username"
                                onChange={handleChange}
                            />
                            <FormInput
                                label="Name"
                                placeholder="Name"
                                border="1px solid #d7d7d7"
                                bold="600"
                                type="text"
                                name="name"
                                onChange={handleChange}
                            />
                            <FormInput
                                label="Date Of Birth"
                                placeholder="Date Of Birth"
                                border="1px solid #d7d7d7"
                                bold="600"
                                type="date"
                                name="dob"
                                onChange={handleChange}
                            />
                            <FormInput
                                label="Email"
                                placeholder="Email"
                                border="1px solid #d7d7d7"
                                bold="600"
                                type="email"
                                name="email"
                                onChange={handleChange}
                            />
                            <Paragraph bold="600" margin="1rem 0">
                                State
                            </Paragraph>
                            <FormInputSelect>
                                <Select
                                    name="state_id"
                                    onChange={handleStateChange}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    {stateData.map((item) => (
                                        <MenuItem value={item.state_id}>{item.state}</MenuItem>
                                    ))}
                                </Select>
                            </FormInputSelect>
                            <br />
                            {/* <FormInput
                                label="Password"
                                placeholder="Password"
                                border="1px solid #d7d7d7"
                                bold="600"
                                type="password"
                                name="password"
                                onChange={handleChange}
                            />
                            <FormInput
                                label="Confirm Password"
                                placeholder="Confirm Password"
                                border="1px solid #d7d7d7"
                                bold="600"
                                type="password"
                                name="password2"
                                onChange={handleChange}
                            /> */}
                            {/* <div style={{ display: "flex" }}>
                                <CustomFormControl
                                    placeholder="OTP Code"
                                />
                                <a className={classes.theSendCode}>
                                    GET CODE
                                </a>
                            </div> */}

                            <br />

                            <Button label="REGISTER" color="#fff" bg="#0689A7" className={classes.theButton} onClick={handleRegister} />
                            <br />
                            <hr />
                            <br />
                            <div>
                                {/* <a>
                                    <div
                                        className={classes.theBorder}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <div className={classes.theIMG}>
                                            <img src={fb} />
                                        </div>
                                        <Paragraph margin="0 0 0 1rem" bold="600">
                                            Continue with Facebook
                                        </Paragraph>
                                    </div>
                                </a>
                                <a>
                                    <div
                                        className={classes.theBorder}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <div className={classes.theIMG}>
                                            <img src={google} />
                                        </div>
                                        <Paragraph margin="0 0 0 1rem" bold="600">
                                            Continue with Google
                                        </Paragraph>
                                    </div>
                                </a> */}
                                {/* <a>
                                    <div
                                        className={classes.theBorder}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <div className={classes.theIMG}>
                                            <img src={apple} />
                                        </div>
                                        <Paragraph margin="0 0 0 1rem" bold="600">
                                            Continue with Apple
                                        </Paragraph>
                                    </div>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </Container>
            </Section>
            <Footer />
        </React.Fragment>
    );
}
