import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";

import useStyles from "./styles";

import { useNavigate } from "react-router-dom";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import FormInput from "../../components/FormInput/FormInput";
import { Container, Grid } from "@mui/material";
import Slider from "react-slick";

import Footer from "../../components/Footer/Footer";

import map from "../../assets/images/map.png";
import the001 from "../../assets/images/001.jpg";
import icon001 from "../../assets/images/icon-07.png";
import icon002 from "../../assets/images/icon-08.png";
import icon003 from "../../assets/images/icon-09.png";
import icon004 from "../../assets/images/icon-10.png";
import icon005 from "../../assets/images/icon-11.png";
import icon006 from "../../assets/images/icon-24.png";

import icon007 from "../../assets/images/icon-19.png";
import icon008 from "../../assets/images/icon-20.png";
import icon009 from "../../assets/images/icon-18.png";

import { Adjust, Room } from "@mui/icons-material";

import { get_service_price, get_banner } from "../../API/API";

export default function Pricing() {
  const classes = useStyles();
  // const navigate = useNavigate()

  const [servicePriceData, setServicePriceData] = useState([]);

  const getServicePriceData = () => {
    get_service_price().then((json) => {
      setServicePriceData(json.data);
    })
  }

  const [bannerData, setBannerData] = useState([]);

  const getBanner = () => {
    get_banner({ page: "Price" }).then((json) => {
      setBannerData(json.data);
    })
  }

  useEffect(() => {
    getBanner();
    getServicePriceData();
  }, []);

  const isMobile = window.innerWidth <= 768;


  return (
    <React.Fragment>
      <Navbar />
      <Section>
        <Container>

          {isMobile ?
            (
              <div className={classes.theContainer}>
                <Paragraph
                  color="#605C5C"
                  center
                  bold="700"
                  className={classes.theResizeFont}
                  margin="2rem 0"
                >
                  Price Rate
                </Paragraph>
                <div>
                  <Grid container spacing={3}>
                    {servicePriceData.map((item) => (
                      <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.thePriceBorder}>
                          <div className={classes.thePriceFlex}>
                            <div className={classes.theHeadColor}>
                              <Paragraph
                                center
                                bold="700"
                                color="#fff"
                                size="26px"
                                dangerouslySetInnerHTML={{
                                  __html: item.service
                                }}
                              />

                            </div>
                            <div className={classes.theTimeFlexOne}>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ width: "75%" }}>
                                  <Paragraph
                                    dangerouslySetInnerHTML={{
                                      __html: item.description
                                    }}
                                  />
                                </div>
                                <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <Paragraph bold="700" color="#115D6A">
                                    RM {item.price}
                                  </Paragraph>
                                </div>
                              </div>

                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ width: "75%" }}>
                                  <Paragraph
                                    dangerouslySetInnerHTML={{
                                      __html: item.description_2
                                    }}
                                  />
                                </div>
                                {item.price_2 == "0.00" ?
                                  (
                                    null
                                  ) : (
                                    <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <Paragraph bold="700" color="#115D6A">
                                        RM {item.price_2}
                                      </Paragraph>
                                    </div>
                                  )}

                              </div>
                            </div>

                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
            ) : (
              <div className={classes.thePricingBG}>
                {bannerData.map((item) => (
                  <img src={item.banner_image} className={classes.theBGStyle}/>
                ))}
                <div className={classes.theContainer}>
                  <Paragraph
                    color="#fff"
                    center
                    bold="700"
                    className={classes.theResizeFont}
                    margin="2rem 0"
                  >
                    Price Rate
                  </Paragraph>
                  <div>
                    <Grid container spacing={3}>
                      {servicePriceData.map((item) => (
                        <Grid item xs={12} sm={12} md={4}>
                          <div className={classes.thePriceBorder}>
                            <div className={classes.thePriceFlex}>
                              <div className={classes.theHeadColor}>
                                <Paragraph
                                  center
                                  bold="700"
                                  color="#fff"
                                  size="26px"
                                  dangerouslySetInnerHTML={{
                                    __html: item.service
                                  }}
                                />

                              </div>
                              <div className={classes.theTimeFlexOne}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <div style={{ width: "75%" }}>
                                    <Paragraph
                                      dangerouslySetInnerHTML={{
                                        __html: item.description
                                      }}
                                    />
                                  </div>
                                  <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Paragraph bold="700" color="#115D6A">
                                      RM {item.price}
                                    </Paragraph>
                                  </div>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                  <div style={{ width: "75%" }}>
                                    <Paragraph
                                      dangerouslySetInnerHTML={{
                                        __html: item.description_2
                                      }}
                                    />
                                  </div>
                                  {item.price_2 == "0.00" ?
                                    (
                                      null
                                    ) : (
                                      <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Paragraph bold="700" color="#115D6A">
                                          RM {item.price_2}
                                        </Paragraph>
                                      </div>
                                    )}

                                </div>
                              </div>

                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </div>
            )}


        </Container>
      </Section>
      <Footer />
    </React.Fragment>
  );
}
