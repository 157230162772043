import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";

import useStyles from "./styles";

import { useNavigate } from "react-router-dom";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import FormInput from "../../components/FormInput/FormInput";
import { Container, Grid } from "@mui/material";
import Slider from "react-slick";

import Footer from "../../components/Footer/Footer";

import map from "../../assets/images/map.png";
import the001 from "../../assets/images/001.jpg";
import icon001 from "../../assets/images/icon-07.png";
import icon002 from "../../assets/images/icon-08.png";
import icon003 from "../../assets/images/icon-09.png";
import icon004 from "../../assets/images/icon-10.png";
import icon005 from "../../assets/images/icon-11.png";
import icon006 from "../../assets/images/icon-24.png";

import icon007 from "../../assets/images/icon-19.png";
import icon008 from "../../assets/images/icon-20.png";
import icon009 from "../../assets/images/icon-18.png";

import { Adjust, Room } from "@mui/icons-material";
import { get_services } from "../../API/API";

export default function Service() {
  const classes = useStyles();
  // const navigate = useNavigate()

  const [serviceData, setServiceData] = useState([]);

  const getService = () => {
    get_services({}).then((json) => {
      setServiceData(json.data);
    })
  }

  useEffect(() => {
    getService();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <Section>
        <Container>
          <Paragraph
            color="#605C5C"
            center
            bold="700"
            className={classes.theResizeFont}
            margin="2rem 0"
          >
            Our Services
          </Paragraph>

          <Grid container spacing={3}>
            {serviceData.map((item) => (
              <Grid item xs={12} sm={12} md={4}>
                <Card style={{ marginBottom: "1rem", cursor: "unset" }}>
                  <div className={classes.theServiceIMG}>
                    <img src={item.service_image} />
                  </div>
                  <div>
                    <Paragraph center bold="700" color="#115D6A" margin="1rem 0" className={classes.theServiceTitle} >
                      {item.title}
                    </Paragraph>
                    <p className={classes.theServiceDetail}
                      dangerouslySetInnerHTML={{
                        __html: item.description
                      }} />
                  </div>
                </Card>
              </Grid>
            ))}
            {/* 
            <Grid item xs={12} sm={12} md={4}>
              <Card style={{ marginBottom: "1rem" }}>
                <div className={classes.theServiceIMG}>
                  <img src={icon002} />
                </div>
                <div>
                  <Paragraph center bold="700" color="#115D6A" margin="1rem 0">
                    Dialysis Center
                  </Paragraph>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus porttitor nisl ut est vestibulum auctor. Praesent
                    nunc libero, elementum et sem eu, accumsan condimentum
                    lectus.{" "}
                  </Paragraph>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card style={{ marginBottom: "1rem" }}>
                <div className={classes.theServiceIMG}>
                  <img src={icon003} />
                </div>
                <div>
                  <Paragraph center bold="700" color="#115D6A" margin="1rem 0">
                    Rehabilitation Center
                  </Paragraph>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus porttitor nisl ut est vestibulum auctor. Praesent
                    nunc libero, elementum et sem eu, accumsan condimentum
                    lectus.{" "}
                  </Paragraph>
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Card style={{ marginBottom: "1rem" }}>
                <div className={classes.theServiceIMG}>
                  <img src={icon004} />
                </div>
                <div>
                  <Paragraph center bold="700" color="#115D6A" margin="1rem 0">
                    Daycare Center
                  </Paragraph>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus porttitor nisl ut est vestibulum auctor. Praesent
                    nunc libero, elementum et sem eu, accumsan condimentum
                    lectus.{" "}
                  </Paragraph>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card style={{ marginBottom: "1rem" }}>
                <div className={classes.theServiceIMG}>
                  <img src={icon005} />
                </div>
                <div>
                  <Paragraph center bold="700" color="#115D6A" margin="1rem 0">
                    Medical Escort
                  </Paragraph>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus porttitor nisl ut est vestibulum auctor. Praesent
                    nunc libero, elementum et sem eu, accumsan condimentum
                    lectus.{" "}
                  </Paragraph>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Card style={{ marginBottom: "1rem" }}>
                <div className={classes.theServiceIMG}>
                  <img src={icon006} />
                </div>
                <div>
                  <Paragraph center bold="700" color="#115D6A" margin="1rem 0">
                    Leisure & Event
                  </Paragraph>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus porttitor nisl ut est vestibulum auctor. Praesent
                    nunc libero, elementum et sem eu, accumsan condimentum
                    lectus.{" "}
                  </Paragraph>
                </div>
              </Card>
            </Grid> */}
          </Grid>
        </Container>
      </Section>
      <Footer />
    </React.Fragment>
  );
}
