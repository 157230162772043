import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import bg001 from "../../assets/images/bg001.jpg";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  theBanner: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      // height: '450px',
      // objectFit: 'cover'
    },
  },

  theOverlay: {
    position: "absolute",
    width: "100%",
    height: "99%",
    backgroundColor: "rgba(0,0,0,0.4)",
    top: 0,
    left: 0,
  },

  theTextTitle: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },

  theFont: {
    fontSize: "18px",
    ...md("sm", {
      fontSize: "28px",
    }),

    ...md("md", {
      fontSize: "28px",
    }),

    ...md("xmd", {
      fontSize: "34px",
    }),
  },

  theText: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    // width: '100%',
  },

  theResizeFont: {
    fontSize: "20px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "22px !important",
    }),

    ...md("xmd", {
      fontSize: "24px !important",
    }),
  },

  theResizeFont_: {
    fontSize: "18px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "26px !important",
    }),

    ...md("xmd", {
      fontSize: "32px !important",
    }),
  },

  theResizing: {
    width: "54%",
    fontSize: "12px !important",

    ...md("sm", {
      width: "49%",
      fontSize: "16px !important",
    }),

    ...md("md", {
      width: "44%",
      fontSize: "16px !important",
    }),

    ...md("xmd", {
      width: "41%",
      fontSize: "16px !important",
    }),
  },

  theResizeText: {
    fontSize: "14px !important",

    ...md("sm", {
      fontSize: "16px !important",
    }),
  },

  //

  theCircleSuccess: {
    position: "relative",
    background: "#32CD32",
    borderRadius: 50,
    width: 80,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },

  theCirclePending: {
    position: "relative",
    background: "#FFAA00",
    borderRadius: 50,
    width: 80,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },

  theCircleFailed: {
    position: "relative",
    background: "#FF0000",
    borderRadius: 50,
    width: 80,
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },

  theIcon_: {
    position: "relative",
    width: "50%",

    "& img": {
      width: "100%",
    },
  },

  theIcon: {
    position: "relative",
    width: "10%",

    ...md("sm", {
      width: "15%",
    }),

    "& img": {
      width: "100%",
    },
  },

  theFlex: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "space-between",
    }),
  },

  theBtn: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "space-between",
      width: "60%",
      margin: "0 auto",
    }),
  },

  theAddressWidth: {
    width: "100%"
  },

  theAddressDotWidth: {
    width: "1%"
  },

  theCloseBtn: {
    display: "flex",
    alignItems: "center",
    marginLeft: "0.5rem",
  },

  theFlexLocation: {
    display: "flex",
    flexDirection: "column",
  },

  theDeliveryType: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "38%",
    marginBottom: "1rem",
    flexDirection: "column",

    ...md("md", {
      flexDirection: "row"
    }),
  },

  theDistance:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }
};

export default makeStyles(config);
