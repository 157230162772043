import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles, { CustomFormControl } from "./styles";
import Section from "../../components/Section/Section";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Login() {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <Navbar />
            <Section>
                <Container>
                   
                        <b>
                            Terms of Services
                        </b>
                        <br />
                        <p>
                            Onle Services Websites are owned and operated by Onle Services Sdn. Bhd. (Company No: 202101034757) (“Onle Services”). This Terms of Use Agreement (the “Terms of Use”) enables you to understand your rights and obligations relating to the use of the Onle Services Websites and any services or information made available on the Onle Services Websites (the “Services”). These Terms of Use form a legal agreement between you and the Company (the “Agreement”). You can request a copy of the Terms of Use by emailing us at pdpa@onle.my.

                            By accessing the Onle Services Websites, you acknowledge and agree to these Terms of Use. If you do not agree to be bound by these Terms of Use, please do not access or use the Onle Services Websites.

                            Violations of these Terms of Use may result in termination of your service without notice.
                        </p>
                        <br />
                        <b>
                            Updates
                        </b>
                        <br />
                        <p>
                            We regularly update and amend the Onle Services Websites and may at any time delete or change any information or services provided. Although we will endeavor to ensure all information contained on the Onle Services Websites is accurate, we do not guarantee that all information on Onle Services Websites will be complete and accurate.
                        </p>
                        <br />

                        <b>
                            Content
                        </b>
                        <p>
                            All information provided on the Onle Services or through the Services, or in connection with any communications supported by the Company, including but not limited to communications with appropriate Third Party Service Providers providing service (a “3rd Party Service”) during the service (the “Provider”), is intended to be for general information purposes only. You are solely responsible for satisfying yourself that the Provider is suitably qualified and/or licensed in accordance with local laws to practice in your jurisdiction. If you become aware of any Provider that is not suitably qualified, please let us know by emailing us. We reserve the right and have the sole discretion to (but will not be obliged to) remove any Provider’s profile at any time.
                        </p>
                        <br />

                        <b>
                            Procedures/ Products/ Services
                        </b>
                        <p>
                            The procedures, products, services and devices discussed and/or advertised on the Onle Services Websites and the Services are not applicable to all individuals, patients, clients or all clinical situations. Some critical circumstance are not suitable for the services, and you should always seek independent advice from a medical practitioner in person if you are unsure about whether you have been able to explain or communicate your mobility needs accurately or fully, or if you have any other concerns. We make no claims as to the effectiveness of any such procedures, products, and services.
                        </p>
                        <p>
                            Any services represented on the Onle Services Websites by advertisers, sponsors, and other Services participants, either paid or unpaid, are presented for your awareness and do not necessarily imply appropriateness for any particular individual or prediction of effectiveness, outcome or success.
                        </p>
                        <p>
                            You should not use our services in an emergency, or if you have serious illnesses.
                        </p>

                        <br />
                        <b>
                            Liability
                        </b>
                        <p>
                            Content on the Onle Services Websites (the “Content”) is subject to the following Terms of Use. The authors of such Content (the “Authors”) are solely responsible for their Content. While we hope that you will find all Content informative and educational, neither we nor the Authors make any representations or warranties with respect to any information offered or provided within or through the Content regarding treatment of medical conditions and actions. Under no circumstances, as a result of your use of the Onle Services Websites, will the Authors, the Authors’ employer or sponsor be liable to you or to any other person for any damages or harm – including any direct, indirect, special, incidental, exemplary, consequential or other damages, under tort, contract, strict liability or otherwise. Without limiting the generality of the foregoing, the Authors (and such Authors’ employer and/or sponsor) shall have absolutely no liability for:Content on the Onle Services Websites (the “Content”) is subject to the following Terms of Use. The authors of such Content (the “Authors”) are solely responsible for their Content. While we hope that you will find all Content informative and educational, neither we nor the Authors make any representations or warranties with respect to any information offered or provided within or through the Content regarding treatment of medical conditions and actions. Under no circumstances, as a result of your use of the Onle Services Websites, will the Authors, the Authors’ employer or sponsor be liable to you or to any other person for any damages or harm – including any direct, indirect, special, incidental, exemplary, consequential or other damages, under tort, contract, strict liability or otherwise. Without limiting the generality of the foregoing, the Authors (and such Authors’ employer and/or sponsor) shall have absolutely no liability for:
                        </p>
                        <p>
                            (a) any loss or injury caused, in whole or in part, by the Authors’ actions, omissions, or negligence, in procuring, compiling, or delivering information within or through Content;
                        </p>
                        <p>
                            (b) any errors, omissions, or inaccuracies in such information regardless of how caused, or delays or interruptions in delivery of such information; or
                        </p>
                        <p>
                            (c) any decision made or action taken or not taken in reliance upon such information.
                        </p>
                        <p>
                            This means you should not rely on the Content or make important decisions based on it, and it also means that the Authors are not responsible for what you do or don’t do with the Content. You agree to indemnify and hold the Authors and/or the Company (and their employer, employee, servants, agents and/or sponsors) harmless from any claim or demand, including attorneys’ fees, made by any third party as a result of
                            (1) any Content posted or made available by you through the Onle Services Websites,
                        </p>
                        <p>
                            (2) any violation of law (including a breach of any Local Regulations) that occurs by you through your use of Content, the Onle Services Websites or Services, and/or
                        </p>
                        <p>
                            (3) anything you do using Content the Onle Services Websites and/or the information contained therein.
                        </p>
                        <p>
                            The Company, its licensors, its suppliers, Providers, Authors or any third parties mentioned on Onle Services Websites are not liable for any personal injury, including death, attributable to or caused by your use or misuse of Onle Services Websites or the Content, to the fullest extent permissible by law. Any claims arising in connection with your use of the Onle Services Websites or any Content must be brought within one (1) year of the first date of the event giving rise to such action. Remedies under these Terms of Use are exclusive and are limited to those expressly provided for in these Terms of Use. The limitations of liability in this section do not apply to any claims for personal injury or death caused by negligence, breaches of intellectual property provisions by you or your servants or agents.
                        </p>
                        <br />
                        <b>
                            Modification of Terms of Use
                        </b>
                        <p>
                            We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms of Use at any time, by posting the revised Terms of Use on the Onle Services Websites. You hereby waive any right you might have to receive specific notice of such changes.
                        </p>
                        <br />
                        <b>
                            Legal Use
                        </b>
                        <p>
                            You may not use the Services in any manner that violates any law, regulation, treaty or tariff or infringes on the legal rights of any third party, in any manner which is defamatory, fraudulent, indecent, offensive or deceptive, to threaten, harass, abuse or intimidate others, to damage the name or reputation of the Onle Services, its affiliates, or subsidiaries, to breach security on any computer network, or to access an account that does not belong to you, and in any manner that interferes with other customers’ use and enjoyment of the Services provided by the Onle Services.
                        </p>
                        <p>
                            You agree to take no action which is intended to or would reasonably be expected to harm Onle Services Sdn. Bhd or the Onle Services or its reputation or which would reasonably be expected to lead to unwanted or unfavorable publicity to Onle Services Sdn. Bhd or the Onle Services’ service.
                        </p>
                        <p>
                            You may use the Onle Services Websites only in accordance with these Terms of Use and for lawful purposes as stipulated by law. You may not abuse the Onle Services Websites in any way.
                        </p>

                        <br />
                        <b>
                            Miscellaneous
                        </b>
                        <p>
                            We make no representation that all Content or Services described on or available through the Onle Services Websites are appropriate or available for use in locations outside Malaysia. Users visit the Onle Services Websites and access our Content and Services on their own initiative and are responsible for compliance with local laws. We make no claim that Content or Services are appropriate outside Malaysia.
                        </p>
                        <br />
                        <b>
                            Government Compliance
                        </b>
                        <p>
                            The performance of our obligations under these Terms of Use is subject to existing laws and legal process, and nothing contained in these Terms of Use is in derogation of our right to comply with governmental, court and law enforcement requests or requirements relating to your use of Onle Services Websites and Services or information provided to or gathered by us with respect to such use.
                        </p>
                        <br />
                        <b>
                            Severability and Enforceability
                        </b>
                        <p>
                            If any provision or portion of these Terms of Use is held illegal, invalid, or unenforceable, in whole or in part, it shall be modified to the minimum extent necessary to correct any deficiencies or replaced with a provision which is as close as is legally permissible to the provision found invalid or unenforceable and shall not affect the legality, validity or enforceability of any other provisions or portions of these Terms of Use, and the other provisions of the Terms of Use shall remain in full force and effect.
                        </p>
                        <br />
                        <b>
                            Transfer of Information
                        </b>
                        <p>
                            Any information you provide to us will be transferred to the Company or its related companies or subsidiaries in accordance with applicable laws. Any disputes arising in connection with this the Onle Services will be subject to jurisdiction of the courts of Malaysia. By using the Onle Services’ services, you consent to this transfer of information, the Privacy Policy and the Terms of Use.
                        </p>
                        <br />
                        <b>
                            Choice of Law and Venue
                        </b>
                        <p>
                            These Terms of Use shall be governed by and construed in accordance with the laws of Malaysia.
                        </p>
                        <br />
                        <b>
                            Payment
                        </b>
                        <p>
                            1.	The price of a Service (“Packages”) will be set out via communication or on the website at the time of your purchasing Services from a Provider. The prices may change at any time as they are set at the discretion of the Providers, but price changes will not affect any Services that you have already ordered.
                        </p>
                        <p>
                            2.	You are responsible for paying the price for the Service or Packages and any additional charges (“Charges”). Charges could include:
                            <li>transportation fee;</li>
                            <li>reasonable credit card or other processing fees, where these are incurred of any other related fee;</li>
                            <li>service tax (if applicable);</li>
                            <li>any other related fee.</li>
                        </p>
                        <p>
                            3.	You can make payment via online banking prior to the commencement of the Service.
                        </p>

                        <br />
                        <b>
                            Refund Policy
                        </b>

                        <p>1.	100% Refundable if cancellation is 1 day in-advance (24 hours);</p>
                        <p> 2.	RM20 admin fee deduction for cancellation happen 2 hours before the booked time slot;</p>
                        <p> 3.	No refund if less than 2 hours before the booking time slot.</p>

                        <br />
                        <b>
                            Personal Data
                        </b>
                        <p>
                            The primary reason we collect Personal Data is to enable users and Providers to provide the Services and to enable the content-related functions of the Onle Services Websites. In order to facilitate this, we will need to share your personal data with the Providers whom you have chosen or connected with. Your data will only be shared with that individual and only for the purposes of providing the Services that you have requested.
                        </p>
                        <p>
                            We may use your Personal Data for account and Onle Services maintenance, marketing, billing purposes and user proﬁling. We use and disclose Personal Data relating to payment methods and details to the extent required to process fees for completed mobility service and other transactions.
                        </p>
                        <p>
                            We may use your Personal Data to contact you in respect of the Services. We will send emails to the email addresses provided by you and we will be making calls on the phone numbers provided by you if required. If you have provided an incorrect email address or phone number (or if these details have been changed without notice to us), then you may not receive these communications. Onle Services will not be responsible for any breach of privacy due to correspondence going to an incorrect address or phone number. We may also use your Personal Data to contact you to perform surveys about your opinion of Onle Services or the Services, or of potential new Services that may be offered. By using the Onle Services, you consent to your Personal Data being used by Onle Services for such communications. Should you no longer wish to be contacted, you may opt out of receiving these communications by emailing pdpa@onle.my.
                        </p>
                        <p>
                            We may collect information about your computer and/or mobile device, including your IP address, operating system and browser type, for system administration and to report aggregate information to our advertisers and to generate insights. We may use information about member statistics, trafﬁc patterns for the purposes of our business activities, including sales, marketing, mergers, acquisitions and bankruptcies.
                        </p>
                        <p>
                            Generally, Onle Services does not sell, rent or lease its user lists to third parties. Onle Services may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In addition, Onle Services may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide user support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Onle Services, and they are required to maintain the confidentiality of your information.
                        </p>
                        <p>
                            We may disclose your personal information if we are under a duty to disclose or share such data in order to comply with any legal obligation, or to protect the rights, property, or safety of Onle Services, our users, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                        </p>
                        <p>
                            We may aggregate personal and other data captured through the Onle Services Websites and the Services so that the data is no longer capable of identifying any individual. Aggregated data may cover patterns of usage relating to the Onle Services and its Services and Onle Services reserves the right to use this aggregated information for the purposes of improving and enhancing its Services, developing and selling data and information to third parties, generating and commercializing insights and otherwise for the purposes of our business.
                        </p>
                        <br />
                        <b>
                            Contact Information
                        </b>
                        <p>
                            Please feel free to make comments or inquiries regarding these Terms of Use by contacting:
                        </p>
                        <p>
                            Onle Services Sdn. Bhd. (Company No: 202101034757)
                            <br></br>
                            Address: Suite 25.03A, Level 25, Johor Bahru City Square Office Tower 106-108,
                            <br></br>
                            Jalan Wong Ah Fook, 80000, Johor Bahru
                            <br></br>
                            Email: pdpa@onle.my
                            <br></br>
                            Telephone No: 6011 6328 9098
                            <br></br>
                            Operating Hours: 9am - 6pm from Mondays - Fridays (excluding Public Holidays)
                        </p>
                        <p>
                            Onle Services reserves the right to update, revise, amend and/or modify this Personal Data Protection Notice from time to time and in any manner at its absolute discretion but in accordance with the law. Any material changes will be posted on our website with the updated Personal Data Protection Notice.
                        </p>






                    
                </Container>
            </Section>
            <Footer />
        </React.Fragment>
    );
}
