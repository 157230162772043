import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles, { CustomFormControl } from "./styles";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import FormInput from "../../components/FormInput/FormInput";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import fb from "../../assets/images/fb.png";
import google from "../../assets/images/google.png";
import apple from "../../assets/images/apple.png";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import useUser from "../../hooks/useUser";
import toast from "react-simple-toasts";
import { register } from "../../API/API";
import { Adjust, Apple, Facebook, Google, Room } from "@mui/icons-material";
import { edit_user_profile, get_user_profile } from "../../API/API";



export default function Register() {
    const classes = useStyles();
    // const navigate = useNavigate()

    const navigate = useNavigate();

    const [profileData, setProfileData] = useState({});

    const getProfile = () => {
        get_user_profile({}).then((json) => {
            setProfileData(json.data)
        });
    };

    const [newPassword, setNewPassword] = useState({
        password: "",
        password2: "",
    });

    const handleChangeProfile = (event) => {
        setProfileData({
            ...profileData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = () => {
        var data = {
            ...profileData,
        };
        edit_user_profile(data).then((json) => {
            if (json.status) {
                toast("Profile Has Been Updated");
                getProfile();
                // window.location.reload();
                navigate("/");
            } else {
                toast(json.message);
            }
        });
    };

    useEffect(() => {
        getProfile();
    }, []);

    return (
        <React.Fragment>
            <Navbar />
            <Section>
                {profileData != null ?
                    (
                        <>
                            <Container>
                                <div className={classes.theBox}>
                                    <Paragraph
                                        bold="700"
                                        color="#0689A7"
                                        className={classes.theResizeFont}
                                        margin="0 0 .4rem 0"
                                    >
                                        MY PROFILE
                                    </Paragraph>
                                    <div className={classes.theForm}>
                                        <FormInput
                                            label="User ID"
                                            placeholder="User ID"
                                            border="1px solid #d7d7d7"
                                            bold="600"
                                            type="text"
                                            name="username"
                                            value={profileData.username}
                                            onChange={handleChangeProfile}

                                        />
                                        <Paragraph bold="600" color="#000000" style={{ marginBottom: "1rem" }}>
                                            Contact Number
                                        </Paragraph>
                                        <div style={{ display: "flex" }}>
                                            <div className={classes.theContact}>+60</div>
                                            <CustomFormControl
                                                placeholder="Contact Number"
                                                border="1px solid #d7d7d7"
                                                bold="600"
                                                type="text"
                                                value={profileData.contact}
                                                disabled
                                            />
                                        </div>
                                        <FormInput
                                            label="Name"
                                            placeholder="Name"
                                            border="1px solid #d7d7d7"
                                            bold="600"
                                            type="name"
                                            name="name"
                                            value={profileData.name}
                                            onChange={handleChangeProfile}
                                        />
                                        <FormInput
                                            label="Date Of Birth"
                                            placeholder="Date Of Birth"
                                            border="1px solid #d7d7d7"
                                            bold="600"
                                            type="date"
                                            name="dob"
                                            value={profileData.dob}
                                            onChange={handleChangeProfile}
                                        />
                                        <FormInput
                                            label="Email"
                                            placeholder="Email"
                                            border="1px solid #d7d7d7"
                                            bold="600"
                                            type="email"
                                            name="email"
                                            value={profileData.email}
                                            onChange={handleChangeProfile}
                                        />
                                        {/* <FormInput
                                            label="Password"
                                            placeholder="Password"
                                            border="1px solid #d7d7d7"
                                            bold="600"
                                            type="password"
                                            name="password"
                                            value={newPassword.password}
                                            onChange={(event) =>
                                                setNewPassword({
                                                    ...newPassword,
                                                    password: event.target.value,
                                                })
                                            }
                                        />
                                        <FormInput
                                            label="Confirm Password"
                                            placeholder="Confirm Password"
                                            border="1px solid #d7d7d7"
                                            bold="600"
                                            type="password"
                                            name="password2"
                                            value={newPassword.password2}
                                            onChange={(event) =>
                                                setNewPassword({
                                                    ...newPassword,
                                                    password2: event.target.value,
                                                })
                                            }
                                        /> */}
                                        {/* <div style={{ display: "flex" }}>
                                <CustomFormControl
                                    placeholder="OTP Code"
                                />
                                <a className={classes.theSendCode}>
                                    GET CODE
                                </a>
                            </div> */}
                                        <br />
                                        <Button label="SAVE" color="#fff" bg="#0689A7" onClick={handleSubmit} />
                                    </div>
                                </div>
                            </Container>
                        </>
                    ) : (
                        null
                    )}
            </Section>
            <Footer />
        </React.Fragment>
    );
}
