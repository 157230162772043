import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { FooterContainer, FooterList, NavbarLink, NavbarLogo } from "./styles";

import useStyles from "./styles";

import Paragraph from "../Typography/Paragraph";
import {
  Facebook,
  Instagram,
  Mail,
  Phone,
  Room,
  Twitter,
  YouTube,
  WhatsApp,
} from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import { LocationOn } from "@mui/icons-material";
import { get_homepage } from "../../API/API";
import logoword from "../../assets/images/logoword.png";



const Footer = () => {
  const classes = useStyles();

  //INFO

  const [infoData, setInfoData] = React.useState([]);

  const getInfoData = () => {
    get_homepage({}).then((json) => {
      setInfoData(json.data);
    });
  };

  useEffect(() => {
    getInfoData();
  }, []);

  return (
    <FooterContainer>
      {/* <Container> */}
      <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          // alignItems: "flex-start",
          marginTop: ".6rem",
        }}
      >
        <Grid item xs={12} sm={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
          <FooterList style={{ textAlign: "center" }}>
            <NavbarLogo>
              <img src={logoword} />
              {/* <div>
                <Paragraph color="#185D6A" bold="600" center className="theTileFont">Onle Mobility Service</Paragraph>
              </div> */}
            </NavbarLogo>
          </FooterList>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          style={{
            background: "#0689A7",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Container>
            <div style={{ padding: '1rem' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2}>
                  {/* <Paragraph color="#fff" margin="0 0 1rem 0" bold="700">
                    Links
                  </Paragraph> */}
                  <NavbarLink color="#fff" bold="600" href="/">
                    Home
                  </NavbarLink>
                  <NavbarLink color="#fff" bold="600" href="/about">
                    About Us
                  </NavbarLink>
                  <NavbarLink color="#fff" bold="600" href="/service">
                    Service
                  </NavbarLink>
                  <NavbarLink color="#fff" bold="600" href="/pricing">
                    Pricing
                  </NavbarLink>
                  <NavbarLink color="#fff" bold="600" href="/contact">
                    Support
                  </NavbarLink>
                </Grid>
                <Grid item xs={12} sm={6} md={7}>
                  {/* <Paragraph color="#fff" margin="0 0 1rem 0" bold="700">
                    {infoData.name}
                    <br />
                    {infoData.position}
                  </Paragraph>
                  <br /> */}

                  {/* <Paragraph color="#fff">

                  </Paragraph> */}
                  <p
                    style={{ color: "#fff", margin: "unset" }}
                    dangerouslySetInnerHTML={{
                      __html: infoData.name
                    }}
                  />

                  <div style={{ display: "flex" }}>
                    <LocationOn style={{ color: "#fff", marginTop: "1rem", padding: "0 .4rem" }} />
                    <p style={{ color: "#fff", margin: "unset" }}
                      dangerouslySetInnerHTML={{
                        __html: infoData.address
                      }}
                    />
                  </div>
                  <a href={"tel:" + infoData.contact}>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <WhatsApp style={{ color: "#fff", padding: "0 .4rem" }} />
                      <Paragraph color="#fff">{infoData.contact}</Paragraph>
                    </div>
                  </a>
                  <a href={"mailto:" + infoData.email}>
                    <div style={{ display: "flex", marginBottom: "1rem" }}>
                      <Mail style={{ color: "#fff", padding: "0 .4rem" }} />
                      <Paragraph color="#fff">{infoData.email}</Paragraph>
                    </div>
                  </a>

                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <NavbarLink color="#fff" bold="700" href="/privacyandpolicy">
                    Personal Data Protection Notice
                  </NavbarLink>

                  <NavbarLink color="#fff" bold="700" href="/termandcondition">
                    Terms of Services
                  </NavbarLink>
                  <br>
                  </br>
                  <FooterList>
                    <div style={{ justifyContent: "center" }}>
                      <a target="_blank" href="https://www.facebook.com/OnleServices">
                        <Facebook
                          style={{ color: "#fff", padding: "0 .4rem" }}
                        />
                      </a>
                      {/* <a href="/">
                        <Linkin
                          style={{ color: "#fff", padding: "0 .4rem" }}
                        />
                      </a> */}
                      <a target="_blank" href="https://www.instagram.com/onleservices/">
                        <Instagram
                          style={{ color: "#fff", padding: "0 .4rem" }}
                        />
                      </a>
                      <a target="_blank" href="https://www.youtube.com/channel/UCu5kk6AdqzdknyyQmCMOscQ">
                        <YouTube
                          style={{ color: "#fff", padding: "0 .4rem" }}
                        />
                      </a>
                    </div>
                  </FooterList>

                </Grid>
              </Grid>
            </div>
          </Container>
        </Grid>
      </Grid>
      {/* </Container> */}
    </FooterContainer>
  );
};

export default Footer;
