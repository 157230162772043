import React, { useState, useEffect } from "react";

import Navbar from "../../components/Navbar/Navbar";

import useStyles from "./styles";

import { useNavigate } from "react-router-dom";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import FormInput from "../../components/FormInput/FormInput";
import { Container, Grid } from "@mui/material";

import Slider from "react-slick";
import Footer from "../../components/Footer/Footer";
import map from "../../assets/images/map.png";
import the001 from "../../assets/images/001.jpg";
import icon001 from "../../assets/images/icon-07.png";
import icon002 from "../../assets/images/icon-08.png";
import icon003 from "../../assets/images/icon-09.png";
import icon004 from "../../assets/images/icon-10.png";
import icon005 from "../../assets/images/icon-11.png";
import icon006 from "../../assets/images/icon-24.png";
import bg002 from "../../assets/images/bg002.jpg";
import icon007 from "../../assets/images/icon-19.png";
import icon008 from "../../assets/images/icon-20.png";
import icon009 from "../../assets/images/icon-18.png";
import Carousel from "../../components/Carousel/Carousel";
import payment from "../../assets/images/payment.png";
import { Adjust, Room, ArrowForward, Assignment, JoinInner, DirectionsCar, ArrowDownward } from "@mui/icons-material";
import { get_services, get_company, get_service_price, get_banner, get_booking_slide } from "../../API/API";
import { margin } from "@mui/system";


import bghome from "../../assets/images/bghome.JPG";


export default function Home() {
  const classes = useStyles();
  // const navigate = useNavigate()

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (text) => {
    setTab(text);
  };

  const [serviceHome, setServiceHome] = useState([]);

  const getServiceHome = () => {
    get_services().then((json) => {
      setServiceHome(json.data);
    });
  };

  const [companyData, setCompanyData] = useState([]);


  const getCompanyData = () => {
    get_company().then((json) => {
      setCompanyData(json.data);
    });
  };

  const [priceData, setPriceData] = useState([]);

  const getPriceData = () => {
    get_service_price().then((json) => {
      setPriceData(json.data);
    });
  };

  const [bannerData, setBannerData] = useState([]);

  const getBanner = () => {
    get_banner({ page: "Home" }).then((json) => {
      setBannerData(json.data);
    })
  }

  const [imageData, setImageData] = useState([]);

  const getImageData = () => {
    get_booking_slide().then((json) => {
      setImageData(json.data);
    })
  }

  const [token, setToken] = useState(null);

  useEffect(() => {
    let tokenRes = "";
    tokenRes = window.localStorage.getItem("token");
    if (tokenRes) {
      setToken(tokenRes);
    }
    getServiceHome();
    getCompanyData();
    getPriceData();
    getBanner();
    getImageData();
  }, []);

  const one = (
    <span style={{ display: "flex", flexDirection: "row", padding: "0.5rem 1rem" }}>
      <p style={{ fontSize: "50px", margin: "unset" }}>1</p>
      &nbsp;
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <p
          className={classes.theTiltle}
        >
          BECOME A MEMBER
        </p>
        <p
          className={classes.theDetail}
        >
          Register for ONLE membership before using our services. Members can easily re-book, view booking history and more without entering their contact details each time.
        </p>
      </div>
      &nbsp;
    </span>
  )

  const two = (
    <span style={{ display: "flex", flexDirection: "row", padding: "0.5rem 1rem" }}>
      <p style={{ fontSize: "50px", margin: "unset" }}>2</p>
      &nbsp;
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <p
          className={classes.theTiltle}
        >
          BOOK AND PAY ONLINE
        </p>
        <p
          className={classes.theDetail}
        >
          Member can book the trip by distance, by the hour or full day.
        </p>
      </div>
      &nbsp;
    </span>
  )

  const three = (
    <span style={{ display: "flex", flexDirection: "row", padding: "0.5rem 1rem 0 1rem" }}>
      <p style={{ fontSize: "50px", margin: "unset" }}>3</p>
      &nbsp;
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <p
          className={classes.theTiltle}
        >
          PAIRING
        </p>
        <p
          className={classes.theDetail}
        >
          The Portal will assign jobs to our chauffeur, and you can check the booking status from the portal.
        </p>
      </div>
      &nbsp;
    </span>
  )

  const four = (
    <span style={{ display: "flex", flexDirection: "row", padding: "0.5rem 1rem" }}>
      <p style={{ fontSize: "50px", margin: "unset" }}>4</p>
      &nbsp;
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <p
          className={classes.theTiltle}
        >
          PICK-UP
        </p>
        <p
          className={classes.theDetail}
        >
          Please wait for the pick-up and make sure the contact number is reachable; our chauffeur will contact you if necessary.
        </p>
      </div>
      &nbsp;
    </span>
  )


  const isMobile = window.innerWidth <= 768;

  return (
    <React.Fragment>
      <Navbar />
      <div className={classes.theBanner}>
        {/* <img src={bg001} /> */}
        <div className={classes.theBooking} id="bookride">
          {isMobile ?
            (
              null
            ) : (
              <>
                {bannerData.map((item) => (
                  <img src={item.banner_image} className={classes.theBackground} />
                ))}
              </>
            )}
          <div className={classes.theForm}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className={classes.theMap}>
                  {imageData.is_photo == 1 ?
                    (
                      <img src={imageData.result} />
                    ) : (
                      // <iframe width="100%" height="315" src={"https://www.youtube.com/embed/" + imageData.result} title="YouTube video player" frameborder="0" allowfullscreen></iframe>
                      <iframe width="100%" height="315" src={imageData.result} title="YouTube video player" frameborder="0" allowfullscreen></iframe>

                    )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paragraph
                      color="#fff"
                      center
                      bold="700"
                      style={{ backgroundColor: "#21b7d4", borderRadius: "0.25rem" }}
                    >
                      {one}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paragraph
                      color="#fff"
                      center
                      bold="700"
                      style={{ backgroundColor: "#0689a7", borderRadius: "0.25rem" }}
                    >
                      {two}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paragraph
                      color="#fff"
                      center
                      bold="700"
                      style={{ backgroundColor: "#016075", borderRadius: "0.25rem" }}
                    >
                      {three}
                      <div className={classes.theTiltleImg}>
                        <img src={payment} />
                      </div>
                      <br />
                    </Paragraph>

                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paragraph
                      color="#fff"
                      center
                      bold="700"
                      style={{ backgroundColor: "#115d6a", borderRadius: "0.25rem" }}

                    >
                      {four}
                    </Paragraph>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className={classes.theBookRideBtn}>
                      {token != null ?
                        (
                          <>
                            <a href="/bookaride">
                              <Button
                                label="Book A Ride"
                                color="#fff"
                                bg="#185D6A"
                                style={{ borderRadius: "1rem" }}
                              // onClick={() => handleChangeTab(1)}
                              />
                            </a>
                          </>
                        ) : (
                          <>
                            <a href="/login">
                              <Button
                                label="LOGIN NOW"
                                color="#fff"
                                bg="#115D6A"
                                style={{ borderRadius: "0.25rem" }}
                              // onClick={() => handleChangeTab(1)}
                              />
                            </a>
                          </>
                        )}
                    </div>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Section>
        <Container>
          <Paragraph
            color="#605C5C"
            center
            bold="700"
            className={classes.theResizeFont}
            margin="2rem 0"
          >
            Welcome to Onle Service Sdn Bhd
          </Paragraph>
          <Grid container spacing={3}>
            {companyData.map((item) => (
              <>
                <Grid item xs={12} sm={12} md={6}>
                  <div className={classes.theIMG}>
                    <img src={item.company_image} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} style={{ alignItems: "center" }}>
                  <Paragraph bold="700" color="#605C5C" margin="1rem 0">
                    {item.company}
                  </Paragraph>
                  <p dangerouslySetInnerHTML={{
                    __html: item.description
                  }} />
                </Grid>
              </>
            ))}

          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Paragraph
            color="#605C5C"
            center
            bold="700"
            className={classes.theResizeFont}
            margin="2rem 0"
          >
            Our Services
          </Paragraph>
          <Grid container spacing={3}>
            {serviceHome.map((item) => (
              <Grid item xs={12} sm={12} md={4}>
                <Card style={{ marginBottom: "1rem", cursor: "unset" }}>
                  <div className={classes.theServiceIMG}>
                    <img src={item.service_image} />
                  </div>
                  <div>
                    <Paragraph center bold="700" color="#115D6A" margin="1rem 0" className={classes.theServiceTitle} >
                      {item.title}
                    </Paragraph>
                    <p className={classes.theServiceDetail}
                      dangerouslySetInnerHTML={{
                        __html: item.description
                      }} />
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Paragraph
            color="#605C5C"
            center
            bold="700"
            className={classes.theResizeFont}
            margin="2rem 0"
          >
            Price Rate
          </Paragraph>
          <div>
            <Grid container spacing={3}>
              {priceData.map((item) => (
                <Grid item xs={12} sm={12} md={4}>
                  <div className={classes.thePriceBorder}>
                    <div className={classes.thePriceFlex}>
                      <div className={classes.theHeadColor}>
                        <Paragraph
                          center
                          bold="700"
                          color="#fff"
                          size="26px"
                          dangerouslySetInnerHTML={{
                            __html: item.service
                          }}
                        />

                      </div>
                      <div className={classes.theTimeFlexOne}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                          <div style={{ width: "75%" }}>
                            <Paragraph
                              dangerouslySetInnerHTML={{
                                __html: item.description
                              }}
                            />
                          </div>
                          <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Paragraph bold="700" color="#115D6A">
                              RM {item.price}
                            </Paragraph>
                          </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                          <div style={{ width: "75%" }}>
                            <Paragraph
                              dangerouslySetInnerHTML={{
                                __html: item.description_2
                              }}
                            />
                          </div>
                          {item.price_2 == "0.00" ?
                            (
                              null
                            ) : (
                              <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Paragraph bold="700" color="#115D6A">
                                  RM {item.price_2}
                                </Paragraph>
                              </div>
                            )}

                        </div>
                      </div>

                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </Section>
      <Footer />
    </React.Fragment >
  );
}
