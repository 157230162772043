import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import car from "../../assets/images/car.png";

import { Adjust, Room } from "@mui/icons-material";
import { get_order_history_detail } from "../../API/API";


export default function BookingConfirmation() {
  const classes = useStyles();
  // const navigate = useNavigate()

  let { orders_id } = useParams();


  const [orderHistoryDetail, setOrderHistoryDetail] = React.useState([]);

  const getOrderHistoryDetail = () => {
    get_order_history_detail({ orders_id }).then((json) => {
      setOrderHistoryDetail(json.data);
    })
  }

  React.useEffect(() => {
    getOrderHistoryDetail();
  }, []);


  return (
    <React.Fragment>
      <Navbar />
      <Section>
        <Container>
          {orderHistoryDetail.map((item) => (

            <div className={classes.theBox}>
              <div className={classes.theHeader}>
                <Paragraph color="#fff" bold="600" center>
                  Dear {item.user_name}, Your Booking have been confirmed
                </Paragraph>
              </div>
              <div className={classes.theDetail}>
                <Paragraph margin="1rem 0" center>
                  Confirmation Number: {item.orders_id}
                </Paragraph>
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} md={4} className={classes.theFlex}>
                    <div className={classes.theIMG}>
                      <img src={car} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} className={classes.theFlex}>
                    {item.delivery_type_id == 1 && (
                      <div style={{ padding: "1rem", width: '100%' }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Adjust />
                          <div style={{ width: "100%" }}>
                            <Paragraph margin="0 0 0 1rem">
                              {item.delivery_title}
                            </Paragraph>
                          </div>
                        </div>
                        <ul style={{ padding: "0 0 0 1.5rem" }}>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Room style={{ color: "#e74c3c" }} />
                          <div style={{ width: "100%" }}>
                            <Paragraph margin="0 0 0 1rem">
                              {item.time_slot}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    )}

                    {item.delivery_type_id == 2 && (
                      <div style={{ padding: "1rem", width: '100%' }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Adjust />
                          <div style={{ width: "100%" }}>
                            <Paragraph margin="0 0 0 1rem">
                              {item.pickup_location}
                            </Paragraph>
                          </div>
                        </div>
                        <ul style={{ padding: "0 0 0 1.5rem" }}>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Room style={{ color: "#e74c3c" }} />
                          <div style={{ width: "100%" }}>
                            <Paragraph margin="0 0 0 1rem">
                              {item.destination}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    )}

                    {item.delivery_type_id == 3 && (
                      <div style={{ padding: "1rem", width: '100%' }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Adjust />
                          <div style={{ width: "100%" }}>
                            <Paragraph margin="0 0 0 1rem">
                              {item.delivery_title}
                            </Paragraph>
                          </div>
                        </div>
                        {/* <ul style={{ padding: "0 0 0 1.5rem" }}>
                          <li></li>
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Room style={{ color: "#e74c3c" }} />
                          <div style={{ width: "100%" }}>
                            <Paragraph margin="0 0 0 1rem">
                            {item.destination}
                            </Paragraph>
                          </div>
                        </div> */}
                      </div>
                    )}


                  </Grid>
                  <Grid item xs={12} sm={4} md={4} className={classes.theFlex}>
                    <div style={{ padding: "1rem", width: '100%' }}>

                      <Paragraph margin="0 0 1rem 0">
                        Pickup Date: {item.date}
                      </Paragraph>
                      <Paragraph margin="0 0 1rem 0">
                        Pickup time: {item.fetch_time}
                      </Paragraph>
                      {item.return_time != "" &&
                        <Paragraph margin="0 0 1rem 0">
                          Return time: {item.return_time}
                        </Paragraph>
                      }

                      <Paragraph margin="0 0 1rem 0">
                        Custumer Status <br />
                        (Determine By Admin): {item.customer_status}
                      </Paragraph>
                      <hr />
                      <Paragraph margin="0 0 1rem 0">Distance: {item.distance} km</Paragraph>
                      {/* <Paragraph margin="0 0 1rem 0">
                        ETA Drop Off Point: 25min
                      </Paragraph> */}
                      <Paragraph margin="0 0 1rem 0">Price: RM {item.final_amount}</Paragraph>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <br />
            </div>
          ))}
          <br />
          <br />
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4}>
              <Button bg="#115D6A" color="#fff" label="Manage My Booking" />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Button bg="#115D6A" color="#fff" label="View My Booking List" />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Button
                bg="#115D6A"
                color="#fff"
                label="Print Booking Confirmation"
              />
            </Grid>
          </Grid>
          <br />
          <br /> */}


        </Container>
      </Section>
      <Footer />
    </React.Fragment>
  );
}
