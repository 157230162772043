import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles, { CustomFormControl } from "./styles";
import Section from "../../components/Section/Section";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Login() {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <Navbar />
            <Section>
                <Container>
                    <p>
                        <strong>Personal Data Protection Notice</strong>
                    </p>
                    <p>
                        <strong></strong>
                    </p>
                    <p>
                        Onle Services Sdn. Bhd. (Company No: 202101034757) (“Onle Services”) has
                        created www.onle.my and all related websites and recognizes its obligations
                        under the Malaysian Personal Data Protection Act 2010 which sets out our
                        obligations in respect of the collection, use and disclosure of personal
                        data. Your privacy is one of our top priorities. We have created this
                        Personal Data Protection Notice (“Notice”) to help you understand how we
                        collect, use, communicate, disclose and make use of the personal
                        information we collect. We collect personal information solely for the
                        objective of fulfilling those purposes specified by us or as required by
                        law.
                    </p>
                    <p>
                        Onle Services is referred to as “we”, “us” or “our”. Any person using and
                        accessing this Site, the Content or the Services is referred to as the
                        “User”, “you” or “your”.
                    </p>
                    <p>
                        In the event of any conflict between the English and other language
                        versions, the English version shall prevail.
                    </p>
                    <p>
                        “Personal Data” means information about you, from which you are
                        identifiable, including but not limited to your name, identification card
                        number, birth certificate number, passport number, nationality, address,
                        telephone number, fax number, race, gender, date of birth, marital status,
                        resident status, education background, financial background, personal
                        interests, email address, your occupation, your designation in your
                        company, your company details, the industry in which you work in, any
                        information about you which you have provided to us in registration forms,
                        application forms or any other similar forms and/or any information about
                        you that has been or may be collected, stored, used and processed by us
                        from time to time.
                    </p>
                    <p>
                        Information about our policies and practices relating to the management of
                        personal information is available to all users upon request. We are
                        committed to conducting our business in accordance with these principles in
                        order to ensure that the confidentiality of personal information is
                        protected and maintained. Please note that www.onle.my is not responsible
                        for the privacy statements or other content on websites outside of the Onle
                        Services Website. You should review the privacy statements of websites you
                        choose to link to from the Website, so that you understand how those
                        websites collect, use and share your information. If you have any doubts
                        about the security of the information you are providing on another site,
                        Onle Services Website recommends that you contact that website directly for
                        more information.
                    </p>
                    <p>
                        Onle Services Website shall not be responsible for the actions of third
                        parties in any manner whatsoever, including but not limited to any
                        violation of such privacy policies or otherwise.
                    </p>
                    <p>
                        This policy sets out:
                    </p>
                    <p>
                        · Your consent to this Personal Data Protection Notice
                    </p>
                    <ul>
                        <li>
                            What Personal Data we collect
                        </li>
                        <li>
                            What we use Personal Data for
                        </li>
                        <li>
                            How long we keep Personal Data
                        </li>
                    </ul>
                    <p>
                        · Changes to this Personal Data Protection Notice
                    </p>
                    <ul>
                        <li>
                            Public Information
                        </li>
                        <li>
                            Cookies
                        </li>
                        <li>
                            Personal Information Security
                        </li>
                        <li>
                            Third Party Analytics
                        </li>
                        <li>
                            Transfer of Personal Data
                        </li>
                        <li>
                            Contact Information
                        </li>
                    </ul>
                    <p>
                        Questions, comments and requests regarding this Personal Data Protection
                        Notice are welcomed and should be addressed to pdpa@onle.my.
                    </p>
                    <h5>
                        Your Consent To This Personal Data Protection Notice
                    </h5>
                    <p>
                        By agreeing to the Terms of Use you are accepting and consenting to the
                        practices described in this policy.
                    </p>
                    <p>
                        You agree that your Personal Data that is collected by Onle Services or
                        posted on the Onle Services Website or processed through the Services from
                        time to time may be used for the purposes described in this Personal Data
                        Protection Notice or the Terms of Use.
                    </p>
                    <p>
                        Through the Services and Content on the Onle Services Website, you may be
                        introduced to a variety of third parties such as medical practitioners,
                        physiotherapist, caregivers, and chaperones (“Providers”) and other
                        websites. The privacy policies of these third parties are not under Onle
                        Services control and you should read the respective privacy policies
                        carefully. The use of any information that you may provide to any third
                        party, or the use of “cookie” technology by any third party, will be
                        governed by the Personal Data Protection Notice of the operator of the
                        website that you are visiting.
                    </p>
                    <p>
                        You may refuse or withdraw your consent for us to collect, use or disclose
                        your Personal Data by giving us reasonable notice. This can be done at any
                        time by emailing pdpa@onle.my provided there are no legal or operational
                        restrictions preventing you from doing so. It is important to note that in
                        the event you do withdraw consent, we will be unable to collect, use, or
                        disclose your Personal Data meaning that you will no longer be able to use
                        the Services.
                    </p>
                    <h5>
                        What Personal Data Are Collected
                    </h5>
                    <p>
                        We will store data we collect from you through the contact centre process,
                        such as name, age, gender, and address (and any other data you or others
                        post on the Onle Services Website through the use of the Services) (the
                        “Personal Data”). We may also collect your Personal Data if you report a
                        problem with the Onle Services Website or if you contact us for any reason.
                    </p>
                    <p>
                        Onle Services does not use, disclose or collect sensitive personal
                        information (such as religion, political affiliations or medical
                        information) without your consent. Onle Services Sdn. Bhd. (Company No:
                        202101034757) keeps track of the Onle Services Website and pages our users
                        visit within the Onle Services Website, in order to determine what Services
                        are the most popular. This data may be used to deliver customized content
                        and advertising within the Onle Services Website to users whose behavior
                        indicates that they are interested in a particular subject area.
                    </p>
                    <p>
                        If you have provided Personal Data to Onle Services, you can request access
                        to it at any time, as well as request to know how it is being used and
                        disclosed for the last 12 months. In order to facilitate this access, you
                        may be charged an administrative fee. You also have the right to request
                        correction to your Personal Data.
                    </p>
                    <h5>
                        What We Use Your Data For
                    </h5>
                    <p>
                        The primary reason we collect Personal Data is to enable users to use our
                        Services and to enable the content-related functions of the Onle Services
                        Website. Your data will only be use only for the purposes of providing the
                        service that you have requested.
                    </p>
                    <p>
                        We may use your Personal Data for account and Onle Services Websites
                        maintenance, marketing, billing purposes and user profiling. We use and
                        disclose Personal Data relating to payment methods and details to the
                        extent required to process fees for mobility services and other
                        transactions.
                    </p>
                    <p>
                        We may use your Personal Data to contact you in respect of the Services. We
                        will send emails to the email addresses provided by you and we will be
                        making calls on the phone numbers provided by you if required. If you have
                        provided an incorrect email address or phone number (or if these details
                        have been changed without notice to us), then you may not receive these
                        communications. Onle Services Sdn. Bhd. (Company No: 202101034757) will not
                        be responsible for any breach of privacy due to correspondence going to an
                        incorrect address or phone number. We may also use your Personal Data to
                        contact you to perform surveys about your opinion of Onle Services or the
                        Services, or of potential new Services that may be offered. By using the
                        Onle Services platform, you consent to your Personal Data being used by
                        Onle Services for such communications. Should you no longer wish to be
                        contacted, you may opt out of receiving these communications on your
                        profile.
                    </p>
                    <p>
                        We may collect information about your computer, including your IP address,
                        operating system and browser type, for system administration and to report
                        aggregate information to our advertisers and to generate insights. We may
                        use information about member statistics, traffic patterns for the purposes
                        of our business activities, including sales, marketing, mergers,
                        acquisitions and bankruptcies.
                    </p>
                    <p>
                        Generally, Onle Services does not sell, rent or lease its user lists to
                        third parties. Onle Services may share data with trusted partners to help
                        us perform statistical analysis, send you email or postal mail, provide
                        user support, or arrange for deliveries. All such third parties are
                        prohibited from using your personal information except to provide these
                        services to Onle Services, and they are required to maintain the
                        confidentiality of your information.
                    </p>
                    <p>
                        We may disclose your personal information if we are under a duty to
                        disclose or share such data in order to comply with any legal obligation,
                        or to protect the rights, property, or safety of Onle Services, our users,
                        or others. This includes exchanging information with other companies and
                        organizations for the purposes of fraud protection and credit risk
                        reduction.
                    </p>
                    <p>
                        We may aggregate personal and other data captured through the Onle Services
                        Website and the Services so that the data is no longer capable of
                        identifying any individual. Aggregated data may cover patterns of usage
                        relating to the Onle Services Websites and its Services. Onle Services
                        reserves the right to use this aggregated information for the purposes of
                        improving and enhancing its Services, developing and selling data and
                        information to third parties, generating and commercializing insights and
                        otherwise for the purposes of our business.
                    </p>
                    <h5>
                        How Long We Keep Your Personal Data For
                    </h5>
                    <p>
                        We reserve the right to retain any Personal Data collected until your
                        account is terminated or for a period of ten (10) years after you last used
                        our service (whichever is the longer period).
                    </p>
                    <h5>
                        Changes To This Personal Data Protection Notice
                    </h5>
                    <p>
                        Any changes we may make to this Personal Data Protection Notice in the
                        future will be posted on the Onle Services Website. Changes will take
                        effect immediately and be binding on you from the time of posting.
                    </p>
                    <h5>
                        Public Information
                    </h5>
                    <p>
                        Keep in mind that any reviews, personal information, or comments that you
                        voluntarily disclose on public pages (e.g. the questions and answers page)
                        of the Onle Services Websites may be viewed by the general public. Your
                        username may be displayed to other users when you send and/or post messages
                        on public pages. Please note that any comment or other content you post or
                        send on public pages becomes published content and is not considered
                        personally identifiable information (so is not subjected to this Personal
                        Data Protection Notice). Your messages and other information you make
                        available in public pages of the Onle Services Websites will be seen by
                        people and organizations not related to or controlled by Onle Services and
                        may be used by others to contact you.
                    </p>
                    <p>
                        The data that you submit or write on the Onle Services Websites may be seen
                        by other service providers such as operations and maintenance team
                        repairing and maintaining our technical systems. Onle Services have a
                        Non-Disclosure Agreements with these parties who may be able to access your
                        information.
                    </p>
                    <h5>
                        Cookies
                    </h5>
                    <p>
                        The Onle Services Websites may sometimes use “cookies” to personalize your
                        online experience. The purpose of a cookie is to tell the Web server that
                        you have returned to a specific page. Cookies cannot run programs or
                        deliver viruses to your computer. You can accept or decline cookies by
                        altering the settings in your web browser. Most Web browsers automatically
                        accept cookies, but you can usually modify your browser. If you choose to
                        decline cookies, you may not be able to fully experience the interactive
                        feature setting of the Onle Services Websites you visit.
                    </p>
                    <p>
                        We may also use cookies to collect information about member statistics,
                        traffic patterns and information which may include personal data such as
                        names, addresses and emails. Moreover, we may keep details of your visits
                        to the Onle Services Websites including, but not limited to, traffic data,
                        location data, weblogs and other communication data.
                    </p>
                    <h5>
                        Personal Information Security
                    </h5>
                    <p>
                        Onle Services secures the Personal Data you provide on computer servers in
                        a secure environment. Your information is always protected from
                        unauthorized access. If personal information (such as a credit card number)
                        is transmitted to other websites, its transmission is protected with
                        encryption, such as Secure Socket Layer (SSL) protection.
                    </p>
                    <h5>
                        Transfer Of Personal Data
                    </h5>
                    <p>
                        In the event that Onle Services enters a business transaction, such as a
                        merger or acquisition with/by another company, your Personal Data may be
                        among the assets transferred. You will be notified via email of any such
                        change in ownership or control of your Personal Data.
                    </p>
                    <h5>
                        Contact Information
                    </h5>
                    <p>
                        Onle Services welcomes your comments regarding this Personal Data
                        Protection Notice and its adherence to its obligations under the Malaysian
                        Personal Data Protection Act 2010. If you believe that Onle Services has
                        not adhered to the Malaysian Personal Data Protection Act 2010 and would
                        like to make a complaints, request for access to and/or request correction
                        of your Personal Data, request to limit the processing of your Personal
                        Data for the Additional Purposes and/or make any inquiries regarding your
                        Personal Data by contacting:
                    </p>
                    <p>
                        <strong>Onle Services Sdn. Bhd. (Company No: 202101034757)</strong>
                    </p>
                    <p>
                        Address: Suite 25.03A, Level 25, Johor Bahru City Square Office Tower
                        106-108, Jalan Wong Ah Fook, 80000, Johor Bahru
                    </p>
                    <p>
                        Email: pdpa@onle.my
                    </p>
                    <p>
                        Telephone Number: 6011 6328 9098
                    </p>
                    <p>
                        Operating Hours: 9am - 6pm from Mondays - Fridays (excluding Public
                        Holidays)
                    </p>
                    <p>
                        We may impose a fee for processing the aforesaid requests.
                    </p>
                    <p>
                        Onle Services reserves the right to update, revise, amend and/or modify
                        this Personal Data Protection Notice from time to time and in any manner at
                        its absolute discretion but in accordance with the law. Any material
                        changes will be posted on our website with the updated Personal Data
                        Protection Notice.
                    </p>
                </Container>
            </Section>
            <Footer />
        </React.Fragment>
    );
}