import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import car from "../../assets/images/car.png";

import { Adjust, Room } from "@mui/icons-material";

export default function Payment() {
  const classes = useStyles();
  // const navigate = useNavigate()

  return (
    <React.Fragment>
      <Navbar />
      <Section>
        <Container>
          <div className={classes.theBox}>
            <div className={classes.theHeader}>
              <Paragraph color="#fff" bold="600" center>
                Booking Detail
              </Paragraph>
            </div>
            <div className={classes.theDetail}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={4} className={classes.theFlex}>
                  <div className={classes.theIMG}>
                    <img src={car} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.theFlex}>
                  <div style={{ padding: "1rem", width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Adjust />
                      <div style={{ width: "100%" }}>
                        <Paragraph margin="0 0 0 1rem">
                          123 Jalan Austin 2, Austin Height
                        </Paragraph>
                      </div>
                    </div>
                    <ul style={{ padding: "0 0 0 1.5rem" }}>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Room style={{ color: "#e74c3c" }} />
                      <div style={{ width: "100%" }}>
                        <Paragraph margin="0 0 0 1rem">
                          123 Jalan Austin 2, Austin Height
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} className={classes.theFlex}>
                  <div style={{ padding: "1rem", width: "100%" }}>
                    <Paragraph margin="0 0 1rem 0">
                      Pickup time: Today, Now
                    </Paragraph>
                    <Paragraph margin="0 0 1rem 0">
                      Pickup Date: 09-02-2022
                    </Paragraph>
                    <hr />
                    <Paragraph margin="0 0 1rem 0">Distance: 2.7km</Paragraph>
                    <Paragraph margin="0 0 1rem 0">
                      ETA Drop Off Point: 25min
                    </Paragraph>
                    <Paragraph size="32px" bold="700" margin="0 0 1rem 0">
                      RM 23
                    </Paragraph>
                  </div>
                </Grid>
              </Grid>
            </div>
            <br />
          </div>
          <br />
          <br />
          <Button bg="#115D6A" color="#fff" label="Pay Now" />
          <br />
          <br />
        </Container>
      </Section>
      <Footer />
    </React.Fragment>
  );
}
