import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import bg001 from "../../assets/images/bg001.jpg";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const ShowAddress = styled.div`
 display: block !important;
  padding: .6rem 0.85rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: ${(props) => props.textcolor};
  background: ${(props) => props.bg};
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  /* padding: 6px 0 7px; */
  /* height: 1.1876em; */
`;

export const ModalAddress = styled.div`
  position: absolute;
  background-color: #fff;
  top: 50%;
  width: 90%;
  height: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px #ccc;
  padding: 1rem;
 
  overflow: scroll;
  @media screen and (min-width: 768px) {
    width: 70%;
    height: 750px;
  }
  @media screen and (min-width: 992px) {
    width: 30%;
   
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 1rem 1rem 0 0;
`;

export const FlexIcon = styled.div`
  align-items: center;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
`;

export const ModalBody = styled.div`
  position: relative;
  padding: 0 2rem;
`;

const config = {
  theBanner: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      // height: '450px',
      // objectFit: 'cover'
    },
  },

  theOverlay: {
    position: "absolute",
    width: "100%",
    height: "99%",
    backgroundColor: "rgba(0,0,0,0.4)",
    top: 0,
    left: 0,
  },

  theBooking: {
    position: "relative",
    // backgroundImage: "url(" + bg001 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    padding: "2rem 1.4rem",

    "&:before": {
      content: "",
      width: "100%",
      height: "99%",
      backgroundColor: "rgba(0,0,0,0.4)",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  theForm: {
    background: "#fff",
    width: "90%",
    margin: "0 auto",
    borderRadius: 20,
  },

  theMap: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theTextTitle: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },

  theFont: {
    fontSize: "18px",
    ...md("sm", {
      fontSize: "28px",
    }),

    ...md("md", {
      fontSize: "28px",
    }),

    ...md("xmd", {
      fontSize: "34px",
    }),
  },

  theList: {
    position: "relative",
  },

  theBox: {
    position: "relative",
    display: "block",

    "& img": {
      width: "100%",
    },
  },

  theText: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    // width: '100%',
  },

  theResizeFont: {
    fontSize: "20px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "22px !important",
    }),

    ...md("xmd", {
      fontSize: "24px !important",
    }),
  },

  theResizeFont_: {
    fontSize: "17px !important",

    ...md("sm", {
      fontSize: "19px !important",
    }),

    ...md("md", {
      fontSize: "25px !important",
    }),

    ...md("xmd", {
      fontSize: "31px !important",
    }),
  },

  theCardTesti: {
    position: "relative",
    width: "70%",
    // margin: '0 auto',
    margin: "10px auto",
  },

  theQuo: {
    position: "relative",
    width: "10%",
    margin: "0 auto",
    paddingBottom: "1rem",
    "& img": {
      width: "100%",
    },
  },

  theAvatar: {
    position: "relative",
    width: "100%",
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    marginBottom: ".2rem",

    "& img": {
      width: 50,
      height: 50,
      borderRadius: 50,
      objectFit: "cover",
    },
  },

  theResizing: {
    width: "54%",
    fontSize: "12px !important",

    ...md("sm", {
      width: "49%",
      fontSize: "16px !important",
    }),

    ...md("md", {
      width: "44%",
      fontSize: "16px !important",
    }),

    ...md("xmd", {
      width: "41%",
      fontSize: "16px !important",
    }),
  },

  theResizeText: {
    fontSize: "14px !important",

    ...md("sm", {
      fontSize: "16px !important",
    }),
  },

  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theServiceIMG: {
    position: "relative",
    width: "40%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  theDetail: {},

  thePricing: {
    position: "relative",
    // width: "100%",
    background: "#C1DEE5",
    padding: "1rem",
  },

  // theBox: {
  //   position: "relative",
  //   width: "80%",
  //   margin: "0 auto",
  // },

  // theLine: {
  //   borderBottom: "1px solid #fff",
  // },

  theIcon: {
    position: "relative",
    width: "10%",

    ...md("sm", {
      width: "15%",
    }),

    "& img": {
      width: "100%",
    },
  },

  theBtn: {
    display: 'block',

    ...md("sm", {
      display: 'flex',
      justifyContent: 'space-between',
      width: '60%',
      margin: '0 auto'
    }),
  },

  theBtn_: {
    display: 'block',

    ...md("sm", {
      display: 'flex',
      justifyContent: 'left',
      margin: '0 auto'
    }),
  },

  theBtnConfirm: {
    display: 'block',

    ...md("sm", {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 auto'
    }),
  },


  theBtnAdd: {
    display: 'block',

    ...md("sm", {
      display: 'flex',
      justifyContent: 'center',
      width: '60%',
      margin: '0 auto'
    }),
  },

  theDateStyle: {
    width: "100%",
    padding: "1rem 0 1rem 0.5rem",
    border: "1px solid #cecece",
    borderRadius: "0.25rem",
  },

  theContentStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "2rem",
    alignItems: "center",
  },

  theContentOneStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  theInputForm: {
    display: "flex",
    flexDirection: "column",

    ...md("md", {
      flexDirection: "row",
    }),
  },

  theFormWidth: {
    width: "100%",

    ...md("md", {
      width: "50%",
    }),
  },

  theLine: {
    margin: "2rem 0",

    ...md("md", {
      margin: "0 2rem",
    }),
  },

  theBtnApply: {
    marginBottom: "1rem",
    marginRight: "unset",

    ...md("sm", {
      marginBottom: "unset",
      marginRight: "0.5rem",
    }),
  },

  theBtnApply_: {
    marginBottom: "1rem",

    ...md("sm", {
      marginBottom: "unset",
    }),
  },

  theFlexCreateBtn: {
    display: 'flex',
    alignItems: 'center',
    border: '2px solid #185D6A',
    padding: 6,
    justifyContent: 'space-between',
    marginBottom: 14
  },

  theFlexCreateBtn_: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d7d7d7',
    padding: 6,
    justifyContent: 'space-between',
    marginBottom: 14,

  },

  theDefaultAddress: {
    backgroundColor: '#f0f0f0',
    padding: '0.375rem 0.75rem',
    display: 'block',
    borderBottom: '1px solid #000'
  },

  theSelectedAddress: {
    borderBottom: '1px solid #d7d7d7',
    backgroundColor: '#fff',
    padding: '0.375rem 0.75rem',
    display: 'block'
  },

  theLandmark: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "flex-end"
  }
};

export default makeStyles(config)