import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";

import bgabout from "../../assets/images/bgabout.jpg";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  theBanner: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      // height: '600px'
    },
  },

  theOverlay: {
    position: "absolute",
    width: "100%",
    height: "99%",
    backgroundColor: "rgba(0,0,0,0.4)",
    top: 0,
    left: 0,
  },

  theTextTitle: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },

  theFont: {
    fontSize: "18px",
    ...md("sm", {
      fontSize: "28px",
    }),

    ...md("md", {
      fontSize: "28px",
    }),

    ...md("xmd", {
      fontSize: "34px",
    }),
  },

  theList: {
    position: "relative",
  },

  theBox: {
    position: "relative",
    display: "block",

    "& img": {
      width: "100%",
    },
  },

  theText: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    // width: '100%',
  },

  theIndustry: {
    position: "relative",
    display: "block",

    "& img": {
      width: "100%",
    },
  },

  theResizeFont: {
    fontSize: "20px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "22px !important",
    }),

    ...md("xmd", {
      fontSize: "24px !important",
    }),
  },

  theResizeFont_: {
    fontSize: "16px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "24px !important",
    }),

    ...md("xmd", {
      fontSize: "28px !important",
    }),
  },

  theResizing: {
    width: "54%",
    fontSize: "12px !important",

    ...md("sm", {
      width: "49%",
      fontSize: "16px !important",
    }),

    ...md("md", {
      width: "44%",
      fontSize: "16px !important",
    }),

    ...md("xmd", {
      width: "41%",
      fontSize: "16px !important",
    }),
  },

  theResizeText: {
    fontSize: "14px !important",

    ...md("sm", {
      fontSize: "16px !important",
    }),
  },

  theBG: {
    position: "relative",
    // overflow: 'scroll',
    // backgroundImage: "url(" + bgabout + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    // padding: "1.4rem",
    // height: "330px",
    // height: '100vh',
    // "& img": {
    // 	width: '100%',
    // 	height: '120vh',
    // 	...md("md", {
    // 		height: 'auto',
    // 	}),
    // }

    "& img": {
      width: "100%",
    },
  },

  theBackground: {
    height: "890px",

    ...md("xxmd", {
      height: "550px",
    }),

    ...md("xmd", {
      height: "600px",
    }),

    ...md("xl", {
      height: "750px",
    }),
  },

  theBoxAbout: {
    position: "absolute",
    width: "80%",
    margin: "0 auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0,0,0,.4)",
    padding: "1.4rem",
    // paddingTop: '1rem',
    overflow: "scroll",
    height: "820px",

    ...md("sm", {
      width: "80%",
    }),

    ...md("md", {
      overflow: "unset",
      height: "auto",
    }),
  },

  theUserIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
};

export default makeStyles(config);
