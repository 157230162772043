import styled from "styled-components";
import { Menu, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";


export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const FooterContainer = styled.div`
  // background: #acadaf;
  display: flex;
  align-items: center;
  // padding: 0.5rem 0;
	/* box-shadow: 1px -9px 5px -3px rgba(240,240,240,1); */
	position: relative;
	width: 100%;

  @media screen and (min-width: 992px) {
    // padding: 1rem 0;
  }
`;

export const FooterList = styled.div`
  display: block;
  width: 100%;
  text-align: left
`;

export const NavbarUpper = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const NavbarLower = styled.div`
  justify-content: center;
  display: flex;
  flex-grow: 1;
  padding: 1rem 0;
`;

export const NavbarLowerList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
`;

export const NavbarLink = styled.a`
  color: ${({ color }) => color};
  font-weight: ${({ bold }) => bold};
  align-items: center;
  display: flex;
  position: relative;
  margin-bottom: 8px;
  font-size: 14px;
  cursor: pointer;

  &:last-child {
    border-right: unset;
  }
`;

export const NavbarLogo = styled.a`

  width: 220px;

  @media screen and (min-width: 992px) {
    width: 180px;
  }

  @media screen and (min-width: 1440px) {
    width: 220px;
  }
  /* padding-top: 8px; */
  img {
    width: 90%;

    @media screen and (min-width: 992px) {
      width: 85%;
    }

    @media screen and (min-width: 1440px) {
      width: 70%;
    }

    @media screen and (min-width:1920px){
      width: 80%;
    }

     @media screen and (min-width:2100px){
      width: 80%;
    }
  }
`;


const config = {

	theBox_: {

		...md("md", {
			borderLeft: '2px solid #178992',
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			height: 150,
		}),
	},

  

};

export default makeStyles(config);