import React, { useContext } from "react";
import { AddressContext } from "../contexts/AddressContext";

const Storage = (addressItems) => {
  localStorage.setItem(
    "address",
    JSON.stringify(addressItems)
  );
};

const useAddress = () => {
  const [userAddress, setUserAddress] = useContext(AddressContext);

  function getUserAddress() {
    // var localAddress = JSON.parse(localStorage.getItem("addressListing"))

    // if (localStorage.setItem("addressListing")) {
    //   setUserAddress(localAddress[0]);
    // }
    // localStorage.setItem("address");

    return userAddress;
  }

  function clearAddress() {
    setUserAddress([]);
    Storage([]);
  }

  function setUserAddressFunction(address) {
    // alert(JSON.stringify(address))
    setUserAddress(address);
  }

  function addUserAddress(item) {

    // var newItem = item;

    // addressTemp.push(newItem);

    // setUserAddress(addressTemp);

    setUserAddress(item);

    Storage(item);

    console.log(item);

    // setTimeout(() => {
    //     console.log(userAddress);
    //   }, 5000);
  }



  return {
    addUserAddress,
    clearAddress,
    getUserAddress,
    setUserAddressFunction,
  };
};

export default useAddress;

