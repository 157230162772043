import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles, { CarouselIMG } from "./styles";
import Paragraph from "../../components/Typography/Paragraph";
import Section from "../../components/Section/Section";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";

import user001 from "../../assets/images/user001.png"
import user002 from "../../assets/images/user002.png"
import user003 from "../../assets/images/user003.png"
import user004 from "../../assets/images/user004.png"

import bgabout from "../../assets/images/bgabout.jpg";


import { get_about_us, get_team, get_banner } from "../../API/API";

export default function About() {
  const classes = useStyles();
  // const navigate = useNavigate()

  const [aboutTile, setAboutTitle] = useState();
  const [aboutDescription, setAboutDescription] = useState();
  const [aboutMission, setAboutMission] = useState();
  const [aboutVision, setAboutVision] = useState();

  const getAboutData = () => {
    get_about_us().then((json) => {
      setAboutTitle(json.data.title);
      setAboutDescription(json.data.description);
      setAboutMission(json.data.mission);
      setAboutVision(json.data.vision);
    });
  };

  const [teamData, setTeamData] = useState([]);

  const getTeamData = () => {
    get_team().then((json) => {
      setTeamData(json.data);
    })
  }

  const [bannerData, setBannerData] = useState([]);

  const getBanner = () => {
    get_banner({ page: "About Us" }).then((json) => {
      setBannerData(json.data);
    })
  }

  useEffect(() => {
    getAboutData();
    getTeamData();
    getBanner();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <>
        <Section>
          <Container>

            <div className={classes.theBG}>
              {bannerData.map((item) => (
                <img src={item.banner_image} className={classes.theBackground} />
              ))}
              <div className={classes.theBoxAbout}>
                <Paragraph
                  color="#fff"
                  center
                  bold="700"
                  size="24px"
                  margin="1rem 0 2rem 0"
                >
                  {aboutTile}

                </Paragraph>
                <p style={{ color: "#ffffff" }}
                  dangerouslySetInnerHTML={{
                    __html: aboutDescription
                  }}
                />
              </div>
            </div>

            <br />
          </Container>
        </Section>
        <Section>
          <Container>
            <div>
              <Paragraph
                bold="700"
                size="24px"
                margin="1rem 0 1rem 0"
                style={{ fontStyle: "italic" }}
              >
                Our Mission
              </Paragraph>
              <p
                dangerouslySetInnerHTML={{
                  __html: aboutMission
                }}

              />
            </div>
          </Container>
        </Section>
        <Section>
          <Container>
            <div>
              <Paragraph
                bold="700"
                size="24px"
                margin="1rem 0 1rem 0"
                style={{ fontStyle: "italic" }}
              >
                Our Vision
              </Paragraph>
              <p
                dangerouslySetInnerHTML={{
                  __html: aboutVision
                }}

              />
            </div>
          </Container>
        </Section>
        {/* <Section>
          <Container>
            <Paragraph
              color="#605C5C"
              center
              bold="700"
              className={classes.theResizeFont}
              margin="2rem 0"
            >
              Our Team
            </Paragraph>
            <Grid container spacing={3}>
              {teamData.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <div className={classes.theUserIMG}>
                    <img src={item.team_image} />
                  </div>
                  <Paragraph>{item.name}</Paragraph>
                  <Paragraph>{item.position}</Paragraph>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Section> */}
      </>
      <br />
      <br />
      <Footer />
    </React.Fragment>
  );
}
