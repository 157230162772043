import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles from "./styles";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";

import icon007 from "../../assets/images/icon-19.png";
import icon008 from "../../assets/images/icon-20.png";
import icon009 from "../../assets/images/icon-18.png";
import { Add, Close, Remove } from "@mui/icons-material";
import icon010 from "../../assets/images/icon-21.png";
import icon011 from "../../assets/images/icon-22.png";
import icon012 from "../../assets/images/icon-23.png";
import { width } from "@mui/system";
import { get_order_history, delete_order } from "../../API/API";


export default function Booking() {
  const classes = useStyles();

  // const navigate = useNavigate() 

  const [orderHistory, setOrderHistory] = React.useState([]);

  const getOrderHistory = () => {
    get_order_history({}).then((json) => {
      setOrderHistory(json.data);
    })
  }

  const handleRemove = (orders_id) => {
    delete_order({ orders_id: orders_id }).then((json) => {
      if (json.status) {
        getOrderHistory();
      }
    })
  }

  React.useEffect(() => {
    getOrderHistory();

  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <Section>
        <Container>
          <Paragraph
            bold="700"
            color="#605C5C"
            className={classes.theResizeFont}
          >
            My Booking List
          </Paragraph>
          <br />
          {orderHistory != "" ?
            (
              <>
                {orderHistory.map((item) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <Link to={`../bookingconfirmation/${item.orders_id}`}>
                        <Card>
                          <div className={classes.theFlex}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minWidth: "20%",
                                marginBottom: "1rem",
                              }}
                            >
                              {item.customer_status_id == 1 && (
                                <div className={classes.theCircleSuccess}>
                                  <div className={classes.theIcon_}>
                                    <img src={icon010} />
                                  </div>
                                </div>
                              )}
                              {item.customer_status_id == 2 && (
                                <div className={classes.theCircleFailed}>
                                  <div className={classes.theIcon_}>
                                    <img src={icon012} />
                                    <Paragraph margin="unset" color="#fff">
                                      Failed
                                    </Paragraph>
                                  </div>
                                </div>
                              )}

                              {item.customer_status_id == 3 && (
                                <div className={classes.theCirclePending}>
                                  <div className={classes.theIcon_}>
                                    <img src={icon011} />
                                  </div>
                                </div>
                              )}

                            </div>

                            {/* By Hours */}

                            {item.delivery_type_id == 1 && (
                              <div className={classes.theDeliveryType}>
                                <Paragraph bold="700" className={classes.theAddressWidth}>{item.delivery_title}</Paragraph>
                                <Paragraph margin="0 1rem" className={classes.theAddressDotWidth}>:</Paragraph>
                                <Paragraph bold="700" className={classes.theAddressWidth}>{item.time_slot}</Paragraph>
                              </div>
                            )}

                            {/* By Distance */}

                            {item.delivery_type_id == 2 && (
                              <div className={classes.theDeliveryType}>
                                <div className={classes.theFlexLocation}>
                                  <Paragraph bold="700" className={classes.theAddressWidth}>{item.pickup_landmark}</Paragraph>
                                  <Paragraph bold="400" className={classes.theAddressWidth}>{item.pickup_location}</Paragraph>
                                </div>
                                <Paragraph margin="0 0.75rem" className={classes.theAddressDotWidth}>:</Paragraph>
                                <div className={classes.theFlexLocation}>
                                  <Paragraph bold="700" className={classes.theAddressWidth}>{item.destination_landmark}</Paragraph>
                                  <Paragraph bold="400" className={classes.theAddressWidth}>{item.destination}</Paragraph>
                                </div>
                              </div>
                            )}

                            {/* By Day */}

                            {item.delivery_type_id == 3 && (
                              <div className={classes.theDeliveryType}>
                                <Paragraph bold="700">{item.delivery_title}</Paragraph>
                                {/* <Paragraph margin="0 1rem">:</Paragraph>
              <Paragraph bold="700">{item.destination}</Paragraph> */}
                              </div>
                            )}



                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                                minWidth: "25%",
                              }}
                            >
                              <Paragraph center margin="0 0 .4rem 0" bold="700">
                                {item.delivery_type}
                              </Paragraph>
                              <Paragraph center margin="0 0 .4rem 0">
                                Payment Status: {item.status}
                              </Paragraph>
                              <Paragraph center margin="0 0 .4rem 0">
                                Pickup Date: {item.date}
                              </Paragraph>
                              <Paragraph center margin="0 0 .4rem 0">
                                Pickup Time: {item.fetch_time}
                              </Paragraph>
                              {item.return_time != "" &&
                                <Paragraph center margin="0 0 .4rem 0">
                                  Return Time: {item.return_time}
                                </Paragraph>
                              }
                            </div>



                            <div className={classes.theDistance}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: ".4rem",
                                  minWidth: "17%"
                                }}
                              >
                                <div className={classes.theIcon}>
                                  <img src={icon007} />
                                </div>
                                <Paragraph color="#115D6A" margin="0 0 0 1rem">
                                  {item.distance} km
                                </Paragraph>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: ".4rem",
                                }}
                              >
                                <div className={classes.theIcon}>
                                  <img src={icon009} />
                                </div>
                                <Paragraph color="#115D6A" margin="0 0 0 1rem">
                                  RM {item.final_amount}
                                </Paragraph>
                              </div>
                            </div>
                          </div>

                        </Card>
                      </Link>

                      <div className={classes.theCloseBtn}>
                        <Close
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => handleRemove(item.orders_id)}
                        />
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <Paragraph bold="700" size="24px" color="#185D6A" center>
                No Order Yet
              </Paragraph>
            )}

          <br />
          <br />
        </Container>
      </Section>
      <Footer />
    </React.Fragment>
  );
}
