import React from "react";
import { Routes as Router, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import AboutPage from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";
import ServicePage from "./pages/Service/Service";
import PricingPage from "./pages/Pricing/Pricing";
import BookingConfirmationPage from "./pages/BookingConfirmation/BoookingConfirmation";
import BookingPage from "./pages/Booking/Booking";
import PaymentPage from "./pages/Payment/Payment";
import LoginPage from "./pages/Login/Login";
import RegisterPage from "./pages/Register/Register";
import BookARidePage from "./pages/Bookaride/Bookaride";
import UserProfilePage from "./pages/UserProfile/Userprofile";
import TermAndConditionPage from "./components/Termandcondition/TermAndCondition";
import PrivacyAndPolicyPage from "./components/Privacyandpolicy/PrivacyAndPolicy"

export default function Routes() {
  return (
    <Router>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/service" element={<ServicePage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/bookingconfirmation/:orders_id" element={<BookingConfirmationPage />} />
      <Route path="/booking" element={<BookingPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/bookaride" element={<BookARidePage />} />
      <Route path="/userprofile" element={<UserProfilePage />} />
      <Route path="/termandcondition" element={<TermAndConditionPage />} />
      <Route path="/privacyandpolicy" element={<PrivacyAndPolicyPage />} />

    </Router>
  );
}
