import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl } from "@mui/material";

import bgcontact from "../../assets/images/bgcontact.jpg";

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 1rem;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;
const config = {
  theFont: {
    fontSize: "18px",
    ...md("sm", {
      fontSize: "28px",
    }),

    ...md("md", {
      fontSize: "28px",
    }),

    ...md("xmd", {
      fontSize: "34px",
    }),
  },

  theList: {
    position: "relative",
  },

  theBox: {
    position: "relative",
    display: "block",

    "& img": {
      width: "100%",
    },
  },

  theText: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    // width: '100%',
  },

  theResizeFont: {
    fontSize: "20px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "22px !important",
    }),

    ...md("xmd", {
      fontSize: "24px !important",
    }),
  },

  theResizeFont_: {
    fontSize: "16px !important",

    ...md("sm", {
      fontSize: "20px !important",
    }),

    ...md("md", {
      fontSize: "24px !important",
    }),

    ...md("xmd", {
      fontSize: "28px !important",
    }),
  },

  theResizing: {
    width: "54%",
    fontSize: "12px !important",

    ...md("sm", {
      width: "49%",
      fontSize: "16px !important",
    }),

    ...md("md", {
      width: "44%",
      fontSize: "16px !important",
    }),

    ...md("xmd", {
      width: "41%",
      fontSize: "16px !important",
    }),
  },

  theResizeText: {
    fontSize: "14px !important",

    ...md("sm", {
      fontSize: "16px !important",
    }),
  },

  //

  theBG: {
    position: "relative",
    // overflow: 'scroll',
    // backgroundImage: "url(" + bgcontact + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    // width: '100%',
    padding: "1.4rem",
    // height: '100vh',
    // "& img": {
    // 	width: '100%',
    // 	height: '120vh',
    // 	...md("md", {
    // 		height: 'auto',
    // 	}),
    // }

    "& img": {
      width: "100%",
    },
  },

  theBackground: {
    height: "890px",

    ...md("xxmd", {
      height: "550px",
    }),

    ...md("xmd", {
      height: "600px",
    }),

    ...md("xl", {
      height: "750px",
    }),
  },

  theBoxContact: {
    position: "absolute",
    width: "80%",
    margin: "0 auto",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: "rgba(255,255,255,.4)",
    padding: "1.4rem",
    // paddingTop: "1rem",

    ...md("sm", {
      width: "80%",
    }),
  },
};

export default makeStyles(config);
