import React, { useState } from 'react';

// const defaultAddress = {};

export const AddressContext = React.createContext();


const storage = localStorage.getItem("address")
  ? JSON.parse(localStorage.getItem("address"))
  : [];

const AddressContextProvider = (props) => {
  const [userAddress, setUserAddress] = useState(storage);

  return (
    <AddressContext.Provider value={[userAddress, setUserAddress]}>
      {props.children}
    </AddressContext.Provider>
  );
};

// const AddressContextProvider = props => {
//   const [userAddress, setUserAddress] = useState(defaultAddress);

//   const initialise = async () => {
//     var theAddress = await localStorage.getItem('address');

//     if (theAddress != []) {
//       setUserAddress(JSON.parse(theAddress));
//     } else {
//         // localStorage.setItem('address', JSON.stringify(userAddress));
//         localStorage.setItem('address', 'testttttt');
//     }
//   };

//   React.useEffect(() => {
//     initialise();
//   }, []);

//   return (
//     <AddressContext.Provider value={[userAddress, setUserAddress]}>
//       {props.children}
//     </AddressContext.Provider>
//   );
// };

export default AddressContextProvider;
