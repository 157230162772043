import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import useStyles, { FormInputSelect } from "./styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import Section from "../../components/Section/Section";
import Card from "../../components/Card/Card";
import FormInput from "../../components/FormInput/FormInput";
import { Container, Grid, Select, MenuItem } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import Footer from "../../components/Footer/Footer";
import InputLabel from '@mui/material/InputLabel';
import bgcontact from "../../assets/images/bgcontact.jpg";

import "./styles.css"

import { contact_us, get_banner } from "../../API/API";

export default function Contact() {
  const classes = useStyles();
  const navigate = useNavigate()

  const [contactData, setContactData] = useState({
    name: "",
    contact: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (event) => {
    setContactData({
      ...contactData,
      [event.target.name]: event.target.value,
    });
  };

  const handleContact = () => {
    contact_us(contactData).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
        // navigate('/')
      }
    });
  };


  const [bannerData, setBannerData] = useState([]);

  const getBanner = () => {
    get_banner({ page: "Support" }).then((json) => {
      setBannerData(json.data);
    })
  }

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <Section>
        <Container>
          <div className={classes.theBG}>
            {bannerData.map((item) => (
              <img src={item.banner_image} className={classes.theBackground} />
            ))}
            <div className={classes.theBoxContact}>
              <Paragraph
                color="#0689A7"
                center
                bold="700"
                size="24px"
                margin="1rem 0 2rem 0"
              >
                Support
              </Paragraph>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInput
                    placeholder="Your Name"
                    bg="#fff"
                    name="name"
                    onChange={handleChange}
                  />
                  <FormInput
                    placeholder="Contact Number"
                    bg="#fff"
                    name="contact"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInput
                    placeholder="Email"
                    bg="#fff"
                    name="email"
                    onChange={handleChange}
                  />
                  {/* <FormInput
                    placeholder="Subject"
                    bg="#fff"
                    name="subject"
                    onChange={handleChange}
                  /> */}

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Subject</InputLabel>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      name="subject"
                      onChange={handleChange}
                      label="Subject"
                    >
                      <MenuItem value="Plan a trip">Plan a trip</MenuItem>
                      <MenuItem value="Booking and Cancellation">Booking and Cancellation</MenuItem>
                      <MenuItem value="Request for quote">Request for quote</MenuItem>
                      <MenuItem value="Membership">Membership</MenuItem>
                      <MenuItem value="Complaint or compliment">Complaint or compliment</MenuItem>
                      <MenuItem value="Covid-19 and SOP related">Covid-19 and SOP related</MenuItem>
                      <MenuItem value="Feedback">Feedback</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div>
                <FormInput
                  placeholder="Your Message"
                  bg="#fff"
                  multiline
                  rows={4}
                  name="message"
                  onChange={handleChange}
                />
              </div>

              <br />
              <Button label="Send" color="#fff" bg="#115D6A" onClick={() => handleContact()} />
            </div>
          </div>
        </Container>
      </Section>
      <br />
      <br />
      <Footer />
    </React.Fragment>
  );
}
